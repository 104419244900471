
import { Options, Vue, prop } from 'vue-class-component'
import FileIcon from '@/components/FileIcon.vue'
import FileDropdown from "@/components/FileDropdown.vue";

import mimeTypes from 'mime-types'

import {
    FileEntity,
    FileEntityUpload,
    FileType,
    FileValidator,
} from '@pacprotocol/yanui'

import {
    YButton,
    YDropdown,
    YDropdownItem,
    YModal,
    YProgressBar,
    YSlider,
} from '@pacprotocol/yanui'
import { file, isFor } from '@babel/types'
import { FileInfo } from '@pacprotocol/yanui/dist/types/utils/Files/FileEncryption'
import { type } from 'os'



class Props {
    file: FileEntityUpload = prop({
        required: true,
    })
    type = prop({
        type: String,
        required: true,
    })
    viewMode = prop({
        type: String,
        required: false,
        default: '',
    })
    index = prop({
        type: Number,
        required: false,
        default: -1,
        validator: (val: number) => val >= -1,
    })
    focus = prop({
        type: Boolean,
        required: false,
        default: false,
    })
    isFor = prop({
        type: String,
        default: '',
    })
    tool = prop({
        type: Boolean,
        required: false,
        default: true,
    })
}

@Options({
    components: {
        YDropdown,
        YDropdownItem,
        FileIcon,
        YButton,
        YModal,
        YSlider,
        YProgressBar,
        FileDropdown,
    },
})
export default class FileItem extends Vue.with(Props) {
    private file_names: string[] = []
    public file_drag_mode: boolean = false
    public file_is_inactive: boolean = false

    mounted() {
        this.file_names_updated()
        this.$watch('file.name', () => {
            this.file_names_updated()
        })
    }

    private file_drag(point_position: any) {
        if (this.isFor) {
            return
        }
        this.file_drag_mode = true
        this.$emit('file-drag', this, point_position, this.file, this.index)
    }

    public file_drag_abort() {
        if (this.isFor) {
            return
        }
        setTimeout(() => {
            this.file_drag_mode = false
        }, 1)
    }

    private async file_click(event: MouseEvent) {
        if (this.file_drag_mode) {
            event.stopPropagation()
            return
        }

        if (this.file._upload && this.file._upload.upload_status == 'error') {
            ;(this.$i ? this.$i : this).$yanui.dialog.alert({
                type: 'is-danger',
                icon: 'exclamation-circle',
                title: 'Error Uploading',
                message: `"${this.file.name}" couldn't be upload.\n\nReason:\n${this.file._upload.upload_message}`,
            })
        }

        if (
            this.file._upload &&
            (this.file._upload.upload_status == 'in_queue' ||
                this.file._upload.upload_status == 'uploading')
        ) {
            this.$emit('file-remove', this.file, this.$el)
        }

        if (this.file._upload && !!this.file._upload.uploading) {
            return
        }
        if (this.file.type === FileType.FILE) {
            const password = await (this.$i
                ? this.$i
                : this
            ).$masterkey.derived_password_file(this.file.secret)
            //const password_hex = new Uint8Array(await crypto.subtle.exportKey("raw", password)).reduce((a, b) => a + b.toString(16).padStart(2, '0'), '')
            //console.log("Password Download", password_hex);
            console.log(this.file.id_sha256)
            await (this.$i ? this.$i : this).$yanui.file_server.file_meta_add({
                id: this.file.id_sha256,
                mimetype:
                    mimeTypes.lookup(this.file.name) ||
                    'application/octet-stream',
                name: this.file.name,
                size: this.file.size,
                key: password,
                type: '',
            })
        }
        this.$emit('file-open', this.file, this.$el, this.index)
    }

    private file_remove() {
        if (this.file._upload && !!this.file._upload.uploading) {
            return
        }
        this.$emit('file-remove', this.file, this.$el)
    }

    private file_names_updated() {
        this.file_names = []
        let extension: string = (
            this.$i ? this.$i : this
        ).$yanui.file_utils.fileExtension(this.file.name)
        if (!extension) {
            extension = ''
        }
        let name = this.file.name
        if (!name) {
            this.file_names.push('unknown')
            this.file_names.push('')
            this.file_names.push('')
            return
        }
        if (extension) {
            name = this.file.name.replace(new RegExp(`.${extension}$`), '')
        }

        this.file_names.push(name.substring(0, 2))
        this.file_names.push(name.substring(2, name.length))
        this.file_names.push(extension ? '.' + extension : '')
    }
}
