import UploadDashboard from '@/components/Upload/UploadDashboard.vue'
import StripePayment from '@/components/Payments/StripePayments.vue'

import ChangeEmail from '@/components/Modals/ChangeEmail.vue'

import { Vue } from 'vue-class-component'
export default class ModalUtils {
    private vueThis: any

    constructor(app: any) {
        this.vueThis = app.config.globalProperties
    }

    get i(): Vue {
        return this.vueThis.$i ? this.vueThis.$i : this.vueThis
    }

    public open_upload(props: any = {folder: null}) {
        console.log('!!!')
        this.i.$yanui.modal.open({
            instance: this.i,
            component: UploadDashboard,
            canCancelBackground: false,
            events: {
                'files-added': (files: any) => {
                    this.i.$store.state.files =
                        this.i.$store.state.files.concat(files)
                },
            },
            props,
            width: '768px',
            should_warn_close: true,
            warn_close: (close: CallableFunction) => {
                if (this.i.$store.state.upload_warn_close) {
                    this.i.$yanui.dialog.alert({
                        title: `Cancel Uploading File(s)?`,
                        message:
                            'If you close the window - all uploaded files will be cancelled! Are you sure about that?',
                        icon: 'exclamation-triangle',
                        type: 'is-warning',
                        buttons: [
                            {
                                text: 'No',
                                type: 'is-ghost',
                                action: (modal: any) => {
                                    close(false)
                                    modal.close()
                                },
                            },
                            {
                                text: 'Yes',
                                type: 'is-warning',
                                action: async (modal: any) => {
                                    close(true)
                                    modal.close()
                                },
                            },
                        ],
                    })
                } else {
                    close(true)
                }
            },
            close: () => {
                this.i.$store.state.modal.upload = false
                this.i.$store.state.upload_warn_close = false
            },
        })
    }

    public open_payment(props: any = {}) {
        this.i.$yanui.modal.open({
            title: 'Subscribe for a Plan',
            instance: this.i,
            component: StripePayment,
            canCancelBackground: false,
            events: {},
            props,
            width: '650px',
            should_warn_close: true,
            warn_close: (close: CallableFunction) => {
                close(false)
            },
            close: () => {
                //
            },
        })
    }

    public change_email(props: any = {}) {
        let new_email = ''
        let current_password = ''

        this.i.$yanui.dialog.alert({
            title: 'Change your E-Mail',
            instance: this.i,
            type: 'is-success',
            message: `Current E-Mail: ${this.i.$auth0.user.value.email}`,
            components: [
                {
                    component: ChangeEmail,
                    props,
                    events: {
                        update: (data: any) => {
                            new_email = data.new_email
                            current_password = data.current_password
                        },
                    },
                },
            ],
            canCancelBackground: false,
            width: '650px',
            should_warn_close: true,
            buttons: [
                {
                    text: 'Cancel',
                    type: 'is-ghost',
                    action: (modal: any) => {
                        modal.close()
                    },
                },
                {
                    text: 'Confirm New E-Mail',
                    type: 'is-success',
                    action: async (modal: any) => {
                        const res = await this.i.$utils.change_email_request(
                            new_email,
                            current_password,
                        )
                        modal.close()
                    },
                },
            ],
            warn_close: (close: CallableFunction) => {
                close(false)
            },
            close: () => {
                //
            },
        })
    }

    public change_password(props: any = {}) {
        this.i.$yanui.modal.open({
            title: 'Change your Password',
            instance: this.i,
            component: ChangeEmail,
            canCancelBackground: false,
            events: {},
            props,
            width: '650px',
            should_warn_close: true,
            buttons: [
                {
                    text: 'Cancel',
                    type: 'is-ghost',
                    action: (modal: any) => {
                        modal.close()
                    },
                },
                {
                    text: 'Change E-Mail',
                    type: 'is-success',
                    action: async (modal: any) => {
                        modal.close()
                    },
                },
            ],
            warn_close: (close: CallableFunction) => {
                close(false)
            },
            close: () => {
                //
            },
        })
    }
}
