import { resolveComponent as _resolveComponent, createVNode as _createVNode, vShow as _vShow, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "doc-editor-wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TinyMCEEditor = _resolveComponent("TinyMCEEditor")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _withDirectives(_createElementVNode("div", {
      class: _normalizeClass(["doc-editor-content", {editable: _ctx.editable}])
    }, [
      _createVNode(_component_TinyMCEEditor, {
        ref: "editor",
        "api-key": "no-api-key",
        disabled: _ctx.readonly,
        init: {
                    //skin_url: '/editor/skins/ui/DatosDrive-Skin',
                    max_height: 300,
                    plugins: _ctx.readonly
                        ? ''
                        : 'lists link image table code help wordcount autosave save pagebreak',
                    pagebreak_split_block: true,
                    branding: false,
                    menubar: !_ctx.readonly,
                    toolbar: !_ctx.readonly,
                    statusbar: !_ctx.readonly,
                    readonly: _ctx.readonly,
                    autosave_ask_before_unload: true,
                    autosave_interval: '2s',
                    resize: false,
                    mobile: {
                        menubar: !_ctx.readonly,
                    },
                    setup: _ctx.setup,
                    content_style: `
                        body {
                          background: #fff;
                        }
                    
                        @media (min-width: 840px) {
                          html {
                            background: #eceef4;
                            min-height: 100%;
                            padding: 0 .5rem;
                          }
                      
                          body {
                            background-color: #fff;
                            box-shadow: 0 0 4px rgba(0, 0, 0, .15);
                            box-sizing: border-box;
                            margin: 1rem auto 0;
                            max-width: 820px;
                            min-height: calc(100vh - 1rem);
                            padding:4rem 6rem 6rem 6rem;
                          }
                        }
                      `,
                },
        modelValue: _ctx.content,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.content) = $event)),
        onSelectionChange: _ctx.tinymce_change,
        onInit: _ctx.tinymce_init,
        onRemove: _ctx.tinymce_remove
      }, null, 8, ["disabled", "init", "modelValue", "onSelectionChange", "onInit", "onRemove"])
    ], 2), [
      [_vShow, _ctx.show_editor]
    ])
  ]))
}