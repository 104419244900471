/* eslint-disable */

import mitt, { Emitter } from "mitt";
import { FileEntityUpload, FileUtils } from "@pacprotocol/yanui";

export interface BaseRepresentationTypeOptions {
    maxWidth: number;
    maxHeight: number;
    type: string;
    quality: number;
    dimension: number;
}

export interface BaseRepresentationOptions {
    file: FileEntityUpload;
    thumbnail?: BaseRepresentationTypeOptions,
    preview?: BaseRepresentationTypeOptions
}

export interface RepresentationCaptureInstanceResult {
    blob: Blob;
    url: string;
}


export interface RepresentationCaptureResult {
    thumbnail: RepresentationCaptureInstanceResult | null;
    preview: RepresentationCaptureInstanceResult | null;
}

export default class BaseRepresentationGenerator {
    private defaults = {
      thumbnail: {
          maxWidth: 200,
          maxHeight: 200,
          type: "image/jpeg",
          quality: 60,
          dimension: 200,
      },
      preview: {
          maxWidth: 750,
          maxHeight: 750,
          type: "image/jpeg",
          quality: 95,
          dimension: 750,
      }
  }
    
    protected opts: BaseRepresentationOptions;
    protected file: FileEntityUpload;
    protected file_source: File;
  public event: Emitter<any> = mitt();

  constructor(opts: BaseRepresentationOptions) {
    /**
     * Current options
     * @type {Object}
     */
      this.opts = { ...this.defaults, ...opts };
      this.file = this.opts.file;
      this.file_source = this.file?._upload?.source_file as File;
  }
  /**
   * Start capturing
   * @param  {file} file   The local filename to work with
   */
    public async capture(file: FileEntityUpload): Promise<RepresentationCaptureResult> {
      const thumbnail = await this.capture_thumbnail(file);
      const preview = await this.capture_preview(file);
      
      return {
          thumbnail,
          preview,
      };
    }
    
    protected async capture_thumbnail(file: FileEntityUpload): Promise<RepresentationCaptureInstanceResult | null> {
        return (this.opts.thumbnail ? this.capture_process(this.opts.thumbnail) : null);
    }
    
    protected async capture_preview(file: FileEntityUpload): Promise<RepresentationCaptureInstanceResult | null> {
        return (this.opts.preview ? this.capture_process(this.opts.preview) : null);
    }

    protected async capture_process(options: BaseRepresentationTypeOptions): Promise<RepresentationCaptureInstanceResult | null> {
        return null;
    }
    
    public clean_up() {
      //
    }

    public destroy() {
      this.event.all.clear();
      this.clean_up();
    }
    /**
     * Force and abort of the capture
     */
    public abort() {
      //Do some tidying
      this.clean_up();
      this.event.emit("aborted");
    }
    
    protected get_proportional_dimensions(
        source_width: number,
        source_height: number,
        width: number,
        height: number,
        rotation: number,
        dimension: number,
    ) {
        let aspect = 1
        if (rotation === 90 || rotation === 270) {
            aspect = source_height / source_width
        } else {
            aspect = source_width / source_height
        }

        let target_width = null;
        let target_height = null;

        if (width != null) {
            target_width = width
            target_height = Math.round(width / aspect)
        }else if (height != null) {
            target_width = Math.round(height * aspect)
            target_height = height
        } else {
            target_width = dimension
            target_height = Math.round(dimension / aspect)
        }
        
        target_width = Math.min(target_width, source_width)
        target_height = Math.min(target_height, source_height)

        return {
            width: target_width,
            height: target_height,
        }
    }


    protected resize_image(
        image: HTMLImageElement | HTMLCanvasElement,
        targetWidth: number,
        targetHeight: number,
    ): HTMLImageElement | HTMLCanvasElement {
        image = this.protect(image)

        let steps = Math.ceil(Math.log2(image.width / targetWidth))
        if (steps < 1) {
            steps = 1
        }
        let sW = targetWidth * 2 ** (steps - 1)
        let sH = targetHeight * 2 ** (steps - 1)
        const x = 2

        while (steps--) {
            const canvas = document.createElement('canvas')
            canvas.width = sW
            canvas.height = sH
            const context = canvas.getContext('2d')
            if (context) {
                context.drawImage(image, 0, 0, sW, sH)
            }
            image = canvas

            sW = Math.round(sW / x)
            sH = Math.round(sH / x)
        }

        return image
    }

    protected rotate_image(
        image: HTMLImageElement | HTMLCanvasElement,
        translate: any,
    ) {
        let w = image.width
        let h = image.height

        if (translate.deg === 90 || translate.deg === 270) {
            w = image.height
            h = image.width
        }

        const canvas = document.createElement('canvas')
        canvas.width = w
        canvas.height = h

        const context = canvas.getContext('2d')
        if (context) {
            context.translate(w / 2, h / 2)
            if (translate.canvas) {
                context.rotate(translate.rad)
                context.scale(translate.scaleX, translate.scaleY)
            }
            context.drawImage(
                image,
                -image.width / 2,
                -image.height / 2,
                image.width,
                image.height,
            )
        }

        return canvas
    }

    protected protect(
        image: HTMLImageElement | HTMLCanvasElement,
    ): HTMLImageElement | HTMLCanvasElement {
        const ratio = image.width / image.height

        const maxSquare = 5000000 // ios max canvas square
        const maxSize = 4096 // ie max canvas dimensions

        let maxW = Math.floor(Math.sqrt(maxSquare * ratio))
        let maxH = Math.floor(maxSquare / Math.sqrt(maxSquare * ratio))

        if (maxW > maxSize) {
            maxW = maxSize
            maxH = Math.round(maxW / ratio)
        }
        if (maxH > maxSize) {
            maxH = maxSize
            maxW = Math.round(ratio * maxH)
        }
        if (image.width > maxW) {
            const canvas = document.createElement('canvas')
            canvas.width = maxW
            canvas.height = maxH
            const context = canvas.getContext('2d')
            if (context) {
                context.drawImage(image, 0, 0, maxW, maxH)
            }
            return canvas
        }
        return image
    }

    protected canvas_to_blob(canvas: HTMLCanvasElement, type: string, quality: any): Promise<Blob> {
        try {
            const context = canvas.getContext('2d')
            if (context) {
                context.getImageData(0, 0, 1, 1)
            } else {
                throw new Error()
            }
        } catch (err: any) {
            if (err.code === 18) {
                return Promise.reject(
                    new Error(
                        'cannot read image, probably an svg with external resources',
                    ),
                )
            }
        }

        if (canvas.toBlob) {
            return new Promise((resolve) => {
                canvas.toBlob(resolve, type, quality)
            }).then((blob) => {
                if (blob === null || blob === undefined) {
                    throw new Error(
                        'cannot read image, probably an svg with external resources',
                    )
                }
                return blob as Blob
            })
        }
        return Promise.resolve()
            .then(() => {
                return FileUtils.dataURItoBlob(
                    canvas.toDataURL(type, quality),
                    {},
                )
            })
            .then((blob) => {
                if (blob === null) {
                    throw new Error(
                        'could not extract blob, probably an old browser',
                    )
                }
                return blob
            })
    }
}
