import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "preview-image" }
const _hoisted_2 = ["src"]
const _hoisted_3 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.file._upload && _ctx.file._upload.source_file_src)
      ? (_openBlock(), _createElementBlock("img", {
          key: 0,
          class: "checkerboard-pattern-background",
          src: _ctx.file._upload.source_file_src
        }, null, 8, _hoisted_2))
      : (_openBlock(), _createElementBlock("img", {
          key: 1,
          class: "checkerboard-pattern-background",
          src: _ctx.fileLink
        }, null, 8, _hoisted_3))
  ]))
}