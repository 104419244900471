import EditorLayout from '@/layouts/EditorLayout.vue'
import {
    createRouter as createVueRouter,
    createWebHistory,
    createWebHashHistory,
    RouteRecordRaw,
    Router,
    RouteLocation,
} from 'vue-router'
import Home from '../views/Dashboard/Home.vue'
import DashboardLayout from '@/layouts/DashboardLayout.vue'
import LoginRegisterLayout from '@/layouts/LoginRegisterLayout.vue'
import PublicDownloadLayout from '@/layouts/PublicDownloadLayout.vue'
import SimplePageLayout from '@/layouts/SimplePageLayout.vue'
import VideoBackground from '@/layouts/VideoBackground.vue'
import { Auth0VueClient } from '@auth0/auth0-vue'

import { App, AppContext, unref, watchEffect } from 'vue'
import { useAuth0 } from '@auth0/auth0-vue'
import { Browser } from '@capacitor/browser'

function watchEffectOnce<T>(watcher: () => T, fn: any) {
    const stopWatch = watchEffect(() => {
        if (watcher()) {
            fn()
            stopWatch()
        }
    })
}

function watchEffectOnceAsync<T>(watcher: () => T) {
    return new Promise<void>((resolve) => {
        watchEffectOnce(watcher, resolve)
    })
}

const createAuthGuardCustomHandler = async (
    app: App,
    client: Auth0VueClient,
    to: RouteLocation,
) => {
    const fn = async () => {
        if (unref(client.isAuthenticated)) {
            return true
        }
        await client.loginWithRedirect({
            appState: { target: to.fullPath },
            openUrl: async (url: string) => {
                console.log('URL', url)
                if (app.config.globalProperties.$utils.is_native()) {
                    await Browser.open({
                        url,
                        windowName: '_self',
                        presentationStyle: 'popover',
                    })
                } else {
                  window.location.replace(url)
                }
            },
        })
        return false
    }
    if (!unref(client.isLoading)) {
        return fn()
    }
    await watchEffectOnceAsync(() => !unref(client.isLoading))
    return fn()
}

//@ts-ignore - for some reason TypeScript does not like that.
const createAuthGuardCustom = (app: App) => {
    return async (to: RouteLocation) => {
        const auth0 = app.config.globalProperties.$auth0 as Auth0VueClient
        return createAuthGuardCustomHandler(app, auth0, to)
    }
}

//@ts-ignore - for some reason TypeScript does not like that.
const redirectLoginState = (app: App, logged: any, notLogged: any) => {
    return async (to: RouteLocation) => {
        const auth0 = app.config.globalProperties.$auth0 as Auth0VueClient
        const fn = () => {
            if (unref(auth0.isAuthenticated)) {
                return logged
            }
            return notLogged
        }
        if (!unref(auth0.isLoading)) {
            return fn()
        }
        await watchEffectOnceAsync(() => !unref(auth0.isLoading))
        return fn()
    }
}

export function createRouter(app: App): Router {
    return createVueRouter({
        history: createWebHistory(process.env.BASE_URL),
        routes: [
            {
                path: '/login',
                name: 'Login',
                meta: {
                    title: 'Login',
                    path: '/login',
                    layout: LoginRegisterLayout,
                },
                component: () =>
                    import(
                        /* webpackChunkName: "auth" */ '../views/Auth/Login.vue'
                    ),
            },
            {
                path: '/register',
                name: 'Register',
                meta: {
                    title: 'Register',
                    path: '/register',
                    layout: LoginRegisterLayout,
                },
                component: () =>
                    import(
                        /* webpackChunkName: "auth" */ '../views/Auth/Register.vue'
                    ),
            },
            {
                path: '/forgot-password',
                name: 'ForgotPassword',
                meta: {
                    title: 'Forgot Password',
                    path: '/forgot-password',
                    layout: LoginRegisterLayout,
                },
                component: () =>
                    import(
                        /* webpackChunkName: "auth" */ '../views/Auth/ForgotPassword.vue'
                    ),
            },
            {
                path: '/change-password/:confirmcode',
                name: 'ChangePassword',
                meta: {
                    title: 'Change Password',
                    path: '/change-password',
                    layout: LoginRegisterLayout,
                },
                component: () =>
                    import(
                        /* webpackChunkName: "auth" */ '../views/Auth/ChangePassword.vue'
                    ),
            },
            {
                path: '/share/:userid/:fileid',
                name: 'PublicViewDownload',
                meta: {
                    title: 'Public View Download',
                    path: '/share',
                    layout: PublicDownloadLayout,
                },
                component: () =>
                    import(
                        /* webpackChunkName: "share" */ '../views/PublicDownload/share.vue'
                    ),
            },

            {
                path: '/',
                name: 'Home',
                meta: {
                    title: 'Home',
                    searchbar: true,
                    path: '/',
                    layout: DashboardLayout,
                },
                component: Home,
                beforeEnter: redirectLoginState(
                    app,
                    true,
                    { name: 'Login' },
                ),
            },
            {
                path: '/_internal/elements',
                name: 'Elements',
                meta: {
                    title: 'Elements',
                    scrollable: true,
                    searchbar: false,
                    padding: false,
                    path: '/elements',
                    layout: DashboardLayout,
                },
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () =>
                    import(
                        /* webpackChunkName: "elements" */ '../views/Dashboard/Elements.vue'
                    ),
            },
            {
                path: '/files/:path(.*)*',
                name: 'Files',
                meta: {
                    title: 'Files',
                    scrollable: false,
                    searchbar: true,
                    path: '/files',
                    layout: DashboardLayout,
                },
                beforeEnter: createAuthGuardCustom(app),
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () =>
                    import(
                        /* webpackChunkName: "files" */ '../views/Dashboard/Files/Files.vue'
                    ),
            },
            {
                path: '/media',
                name: 'Media',
                meta: {
                    title: 'Media',
                    scrollable: false,
                    searchbar: true,
                    path: '/media',
                    layout: DashboardLayout,
                },
                beforeEnter: createAuthGuardCustom(app),
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () =>
                    import(
                        /* webpackChunkName: "files" */ '../views/Dashboard/Files/Media.vue'
                    ),
            },
            {
                path: '/pinned',
                name: 'Pinned',
                meta: {
                    title: 'Pinned',
                    scrollable: false,
                    searchbar: true,
                    path: '/pinned',
                    layout: DashboardLayout,
                },
                beforeEnter: createAuthGuardCustom(app),
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () =>
                    import(
                        /* webpackChunkName: "files" */ '../views/Dashboard/Files/Pinned.vue'
                    ),
            },
            {
                path: '/last-used',
                name: 'LastUsed',
                meta: {
                    title: 'Last Used',
                    scrollable: false,
                    searchbar: true,
                    path: '/last-used',
                    layout: DashboardLayout,
                },
                beforeEnter: createAuthGuardCustom(app),
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () =>
                    import(
                        /* webpackChunkName: "files" */ '../views/Dashboard/Files/LastUsed.vue'
                    ),
            },
            {
                path: '/editor/document',
                name: 'DatosDocs',
                meta: {
                    title: 'Docs',
                    scrollable: false,
                    searchbar: false,
                    padding: false,
                    path: '/editor/document',
                    layout: EditorLayout,
                    editor: true,
                },
                beforeEnter: createAuthGuardCustom(app),
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () =>
                    import(
                        /* webpackChunkName: "editor" */ '../views/Dashboard/App/DocEditorView.vue'
                    ),
            },
            {
                path: '/editor/pdf',
                name: 'PDFEditor',
                meta: {
                    title: 'PDF',
                    scrollable: false,
                    searchbar: false,
                    padding: false,
                    path: '/editor/pdf',
                    layout: EditorLayout,
                    editor: true,
                },
                beforeEnter: createAuthGuardCustom(app),
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () =>
                    import(
                        /* webpackChunkName: "editor" */ '../views/Dashboard/App/PDFEditorView.vue'
                    ),
            },
            {
                path: '/subscription',
                name: 'Subscription',
                meta: {
                    title: 'Subscription',
                    scrollable: true,
                    searchbar: false,
                    path: '/subscription',
                    layout: DashboardLayout,
                },
                beforeEnter: createAuthGuardCustom(app),
                component: () =>
                    import(
                        /* webpackChunkName: "subscription" */ '../views/Dashboard/Subscription/index.vue'
                    ),
            },
            {
                path: '/subscription/success',
                name: 'Subscription Success',
                meta: {
                    title: 'Subscription Success',
                    scrollable: true,
                    searchbar: false,
                    path: '/subscription/success',
                    layout: DashboardLayout,
                },
                beforeEnter: createAuthGuardCustom(app),
                component: () =>
                    import(
                        /* webpackChunkName: "subscription" */ '../views/Dashboard/Subscription/Success.vue'
                    ),
            },

            {
                path: '/settings',
                name: 'settings',
                meta: {
                    title: 'Settings',
                    scrollable: true,
                    searchbar: false,
                    padding: false,
                    path: '/settings',
                    layout: DashboardLayout,
                },
                beforeEnter: createAuthGuardCustom(app),
                component: () =>
                    import(
                        /* webpackChunkName: "files" */ '../views/Dashboard/Settings.vue'
                    ),
            },
            {
                path: '/terms-of-service',
                name: 'termsofservice',
                meta: {
                    title: 'Term of Service',
                    path: '/terms-of-service',
                    layout: SimplePageLayout,
                },
                component: () =>
                    import(
                        /* webpackChunkName: "legal" */ '../views/Legal/TermsOfService.vue'
                    ),
            },
            {
                path: '/privacy-policy',
                name: 'privacypolicy',
                meta: {
                    title: 'Privacy Policy',
                    path: '/privacy-policy',
                    layout: SimplePageLayout,
                },
                component: () =>
                    import(
                        /* webpackChunkName: "legal" */ '../views/Legal/PrivacyPolicy.vue'
                    ),
            },
            {
                path: '/agreement',
                name: 'agreement',
                meta: {
                    title: 'Agreement',
                    path: '/agreement',
                    layout: VideoBackground,
                },
                component: () =>
                    import(
                        /* webpackChunkName: "files" */ '../views/Legal/Agreement.vue'
                    ),
            },
            {
                path: '/:catchAll(.*)',
                redirect: '/login',
            },
        ],
        strict: true,
    })
}
