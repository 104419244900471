import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["preview-wrapper", {'spacing': _ctx.preview.spacing}])
  }, [
    (_ctx.preview.condition())
      ? (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.preview.component), {
          key: 0,
          file: _ctx.file,
          "file-link": _ctx.file_link,
          "file-download": _ctx.file_download
        }, null, 8, ["file", "file-link", "file-download"]))
      : _createCommentVNode("", true)
  ], 2))
}