
import { Options, Vue } from 'vue-class-component'
import { YButton, YMenuItem, YInputText, YModal } from '@pacprotocol/yanui'

@Options({
    name: 'Dashboard',
    components: {
        YMenuItem,
        YInputText,
        YButton,
        YModal,
    },
})
export default class LoginRegisterLayout extends Vue {
    mounted() {
        //@ts-ignore
        this.$refs.video.playbackRate = 0.6
    }
}
