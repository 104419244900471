
import ModalUtils from '@/scripts/modal'
import { Utils, YButton } from '@pacprotocol/yanui'
import { Options, Vue, prop } from 'vue-class-component'

class Props {
    plan: any = prop({
        required: true,
    })
    recurring = prop({
        required: false,
        default: 'month',
    })
}

@Options({
    components: {
        YButton,
    },
})
export default class StoragePlan extends Vue.with(Props) {
    get price() {
        return this.plan.prices[this.recurring]
    }
    get amount() {
        return Math.round(this.price.amount) / 100
    }
    get recurring_text() {
        return Utils.capitalize_first_letter_sentence(this.recurring)
    }
    get saved_percentage() {
        //Calculate Saving in Percentage with Yearly / Monthly difference (2 month free)
        const monthly = this.plan.prices['month'].amount
        const yearly = this.plan.prices['year'].amount
        const diff = monthly * 12 - yearly
        const saving = (diff / (monthly * 12)) * 100
        return Math.round(saving)
    }

    private choose() {
       // ModalUtils.open_payment(this, {
       //     button_text: 'Add Card',
       //     plan: this.plan,
       // })
    }

    get current_plan() {
        return this.$store.state?.user?.stripe_product_id
    }

    get current_price() {
        return this.$store.state?.user?.stripe_price_id
    }

    get is_current_plan() {
        if (this.current_plan === null) {
            return this.plan.free;
        }
        return this.current_plan === this.plan.product_id
    }
}
