
import { Options, Vue, prop } from 'vue-class-component'
import FileItems from '@/components/FileItems.vue'
import { nanoid } from 'nanoid'
import mimeTypes from 'mime-types'
import FileRepresentationGenerator from '@/scripts/FileRepresentationGenerator/index'
import { Camera, CameraResultType } from '@capacitor/camera'

import {
    YButton,
    YProgressBar,
    FileUtils,
    FileType,
    FileEntityUpload,
    FileEntity,
    Utils,
} from '@pacprotocol/yanui'

class Props {
    folder: FileEntity = prop({
        required: true,
    })
}

@Options({
    components: {
        YButton,
        FileItems,
        YProgressBar,
    },
})
export default class UploadDashboard extends Vue.with(Props) {
    public files: FileEntityUpload[] = []
    public view_mode: any[] = [
        {
            name: 'view-file-grid',
            type: 'grid',
            icon: 'th',
            title: 'Grid View',
            item_height: 140,
            item_width: 100,
            icon_height: 90,
            icon_padding: 18,
            file_name_size: 14,
            size_name_size: 14,
        },
    ]

    public camera_mode: boolean = false
    public camera_stream: any = null

    public file_is_dragging: boolean = false
    public file_drop_container_show: boolean = true
    public file_total_size: number = 0
    private file_is_uploading: boolean = false
    private representation_generator: FileRepresentationGenerator =
        new FileRepresentationGenerator()
    private eta_interval: any = null
    private file_progress: any = {
        upload_started: 0,
        bytes_uploaded: 0,
        bytes_total: 0,
        eta: '',
        progress: 0,
        files_uploaded: 0,
        files_failed: 0,
        status: '',
    }
    private added_files: FileEntity[] = []

    public file_drag_start() {
        this.file_is_dragging = true
    }

    public file_drag_end() {
        this.file_is_dragging = false
    }
    public async file_drag_drop(e: any) {
        await this.append_files(
            (this.$i ? this.$i : this).$yanui.utils.filter_file_from_clipboard(
                e,
            ),
        )
        this.file_drag_end()
    }

    mounted() {
        this.representation_generator.install()
        this.representation_generator.event.on(
            'generated',
            ({ file, thumbnail, preview }) => {
                const index = this.files.findIndex(
                    //@ts-ignore
                    (f) => f._upload.id === file._upload.id,
                )
                //@ts-ignore
                this.files[index]._upload.thumbnail_object_url = thumbnail.url
                //@ts-ignore
                this.files[index]._upload.thumbnail_blob = thumbnail.blob
                //@ts-ignore
                this.files[index]._upload.thumbnail_processing = false
                //@ts-ignore
                this.files[index]._upload.preview_object_url = preview.url
                //@ts-ignore
                this.files[index]._upload.preview_blob = preview.blob
                //@ts-ignore
                this.files[index]._upload.preview_processing = false
            },
        )
        this.representation_generator.event.on('unsupported', (file) => {
            //@ts-ignore
            const index = this.files.findIndex((f) => f.id === file.id)
            //@ts-ignore
            this.files[index]._upload.preview_processing = false
            //@ts-ignore
            this.files[index]._upload.thumbnail_processing = false
            ;(this.$i ? this.$i : this).$plausible.trackEvent(
                'Thumbnail Unsupported',
                {
                    props: {
                        extension: (this.$i
                            ? this.$i
                            : this
                        ).$yanui.file_utils.fileExtension(file.name),
                        size: (this.$i
                            ? this.$i
                            : this
                        ).$yanui.file_utils.bytes_analytics(file.size),
                    },
                },
            )
            //delete this.files[index].upload_object.thumbnail_generating
        })

        //@ts-ignore
        ;(this.$i ? this.$i : this).$global_event.on(
            'files-added',
            async (files: File[]) => {
                await this.append_files(files)
            },
        )
    }

    unmounted() {
        ;(this.$i ? this.$i : this).$global_event.off('files-added')
        this.representation_generator.uninstall()
        this.clearFiles()
    }

    public async add_file_browse(type: 'folder' | 'file' = 'file') {
        const files = await (this.$i ? this.$i : this).$utils.browse_files(
            '*',
            type,
        )
        console.log('ADD FILE', files)
        await this.append_files(files)
    }

    public async camera_upload() {
        if ((this.$i ? this.$i : this).$utils.is_mobile()) {
            const files = await (this.$i ? this.$i : this).$utils.browse_files(
                'image/*',
                'file',
            )
            console.log('ADD FILE', files)
            await this.append_files(files)
        } else {
            if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
                // Not adding `{ audio: true }` since we only want video now
                navigator.mediaDevices
                    .getUserMedia({
                        video: {
                            width: { min: 1280, max: 1560 },
                            height: { min: 720, max: 1440 },
                        },
                        //audio: true
                    })
                    .then((stream) => {
                        this.camera_mode = true
                        this.camera_stream = stream
                        //@ts-ignore
                        this.$refs.videoCamera.srcObject = this.camera_stream
                        //@ts-ignore
                        this.$refs.videoCamera.play()
                        ////video.src = window.URL.createObjectURL(stream);
                        //video.srcObject = stream
                        //video.play()
                    })
            }
        }
    }

    public async camera_cancel() {
        this.camera_mode = false
        if (this.camera_stream) {
            setTimeout(() => {
                //Give some time for animation to close it down.
                this.camera_stream.getTracks().forEach((track: any) => {
                    track.stop()
                })
            }, 500)
        }
    }

    public async camera_take_photo() {
        //@ts-ignore
        //Create Canvas
        const canvas = document.createElement('canvas')

        //@ts-ignore
        const video = this.$refs.videoCamera
        //@ts-ignore
        canvas.width = video.videoWidth
        //@ts-ignore
        canvas.height = video.videoHeight
        //@ts-ignore
        canvas.getContext('2d').drawImage(video, 0, 0)
        // Other browsers will fall back to image/png
        const data = canvas.toDataURL('image/jpeg')
        console.log('DATA', data)
        canvas.toBlob(
            async (blob: any) => {
                //Rename File Name Prompt
                const date_string = new Date().toISOString().split('T')[0]
                let name = await (this.$i ? this.$i : this).$utils.prompt_input(
                    'Rename File',
                    'Enter a name for the file',
                    'cam-' + date_string + '-' + nanoid(6) + '.jpg',
                )

                if (!name.includes('.jpg')) {
                    name = name + '.jpg'
                }
                const file = new File([blob], name, {
                    type: 'image/jpeg',
                    lastModified: Date.now(),
                })

                await this.append_files([file])
                //Destroy canvas
                canvas.remove()
                this.camera_cancel()
            },
            'image/jpeg',
            1.0,
        )
    }

    public enable_file_drop_container_show() {
        if (this.file_progress.status !== 'uploading') {
            this.file_drop_container_show = true
        }
    }

    public clearFiles() {
        for (let i = 0; i < this.files.length; i++) {
            if (this.files[i]._upload?.thumbnail_object_url) {
                //@ts-ignore
                URL.revokeObjectURL(this.files[i]._upload.thumbnail_object_url)
            }
            if (this.files[i]._upload?.preview_object_url) {
                //@ts-ignore
                URL.revokeObjectURL(this.files[i]._upload.preview_object_url)
            }
            this.upload_abort_file(this.files[i])
        }
        this.files = []
        this.enable_file_drop_container_show()
        this.file_is_uploading = false
        this.file_total_size = 0
        clearInterval(this.eta_interval)
        ;(this.$i ? this.$i : this).$store.state.upload_warn_close = false
        this.file_progress = {
            upload_started: 0,
            bytes_uploaded: 0,
            bytes_total: 0,
            eta: '',
            progress: 0,
            files_uploaded: 0,
            files_failed: 0,
            status: '',
        }
    }

    public async append_files(files: File[]) {
        if (
            ['partly_success', 'success', 'fail'].includes(
                this.file_progress.status,
            )
        ) {
            this.clearFiles()
        }
        if (this.file_is_uploading) {
            return
        }
        this.file_drop_container_show = false
        const new_files = await this.convert_files(files)
        this.files = this.files.concat(new_files)
        this.representation_generator.onFileAdded(new_files)
    }

    public async convert_files(files: File[]): Promise<FileEntityUpload[]> {
        const converted_files: FileEntityUpload[] = []
        for (let i = 0; i < files.length; i++) {
            const file = new File([files[i]], files[i].name, {
                type: files[i].type,
                lastModified: Date.now(), //Fix for Safari iOS where size 0 can occur
            })
            const exists_file = this.files.find(
                (_file: FileEntity) => _file.name == file.name,
            )

            let mime_type = mimeTypes.lookup(file.name)
            if (!mime_type) {
                mime_type = 'application/octet-stream'
            }
            let extension: string = (
                this.$i ? this.$i : this
            ).$yanui.file_utils.fileExtension(file.name)

            if (!exists_file) {
                const file_id = (
                    this.$i ? this.$i : this
                ).$utils.file_generate_id()

                let parent_folder = this.folder ? this.folder.id : ''
                if ((this.$i ? this.$i : this).$route.name !== 'Files') {
                    parent_folder = ''
                }

                const file_upload: FileEntityUpload = {
                    id: file_id,
                    id_sha256: await (this.$i ? this.$i : this).$utils.sha256(
                        file_id,
                    ),
                    secret: (this.$i
                        ? this.$i
                        : this
                    ).$utils.file_generate_secret(),
                    name: file.name,
                    size: file.size,
                    type: FileType.FILE,
                    created_at: Date.now(),
                    updated_at: Date.now(),
                    in_folder: parent_folder,
                    _upload: {
                        id: (this.$i
                            ? this.$i
                            : this
                        ).$utils.file_generate_id(),
                        source_file: file,
                        source_file_src: URL.createObjectURL(file),
                        uploading: false,
                        upload_status: 'idle',
                        progress: 0,
                        bytes_uploaded: 0,
                    },
                }
                converted_files.push(file_upload)
                this.file_total_size += file.size
            }
        }
        return converted_files
    }

    public upload_abort_file(file: FileEntityUpload) {
        if (file._upload && file._upload.uploading) {
            file._upload.uploading.abort()
            file._upload.upload_status = 'aborted'
        }
    }

    public upload_start() {
        ;(this.$i ? this.$i : this).$store.state.upload_warn_close = true
        for (let i = 0; i < this.files.length; i++) {
            //@ts-ignore
            this.files[i]._upload.upload_status = 'in_queue'
        }
        this.file_is_uploading = true
        this.file_progress.upload_started = Date.now()
        this.file_progress.bytes_total = this.file_total_size
        this.file_progress.files_uploaded = 0
        this.file_progress.status = 'uploading'

        this.file_progress.eta = FileUtils.get_eta_pretty(this.file_progress)
        this.eta_interval = setInterval(() => {
            this.file_progress.eta = FileUtils.get_eta_pretty(
                this.file_progress,
            )
        }, 1000)

        this.$nextTick(() => {
            this.upload_files()
        })
    }

    public upload_files(i: number = 0) {
        if (!this.file_is_uploading) {
            return
        }
        this.upload_file(this.files[i], (aborted: boolean) => {
            if (!aborted) {
                i++
            }
            console.log('UPLOADED FILE INDEX', i)
            if (i < this.files.length) {
                this.upload_files(i)
            } else {
                //Upload is done
                clearInterval(this.eta_interval)
                this.file_progress.progress = 100
                ;(this.$i ? this.$i : this).$store.state.upload_warn_close =
                    false
                if (this.file_progress.files_failed == this.files.length) {
                    this.file_progress.status = 'fail'
                    this.file_progress.eta = 'All failed!'
                } else if (this.file_progress.files_failed > 0) {
                    this.file_progress.status = 'partly_success'
                    this.file_progress.eta = 'Only few file(s) were uploaded!'
                } else if (
                    this.file_progress.files_uploaded == this.files.length
                ) {
                    this.file_progress.status = 'success'
                    this.file_progress.eta = 'Completed!'
                } else {
                    this.file_progress.status = 'partly_success'
                    this.file_progress.eta = 'Only few file(s) were uploaded!'
                }
                this.$emit('files-added', this.added_files)
                ;(this.$i ? this.$i : this).$utils.database_upload_trigger(0) //Instant DB Upload
                //;(this.$i ? this.$i : this).$yandna
                //    .profile_get()
                //    .then((profile: any) => {
                //        (this.$i ? this.$i : this).$store.commit('profile', {
                //            ...profile.profile,
                //            ...profile.user,
                //        })
                //    })
                this.added_files = []
            }
        })
    }

    public async upload_file(file: FileEntityUpload, next: CallableFunction) {
        const space_used = (this.$i ? this.$i : this).$store.state?.space_used
        const storage_available_total = (this.$i ? this.$i : this).$store.state
            ?.storage_total_available
        const storage_left = storage_available_total - space_used

        if (storage_left < file.size) {
            if (file._upload) {
                file._upload.upload_status = 'error'
                file._upload.uploading = false
                file._upload.progress = 0
                file._upload.bytes_uploaded = 0
            }
            this.file_progress.files_failed++
            this.file_progress.bytes_failed += file.size
            this.file_progress.bytes_uploaded += file.size
            this.file_progress.progress = Math.round(
                (this.file_progress.bytes_uploaded /
                    this.file_progress.bytes_total) *
                    100,
            )
            this.file_progress.eta = FileUtils.get_eta_pretty(
                this.file_progress,
            )
            next(false)
            return
        }

        if (!file._upload) {
            return
        }
        const _file = file._upload.source_file
        const contentType = mimeTypes.lookup(file.name)

        file._upload.upload_status = 'uploading'
        const password = await (this.$i
            ? this.$i
            : this
        ).$masterkey.derived_password_file(file.secret)
        //const password_hex = new Uint8Array(await crypto.subtle.exportKey("raw", password)).reduce((a, b) => a + b.toString(16).padStart(2, '0'), '')
        //console.log('Password Upload', password_hex);
        file._upload.uploading = new AbortController()
        console.log('1. FILE UPLOAD', file._upload.source_file)
        console.log('FILE DETAILS', file)
        await (this.$i ? this.$i : this).$utils.file_upload(
            file._upload.source_file,
            password,
            {
                id: file.id_sha256,
            },
            {
                on_upload_progress: (
                    progress: number,
                    bytes_uploaded: number,
                    bytes_loaded: number,
                ) => {
                    if (file._upload) {
                        //file._upload.progress = progress
                        //@ts-ignore
                        this.file_progress.bytes_uploaded += bytes_loaded
                        this.file_progress.progress = Math.min(
                            100,
                            Math.floor(
                                (this.file_progress.bytes_uploaded /
                                    this.file_progress.bytes_total) *
                                    10000,
                            ) / 100,
                        )

                        file._upload.bytes_uploaded = bytes_uploaded
                        file._upload.progress = progress / 100
                    }
                },
                on_upload_error: (error: any) => {
                    console.log('UPLOAD ERROR', error)
                    if (file._upload) {
                        file._upload.upload_status = 'error'
                        file._upload.uploading = false
                        file._upload.progress = 0
                        file._upload.bytes_uploaded = 0
                    }
                    this.file_progress.files_failed++
                    this.file_progress.bytes_failed += file.size
                    this.file_progress.bytes_uploaded += file.size
                    this.file_progress.progress = Math.round(
                        (this.file_progress.bytes_uploaded /
                            this.file_progress.bytes_total) *
                            100,
                    )
                    this.file_progress.eta = FileUtils.get_eta_pretty(
                        this.file_progress,
                    )
                },
            },
            {
                signal: file._upload.uploading.signal,
            },
        )

        console.log('1. ID', file.id)

        await (this.$i ? this.$i : this).$utils.file_thumbnail_upload(
            file,
            password,
        )
        await (this.$i ? this.$i : this).$utils.file_preview_upload(
            file,
            password,
        )

        file.name = await (this.$i
            ? this.$i
            : this
        ).$utils.file_name_duplicate_rename(
            file.name,
            (this.$i ? this.$i : this).$store.state.current_folder
                ? (this.$i ? this.$i : this).$store.state.current_folder.name
                : '',
        )
        const clone_file = { ...file }
        delete clone_file._upload
        const __file = await (this.$i ? this.$i : this).$yanui.db.files.insert(
            clone_file,
        )
        console.log('Added File', __file)
        if (clone_file) {
            await (this.$i ? this.$i : this).$utils.folder_update_stats(
                clone_file.in_folder,
            )
        }

        file._upload.progress = 1
        file._upload.upload_status = 'uploaded'
        this.file_progress.files_uploaded++
        ;(this.$i ? this.$i : this).$plausible.trackEvent(
            'File Upload Success',
            {
                props: {
                    extension: (this.$i
                        ? this.$i
                        : this
                    ).$yanui.file_utils.fileExtension(file.name),
                    size: (this.$i
                        ? this.$i
                        : this
                    ).$yanui.file_utils.bytes_analytics(file.size),
                },
            },
        )
        next()

        /*

                if (file.upload_object.upload_status !== 'aborted') {
                    file.upload_object.upload_status = 'error'
                    file.upload_object.upload_error = await err
                    console.error('FILE FAILED:', file.name, await err)
                    file.upload_object.request = undefined
                    this.file_progress.files_failed++
                    ;(this.$i ? this.$i : this).$plausible.trackEvent(
                        'File Upload Failed',
                        {
                            props: {
                                error: err.message,
                                extension: file.extension.toLowerCase(),
                                size: (this.$i
                                    ? this.$i
                                    : this
                                ).$yanui.file_utils.bytes_analytics(file.size),
                            },
                        },
                    )
                    next()
                } else {
                    //this.file_progress.bytes_total -= file.size;
                    ;(this.$i ? this.$i : this).$plausible.trackEvent(
                        'File Upload Aborted',
                    )
                    next(true)
                }
        */
    }

    public file_remove(file: FileEntityUpload, $el: any) {
        this.upload_abort_file(file)
        this.representation_generator.onFileRemoved(file)
        if (file._upload && file._upload.preview_object_url) {
            URL.revokeObjectURL(file._upload.preview_object_url)
        }
        const files_filtered = this.files.filter((o) => o.id != file.id)
        if (files_filtered.length !== this.files.length) {
            this.file_total_size -= file.size
        }
        this.files = files_filtered
        if (this.file_progress.bytes_total > 0) {
            if (file._upload) {
                this.file_progress.bytes_uploaded -= file._upload.bytes_uploaded
            }
            this.file_progress.bytes_total -= file.size
        }
    }

    beforeUnmount() {
        this.camera_cancel()
    }
}
