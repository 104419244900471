import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, createVNode as _createVNode, createSlots as _createSlots, Teleport as _Teleport } from "vue"

const _hoisted_1 = { class: "file-items-wrapper" }
const _hoisted_2 = {
  key: 1,
  class: "file-item file-empty"
}
const _hoisted_3 = { class: "file-name-title-slider" }
const _hoisted_4 = { class: "file-size-title-slider secondary-text" }
const _hoisted_5 = {
  ref: "file_drag_element",
  class: "file_drag_element"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FileItem = _resolveComponent("FileItem")!
  const _component_y_list_chunk = _resolveComponent("y-list-chunk")!
  const _component_FileDropdown = _resolveComponent("FileDropdown")!
  const _component_y_button = _resolveComponent("y-button")!
  const _component_Preview = _resolveComponent("Preview")!
  const _component_y_slider = _resolveComponent("y-slider")!
  const _component_y_modal = _resolveComponent("y-modal")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.file_display)
      ? (_openBlock(), _createBlock(_component_y_list_chunk, {
          key: 0,
          ref: "listChunk",
          data: _ctx.file_display,
          id: "id",
          refresh: _ctx.view_mode.refresh,
          "has-card": false,
          class: _normalizeClass(["file-items", ['view-' + _ctx.view_mode.type]]),
          "item-height": _ctx.view_mode.item_height,
          "item-spacing": _ctx.view_mode.type == 'list' ? 8 : 12,
          "item-padding": 0,
          onSizeChange: _ctx.listSizeChanged,
          onLoadMore: _ctx.load_more_files,
          "load-bottom-offset": _ctx.view_mode.load_bottom_offset,
          "stop-scroll": !_ctx.isFor && _ctx.$store.state.is_file_dragging
        }, {
          default: _withCtx((props) => [
            (_ctx.view_mode.type === 'list')
              ? (_openBlock(), _createBlock(_component_FileItem, {
                  file: props.item,
                  key: props.item.id + props.item.name,
                  index: props.index,
                  focus: _ctx.file_details_index == props.index,
                  onFocus: _ctx.fileItemFocusTrigger,
                  type: "list",
                  "view-mode": _ctx.view_mode,
                  onFileOpen: _ctx.open_file_details,
                  "is-for": _ctx.isFor,
                  tool: _ctx.tool,
                  onFileRemove: _ctx.file_remove,
                  onFileRename: ($event: any) => (_ctx.$utils.file_rename_window(props.item)),
                  onFileShare: _ctx.file_share,
                  onFileDrag: _ctx.file_drag
                }, null, 8, ["file", "index", "focus", "onFocus", "view-mode", "onFileOpen", "is-for", "tool", "onFileRemove", "onFileRename", "onFileShare", "onFileDrag"]))
              : (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(props.item, (sub_file, $index) => {
                  return (_openBlock(), _createElementBlock(_Fragment, {
                    key: sub_file ? sub_file.id + sub_file.name : null
                  }, [
                    sub_file
                      ? (_openBlock(), _createBlock(_component_FileItem, {
                          file: sub_file,
                          key: 
                                    sub_file
                                        ? sub_file.id + sub_file.name
                                        : null
                                ,
                          index: 
                                    props.index * props.item.length + $index
                                ,
                          focus: 
                                    _ctx.file_details_index ==
                                    props.index * props.item.length + $index
                                ,
                          onFocus: _ctx.fileItemFocusTrigger,
                          type: "grid",
                          "view-mode": _ctx.view_mode,
                          onFileOpen: _ctx.open_file_details,
                          "is-for": _ctx.isFor,
                          onFileRemove: _ctx.file_remove,
                          onFileRename: ($event: any) => (
                                    _ctx.$utils.file_rename_window(sub_file)
                                ),
                          onFileShare: _ctx.file_share,
                          onFileDrag: _ctx.file_drag
                        }, null, 8, ["file", "index", "focus", "onFocus", "view-mode", "onFileOpen", "is-for", "onFileRemove", "onFileRename", "onFileShare", "onFileDrag"]))
                      : (_openBlock(), _createElementBlock("div", _hoisted_2))
                  ], 64))
                }), 128))
          ]),
          _: 1
        }, 8, ["data", "refresh", "class", "item-height", "item-spacing", "onSizeChange", "onLoadMore", "load-bottom-offset", "stop-scroll"]))
      : _createCommentVNode("", true),
    _createVNode(_component_y_modal, {
      active: _ctx.file_detals_modal_enabled,
      "onUpdate:active": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.file_detals_modal_enabled) = $event)),
      "append-class": "is-slider",
      "has-card": false,
      overflow: "visible",
      "modal-inner": false,
      "can-cancel": false,
      "can-cancel-background": true,
      "has-animation": false,
      onClose: _ctx.sliderClose
    }, {
      default: _withCtx(() => [
        _createVNode(_component_y_slider, {
          active: _ctx.file_detals_modal_enabled,
          "onUpdate:active": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.file_detals_modal_enabled) = $event)),
          id: 
                    _ctx.file_details && _ctx.file_detals_modal_enabled
                        ? _ctx.file_details.id
                        : 'null-null'
                ,
          "slide-left": _ctx.slider_left,
          "slide-right": _ctx.slider_right,
          onSlide: _ctx.slide,
          "no-space": true
        }, _createSlots({
          default: _withCtx(() => [
            (_ctx.file_details)
              ? (_openBlock(), _createBlock(_component_Preview, {
                  key: 0,
                  file: _ctx.file_details
                }, null, 8, ["file"]))
              : _createCommentVNode("", true)
          ]),
          _: 2
        }, [
          (_ctx.file_details)
            ? {
                name: "title",
                fn: _withCtx(() => [
                  _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.file_names[0] + _ctx.file_names[1] + _ctx.file_names[2]), 1),
                  _createElementVNode("span", _hoisted_4, " - " + _toDisplayString((this.$i
                                ? this.$i
                                : this
                            ).$yanui.file_utils.byte_to_human_readable(
                                _ctx.file_details.size,
                            )), 1)
                ]),
                key: "0"
              }
            : undefined,
          (_ctx.file_details)
            ? {
                name: "action",
                fn: _withCtx(() => [
                  (!_ctx.file_details.upload_object)
                    ? (_openBlock(), _createBlock(_component_FileDropdown, {
                        key: 0,
                        file: _ctx.file_details,
                        type: "list",
                        square: false
                      }, null, 8, ["file"]))
                    : (_openBlock(), _createBlock(_component_y_button, {
                        key: 1,
                        type: "is-danger",
                        icon: "trash-alt",
                        spacing: true,
                        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$utils.file_delete(_ctx.file_details)))
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(" Delete ")
                        ]),
                        _: 1
                      })),
                  _createVNode(_component_y_button, {
                    type: "is-ghost",
                    icon: "times",
                    spacing: true,
                    square: true,
                    onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.file_detals_modal_enabled = false))
                  })
                ]),
                key: "1"
              }
            : undefined
        ]), 1032, ["active", "id", "slide-left", "slide-right", "onSlide"])
      ]),
      _: 1
    }, 8, ["active", "onClose"]),
    (_openBlock(), _createBlock(_Teleport, { to: "#teleport-popup" }, [
      _createElementVNode("div", _hoisted_5, null, 512)
    ]))
  ]))
}