/* eslint-disable */

import { FileEntityUpload } from "@pacprotocol/yanui";
import exifr from "exifr";
import BaseRepresentationGenerator, {BaseRepresentationOptions, BaseRepresentationTypeOptions, RepresentationCaptureInstanceResult, RepresentationCaptureResult} from "./BaseRepresentationGenerator";

export default class ImageRepresentationGenerator extends BaseRepresentationGenerator {
  constructor(opts: BaseRepresentationOptions) {
      super(opts);
  }
      
  protected async capture_process(options: BaseRepresentationTypeOptions): Promise<RepresentationCaptureInstanceResult> {
        if (!this.file._upload) return Promise.reject()
        const originalUrl = URL.createObjectURL(this.file_source)

        const onload = new Promise((resolve, reject) => {
            const image = new Image()
            image.src = originalUrl
            image.addEventListener('load', () => {
                URL.revokeObjectURL(originalUrl)
                resolve(image)
            })
            image.addEventListener('error', (event) => {
                URL.revokeObjectURL(originalUrl)
                this.event.emit('unsupported', this.file)
                reject(event.error || new Error('Could not create thumbnail'))
            })
        })

        const orientationPromise = exifr
            .rotation(this.file_source)
            .catch(() => 1)

        return Promise.all([onload, orientationPromise])
            .then(([image, orientation]: [any, any]) => {
                const dimensions = this.get_proportional_dimensions(
                    image.width,
                    image.height,
                    options.maxWidth,
                    options.maxHeight,
                    orientation.deg,
                    options.dimension
                )
                const rotatedImage = this.rotate_image(image, orientation)
                console.log("DIMENSIONS", dimensions);
                const resizedImage = this.resize_image(
                    rotatedImage,
                    dimensions.width,
                    dimensions.height,
                ) as HTMLCanvasElement
                return this.canvas_to_blob(
                    resizedImage,
                    options.type,
                    options.quality,
                )
            })
            .then((blob) => {
                return {
                    blob,
                    url: URL.createObjectURL(blob),
                }
            })
  }
}
