import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-b4b1b756"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "distribution-item card" }
const _hoisted_2 = { class: "distribution-rows" }
const _hoisted_3 = { class: "distribution-icon" }
const _hoisted_4 = { class: "distribution-content" }
const _hoisted_5 = { class: "distribution-title" }
const _hoisted_6 = { class: "distribution-subtitle" }
const _hoisted_7 = { class: "distribution-value" }
const _hoisted_8 = { class: "percentage" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_fa = _resolveComponent("fa")!
  const _component_y_tooltip = _resolveComponent("y-tooltip")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_fa, {
          icon: ['fal', _ctx.icon]
        }, null, 8, ["icon"])
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.title), 1),
        _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.subtitle), 1)
      ]),
      _createElementVNode("div", _hoisted_7, [
        _createVNode(_component_y_tooltip, {
          content: "Of total storage",
          type: "grey"
        }, {
          default: _withCtx(() => [
            _createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.value) + " %", 1)
          ]),
          _: 1
        })
      ])
    ])
  ]))
}