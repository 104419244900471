import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "preview-datdoc card card-white shadow" }
const _hoisted_2 = { class: "datdoc-view" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DocEditor = _resolveComponent("DocEditor")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_DocEditor, {
        "file-link": _ctx.fileLink,
        readonly: true,
        "file-name": _ctx.file.name
      }, null, 8, ["file-link", "file-name"])
    ])
  ]))
}