import mitt, { Emitter } from 'mitt'
import {
    FileUtils,
    FileEntity,
    FileEntityUpload,
    Utils,
} from '@pacprotocol/yanui'
import exifr from 'exifr'
/**
 * The Thumbnail Generator plugin
 */
import ImageRepresentationGenerator from './ImageRepresentationGenerator'
import VideoRepresentationGenerator from './VideoRepresentationGenerator'
import BaseRepresentationGenerator from './BaseRepresentationGenerator'

const IMAGE_EXTENSIONS = [
    'jpg',
    'jpeg',
    'png',
    'gif',
    'svg',
    'bmp',
    'webp',
    'avif',
]

const VIDEO_EXTENSIONS = ['mp4', 'webm', 'mov', 'avi', 'wmv', 'flv', 'mkv']

//FileRepresentationGenerator
export default class FileRepresentationGenerator {
    private queue: FileEntityUpload[] = []
    private queue_processing_max = 8
    private queue_processing = 0

    private queueProcessing = false
    private defaultThumbnailDimension = 0
    private thumbnailType: string
    private thumbnailQuality: number

    private opts = {
        thumbnailType: 'image/jpeg',
        thumbnailQuality: 80,
        thumbnailWidth: 200,
        thumbnailHeight: 200,
    }
    public event: Emitter<any> = mitt()

    constructor(opts: any = {}) {
        this.queue = []
        this.queueProcessing = false
        this.defaultThumbnailDimension = 200
        this.thumbnailType = this.opts.thumbnailType || 'image/jpeg'
        this.thumbnailQuality = this.opts.thumbnailQuality || 80
        this.opts = { ...this.opts, ...opts }
    }

    public setThumbnail(
        file: FileEntityUpload,
        thumbnail_url: string,
        thumbnail_blob: Blob,
    ) {
        if (!file._upload) return
        file._upload.thumbnail_object_url = thumbnail_url
        file._upload.thumbnail_blob = thumbnail_blob
        file._upload.thumbnail_processing = false
        console.log('SET THUMBNAIL?')
    }
    public setPreview(
        file: FileEntityUpload,
        preview_url: string,
        preview_blob: Blob,
    ) {
        if (!file._upload) return
        file._upload.preview_object_url = preview_url
        file._upload.preview_blob = preview_blob
        file._upload.preview_processing = false
    }

    public setRunningUrl(file: FileEntityUpload) {
        if (!file._upload) return
        file._upload.thumbnail_processing = true
        file._upload.preview_processing = true
    }

    public addToQueue(file: FileEntityUpload) {
        this.setRunningUrl(file)
        this.queue.push(file)
        if (this.queueProcessing === false) {
            this.processQueue()
        }
    }

    public async processQueue(): Promise<void> {
        this.queueProcessing = true
        console.log('processQueue')
        do {
            if (
                this.queue_processing <= this.queue_processing_max &&
                this.queue.length > 0
            ) {
                this.queue_processing++
                const current = this.queue.shift()
                if (!current) {
                    console.error(
                        '[ThumbnailGenerator] file was removed before a thumbnail could be generated, but not removed from the queue. This is probably a bug',
                        'error',
                    )
                    return
                }
                this.representation_generate(current)
                    .catch(() => {
                        this.event.emit('unsupported', current)
                    })
                    .then(() => {
                        this.queue_processing--
                    })
            } else {
                await Utils.sleep(10)
            }
        } while (this.queue.length > 0 || this.queue_processing > 0)
        console.log(
            'queue all generated?',
            this.queue.length,
            this.queue_processing,
        )
        this.queueProcessing = false
        this.event.emit('all-generated')
    }

    protected is_image_representation_supported(extension: string) {
        if (!extension) return false
        if (IMAGE_EXTENSIONS.includes(extension)) {
            return true
        }
        return false
    }

    protected is_video_representation_supported(extension: string) {
        if (!extension) return false
        if (VIDEO_EXTENSIONS.includes(extension)) {
            return true
        }
        return false
    }

    representation_type(file: FileEntityUpload) {
        if (!file._upload) return 'unknown'
        const extension = FileUtils.fileExtension(file.name)
        if (!extension) {
            return 'unknown'
        }
        if (this.is_image_representation_supported(extension)) {
            return 'image'
        } else if (this.is_video_representation_supported(extension)) {
            return 'video'
        }
        return 'unknown'
    }

    async representation_generate(file: FileEntityUpload) {
        console.log('queue processing')
        const representation_type = this.representation_type(file)
        let representation_generator: BaseRepresentationGenerator

        switch (representation_type) {
            case 'image': {
                representation_generator = new ImageRepresentationGenerator({
                    file,
                })
                break
            }
            case 'video': {
                representation_generator = new VideoRepresentationGenerator({
                    file,
                })
                break
            }
            default:
                return
        }
        console.log('REPRESENTATION LOADED', representation_generator)
        const result = await representation_generator.capture(file)

        if (result) {
            this.event.emit('generated', { file, ...result })
        }
        return Promise.resolve()

        /*
        if (FileUtils.isImagePreviewSupported(extension)) {
            return this.createThumbnail(
                file,
                this.opts.thumbnailWidth,
                this.opts.thumbnailHeight,
            )
                .then(({ url, blob }: any) => {
                    this.setThumbnail(file, url, blob)
                    //console.log(
                    //    `[ThumbnailGenerator] Generated Thumbnail for ${file.id}`,
                    //)
                    this.event.emit('generated', { file, url, blob })
                })
                .catch((err) => {
                    //console.log(
                    //    `[ThumbnailGenerator] Failed Image Thumbnail for ${file.id}:`,
                    //    'warning',
                    //)
                    //console.warn(err, 'warning')
                    this.event.emit('unsupported', file)
                    this.event.emit('error', { file, err })
                })
        }

        if (FileUtils.isVideoPreviewSupported(extension)) {
            const thumbnails = new VideoThumbnails({
                count: 1,
                maxWidth: this.opts.thumbnailWidth,
                maxHeight: this.opts.thumbnailHeight,
                thumbnailType: this.thumbnailType,
                thumbnailQuality: this.thumbnailQuality,
            })

            const reset = () => {
                console.error('[THUMBNAIL BRR]', file)
                this.event.emit('unsupported', file)
                thumbnails.destroy()
            }

            //Captured a thumb
            thumbnails.event.on('capture', ({ url, blob }: any) => {
                this.setThumbnail(file, url, blob)
                //console.log(
                //    `[ThumbnailGenerator] Generated Video Thumbnail for ${file.id}`,
                //)
                this.event.emit('generated', { file, url, blob })
                thumbnails.destroy()
            })

            thumbnails.event.on('unsupported', () => reset())
            thumbnails.event.on('aborted', () => reset())
            thumbnails.capture(file._upload.source_file)
        }
        */
        //return Promise.resolve()
    }

    onFileAdded = (files: FileEntityUpload | FileEntityUpload[]) => {
        if (!Array.isArray(files)) {
            files = [files]
        }
        for (let i = 0; i < files.length; i++) {
            const file = files[i]
            const extension = FileUtils.fileExtension(file.name)
            if (
                !file.thumbnail_id &&
                file._upload &&
                file._upload.source_file &&
                extension &&
                (FileUtils.isImagePreviewSupported(extension) ||
                    FileUtils.isVideoPreviewSupported(extension))
            ) {
                this.addToQueue(file)
            } else {
                if (file && file._upload) {
                    this.event.emit('unsupported', file)
                }
            }
        }
    }

    /**
     * Cancel a lazy request for a thumbnail if the thumbnail has not yet been generated.
     */
    onCancelRequest = (file: FileEntityUpload) => {
        const index = this.queue.findIndex((f) => f.id == file.id)
        if (index !== -1) {
            this.queue.splice(index, 1)
        }
    }

    /**
     * Clean up the thumbnail for a file. Cancel lazy requests and free the thumbnail URL.
     */
    onFileRemoved = (file: FileEntityUpload) => {
        const index = this.queue.findIndex((f) => f.id == file.id)
        if (index !== -1) {
            this.queue.splice(index, 1)
        }

        // Clean up object URLs.
        if (!file._upload) return
        if (
            file._upload.thumbnail_object_url &&
            FileUtils.isObjectURL(file._upload.thumbnail_object_url)
        ) {
            URL.revokeObjectURL(file._upload.thumbnail_object_url)
        }
    }

    install() {
        this.event.on('file-removed', this.onFileRemoved)
        this.event.on('file-added', this.onFileAdded)
        this.event.on('cancel', this.onCancelRequest)
    }

    uninstall() {
        this.event.off('file-removed', this.onFileRemoved)
        this.event.off('file-added', this.onFileAdded)
        this.event.off('cancel', this.onCancelRequest)
    }
}
