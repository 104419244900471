import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "preview-video" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_y_video_player = _resolveComponent("y-video-player")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_y_video_player, {
      parent: _ctx.el,
      options: {
                controls: true,
                poster: _ctx.thumnbail,
                width: _ctx.width,
                height: _ctx.height,
                autoplay: false,
                sources: [
                {
                    src: _ctx.fileLink,
                    type: _ctx.mimeType,
                }
                ],
                ..._ctx.options
            }
    }, null, 8, ["parent", "options"])
  ]))
}