
import { Options, Vue, prop } from 'vue-class-component'

import { YInputText } from '@pacprotocol/yanui'

class Props {
    readonly = prop({
        type: Boolean,
        required: false,
        default: false,
    })
    phrases = prop({
        type: [String],
        required: false,
        // eslint-disable-next-line prettier/prettier
        default: ['', '', '', '', '', '', '', '', '', '', '', ''],
    })
}

@Options({
    components: {
        YInputText,
    },
})
export default class SeedPhrase extends Vue.with(Props) {
    private value: string[] = []

    mounted() {
        //@ts-ignore
        this.value = this.phrases
    }

    onpaste(event: any) {
        const clipboardData = event.clipboardData || window.Clipboard
        const pastedData = clipboardData.getData('Text')
        const pastedDataArray = pastedData.split(' ')
        if (pastedDataArray.length === 12) {
            setTimeout(() => {
                this.value = pastedDataArray
                this.$emit('change', this.value)
            })
            //this.$emit('change', this.value);
        }
        
        //shrimp what stock target foam embark engine wagon scatter fluid student silk food stem cry brush gym inner lawsuit repair sleep source plunge stand
    }
}
