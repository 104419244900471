
import { Options, Vue, prop } from 'vue-class-component'
import FileIcon from '@/components/FileIcon.vue'
import { FileEntity } from '@pacprotocol/yanui'
import { YButton } from '@pacprotocol/yanui'
import DocEditor from '@/components/Editor/DocEditor.vue'

class Props {
    file: FileEntity = prop({
        required: true,
    })
    fileLink: string = prop({
        type: String,
        required: true,
    })
    fileDownload: string = prop({
        required: true,
    })
}

@Options({
    components: {
        YButton,
        FileIcon,
        DocEditor,
    },
})
export default class PreviewDatDoc extends Vue.with(Props) {

}
