
import { Options, Vue, prop } from 'vue-class-component'
import { FileEntityUpload, FileValidator } from '@pacprotocol/yanui'
import mimeTypes from 'mime-types'

class Props {
    size = prop({
        required: false,
        default: 176,
    })
}

@Options({
    components: {},
})
export default class Avatar extends Vue.with(Props) {
    private avatar_loaded = false
    private avatar_id: string = ''

    async created() {
        await this.$yanui.db_ready()
        const query = this.$yanui.db.user.findOne()
        query.$.subscribe((user: any) => {
            this.avatar_id = user?.avatar_id_sha256 ?? ''
            this.initialize()
        })
        const user = await query.exec()
        this.avatar_id = user?.avatar_id_sha256 ?? ''
        console.log('AVATAR ID', this.avatar_id, user)
        this.initialize()
    }

    private async initialize() {
        this.avatar_loaded = false
        if (!this.avatar_id) return
        const password = await (this.$i
            ? this.$i
            : this
        ).$masterkey.derived_password_file(
            'avatar-' +
                (this.$i ? this.$i : this).$auth0.user.value.sub.split('|')[1],
        )
        await this.$yanui.file_server.file_meta_add({
            id: this.avatar_id,
            mimetype:
                mimeTypes.lookup('avatar.jpg') || 'application/octet-stream',
            name: 'avatar.jpg',
            size: -1,
            key: password,
            type: '',
        })
        this.avatar_loaded = true
    }
}
