declare module '@vue/runtime-core' {
    interface ComponentCustomProperties {
        $el_utils: ElementUtils
        $theme: any
        $state: State
        $yanid: yanIDClient
        $yandna: yanDNAClient
        $yanui: yanUI
        $global_event: Emitter<any>
        $i: any
        $utils: DatosUtils
        $masterkey: DatosMasterKey
        $modal: ModalUtils
    }
}

declare global {
    interface Window {
        Vue: any
        is_logged: boolean
    }
}

//@ts-ignore
window.HELP_IMPROVE_VIDEOJS = false
//@ts-ignore
navigator.getUserMedia = //@ts-ignore
    navigator.getUserMedia || //@ts-ignore
    navigator.webkitGetUserMedia || //@ts-ignore
    navigator.mozGetUserMedia || //@ts-ignore
    navigator.msGetUserMedia

//import './registerServiceWorker';

import mitt, { Emitter } from 'mitt'
import { createApp, reactive } from 'vue'
import App from './App.vue'
import FontAwesomeIcon from './importFontAwesome'
import {
    FontAwesomeLayers,
    FontAwesomeLayersText,
} from '@fortawesome/vue-fontawesome'

import { createRouter } from './router'

import { Vue3Plausible } from 'vue-plausible'

import { yanUI, ElementUtils } from '@pacprotocol/yanui'
import '@pacprotocol/yanui/dist/style.css'

import VueScreen from 'vue-screen'

import state from './state'
import { VueWindowSizePlugin } from 'vue-window-size/option-api'
import { yanIDClient, yanDNAClient } from 'yandna-api-internal'
import DatosUtils from './scripts/utils'
import DatosMasterKey from './scripts/masterkey'
import { createAuth0 } from '@auth0/auth0-vue'
import ModalUtils from './scripts/modal'
import { StatusBar, Style } from '@capacitor/status-bar'
import { Browser } from '@capacitor/browser'

window.oncontextmenu = function (event: any) {
    event.preventDefault()
    event.stopPropagation()
    return false
}

const app = createApp(App)
const vueThis = app.config.globalProperties

vueThis.$el_utils = new ElementUtils()

/*
vueThis.$yanid = new yanIDClient({
    base_path: process.env.VUE_APP_DATOSDRIVE_BACKEND_URL,
})
*/

vueThis.$global_event = mitt()
vueThis.$modal = new ModalUtils(app)
vueThis.$utils = new DatosUtils(app)
vueThis.$masterkey = new DatosMasterKey(app)
app.provide('$utils', vueThis.$utils)
app.provide('$masterkey', vueThis.$masterkey)
vueThis.$i = false

/*
vueThis.$yandna = new yanDNAClient({
    base_path: process.env.VUE_APP_DATOSDRIVE_BACKEND_URL,
    call_access_token: () => vueThis.$yanid.token(),
    call_once_token: () => vueThis.$yanid.token_once(),
})
*/

app.component('fa', FontAwesomeIcon)
app.component('fa-text', FontAwesomeLayersText)

const redirect_to_dashboard: any = [
    '/',
    '/login',
    '/register',
    '/forgot-password',
    '/change-password',
]

const ignore_redirect: any = [
    '/privacy-policy',
    '/terms-of-service',
    '/v',
    '/sw.js',
]

const router = createRouter(app)
router.afterEach((to, from) => {
    // Use next tick to handle router history correctly
    // see: https://github.com/vuejs/vue-router/issues/914#issuecomment-384477609

    //@ts-ignore
    if (to.meta.title) {
        document.title = to.meta.title + ' - DatosDrive' || 'DatosDrive'
    }

    if (process.env.NODE_ENV === 'production') {
        let to_path = to.meta.path
        let from_path = from?.meta?.path || to.meta.path

        if (
            to_path === '/files' &&
            to.path.replace(to_path, '').trim() !== ''
        ) {
            to_path = '/files/<folder_name>'
        }

        if (to.meta.path === '/v') to_path = to.path

        if (
            from_path === '/files' &&
            from.path.replace(from_path, '').trim() !== ''
        ) {
            from_path = '/files/<folder_name>'
        }

        if (from?.meta?.path || to.meta.path === '/v') from_path = from.path

        vueThis.$plausible.trackPageview({
            url: location.origin + to_path,
            referrer: location.origin + from_path,
        })
    }
})

router.beforeResolve(async (to, from, next) => {
    return next()
    if (
        ignore_redirect.includes(
            to.meta && to.meta.path ? to.meta.path : to.path,
        )
    ) {
        return next()
    }
    return next()

    const is_logged = localStorage.getItem('is_logged')
    console.log('IS_LOGGED', is_logged)

    if (is_logged) {
        if (
            vueThis.$store.state.user.terms_of_services_consent_version ||
            vueThis.$store.state.user.privacy_policy_consent_version
        ) {
            console.log('FROM', from)
            console.log('TO', to)
            if (to.name === 'agreement') {
                return next()
            } else {
                vueThis.$store.state.redirect = to
                return next({ name: 'agreement' })
            }
        }

        if (to.name === 'agreement') {
            return next({ name: 'Files' })
        }

        //You're logged-in
        if (to.meta.path === '/') {
            return next({ name: 'Files' })
        }
        //@ts-ignore
        if (redirect_to_dashboard.includes(to.meta.path)) {
            //Those path are not relevant
            return next({ name: 'Files' })
        } else {
            return next()
        }
    } else {
        if (to.meta.path === '/' || to.name === 'agreement') {
            return next({ name: 'Login' })
        }
        //@ts-ignore
        if (!redirect_to_dashboard.includes(to.meta.path)) {
            return next({ name: 'Login' })
        } else {
            return next()
        }
    }
})

app.use(VueScreen)
app.use(state)
app.use(router)
app.use(VueWindowSizePlugin)
app.use(Vue3Plausible, {
    domain: 'datosdrive.com',
    trackLocalhost: false,
    apiHost: 'https://investigation.yandna.io',
    enableAutoOutboundTracking: true,
})

app.use(yanUI, {
    icon_component: {
        name: 'fa',
        pack: 'fal',
        component: FontAwesomeIcon,
    },
    router,
    file_server: {
        frontend_url: process.env.VUE_APP_DATOSDRIVE_FRONTEND_URL,
        backend_url: process.env.VUE_APP_DATOSDRIVE_BACKEND_URL,
        websocket_url: process.env.VUE_APP_DATOSDRIVE_WEBSOCKET_URL,
    },
})

console.log('CALLBACK', process.env.VUE_APP_AUTH0_CALLBACK_URL_NATIVE)
console.log('CALLBACK', process.env.VUE_APP_AUTH0_CALLBACK_URL)

app.use(
    createAuth0({
        domain: process.env.VUE_APP_AUTH0_ISSUER_BASE_URL || '',
        clientId: process.env.VUE_APP_AUTH0_CLIENT_ID || '',
        // useRefreshTokens: true,
        authorizationParams: {
            redirect_uri: process.env.VUE_APP_AUTH0_CALLBACK_URL,
            audience: process.env.VUE_APP_AUTH0_AUDIENCE || '',
        },
        cacheLocation: 'localstorage'
    }),
)

//Is Capacitor?
if (app.config.globalProperties.$utils.is_native()) {
    if (app.config.globalProperties.$yanui.theme.value == 'light') {
        StatusBar.setStyle({
            style: Style.Light,
        })
    } else {
        StatusBar.setStyle({
            style: Style.Dark,
        })
    }
    //@ts-ignore
    window.open = async (url: string) => {
        console.log("Browser.open", url);
        return Browser.open({
            url,
            windowName: '_self',
            presentationStyle: 'popover',
        })
    }
}

app.mount('#application')

window.Vue = app

const appHeight = () => {
    setTimeout(() => {
        const doc = document.documentElement
        let height = window.innerHeight
        const safeTop = parseInt(
            getComputedStyle(document.body)
                .getPropertyValue('--safe-top')
                .split('px')[0],
        )
        const safeBottom = parseInt(
            getComputedStyle(document.body)
                .getPropertyValue('--safe-bottom')
                .split('px')[0],
        )

        height -= safeTop - safeBottom

        doc.style.setProperty('--app-height', `${height}px`)
    }, 150)
}
window.addEventListener('resize', appHeight)
window.addEventListener('load', appHeight)
appHeight()
