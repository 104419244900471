
import { Options, Vue, prop } from 'vue-class-component'
import PreviewImage from './PreviewImage.vue'
import PreviewVideo from './PreviewVideo.vue'
import PreviewMusic from './PreviewMusic.vue'
import PreviewUnknown from './PreviewUnknown.vue'
import PreviewTooBig from './PreviewTooBig.vue'
import PreviewPDF from './PreviewPDF.vue'
import PreviewDatDoc from './PreviewDatDoc.vue'
import { FileEntity } from '@pacprotocol/yanui'
import mimeType from 'mime-types'

class Props {
    file: FileEntity = prop({
        required: true,
    })
    shareCode = prop({
        type: String,
        default: '',
        required: false,
    })
}

@Options({
    components: {
        PreviewImage,
        PreviewVideo,
        PreviewMusic,
        PreviewUnknown,
        PreviewTooBig,
        PreviewPDF,
        PreviewDatDoc
    },
})
export default class Preview extends Vue.with(Props) {
    private type: string = ''
    private sub_type: string = ''
    private extension: string = ''

    private previews = [
        {
            condition: () => this.extension === 'datdoc' || this.type === 'text' || this.extension === 'xml',
            component: 'PreviewDatDoc',
            spacing: false,
        },
        {
            condition: () => this.extension === 'pdf',
            component: 'PreviewPDF',
            spacing: false,
        },
        {
            condition: () => this.type === 'video' || this.type === 'audio',
            component: 'PreviewVideo',
            spacing: true,
        },
        {
            condition: () => this.type === 'image' && this.file.size > 25_000_000,
            component: 'PreviewTooBig',
            spacing: false,
        },
        {
            condition: () => this.type === 'image',
            component: 'PreviewImage',
            spacing: true,
        },
        {
            condition: () => true,
            component: 'PreviewUnknown',
            spacing: false,
        },
    ]

    get preview() {
        let preview = this.previews.find((preview) => preview.condition())
        if (!preview) {
            preview = this.previews[this.previews.length - 1]
        }
        return preview
    }

    created() {
        const contentType = mimeType.lookup(this.file.name)
        this.extension = this.file.name.split('.').pop() || ''
        if (!contentType) {
            this.type = ''
            this.sub_type = ''
            return
        }
        const types = contentType.split('/')
        this.type = types[0]
        this.sub_type = types[1]
    }

    get file_link() {
        //if (this.file.preview_id) {
        //    return this.file.preview_id
        //}
        return (this.$i ? this.$i : this).$utils.file_link(this.file)
    }

    get file_download() {
        return () => {
            ;(this.$i ? this.$i : this).$utils.file_download(this.file)
        }
    }
}
