
import { FileEntityUpload } from '@pacprotocol/yanui'
import { Options, Vue, prop } from 'vue-class-component'

class Props {
    file: FileEntityUpload = prop({
        required: true,
    })
    fileLink: string = prop({
        type: String,
        required: true,
    })
    fileDownload: string = prop({
        required: true,
    })
}

@Options({
    components: {},
})
export default class PreviewImage extends Vue.with(Props) {}
