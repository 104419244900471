
import { Options, Vue, prop } from 'vue-class-component'
import FileIcon from '@/components/FileIcon.vue'
import { FileEntity } from '@pacprotocol/yanui'

import { YButton } from '@pacprotocol/yanui'

class Props {
    file: FileEntity = prop({
        required: true,
    })
    fileLink: string = prop({
        type: String,
        required: true,
    })
    fileDownload: string = prop({
        required: true,
    })
}

@Options({
    components: {
        YButton,
        FileIcon,
    },
})
export default class PreviewImage extends Vue.with(Props) {}
