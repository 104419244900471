
import { Options, Vue, prop } from 'vue-class-component'

import { FileEntityUpload } from '@pacprotocol/yanui'

import { YButton, YDropdown, YDropdownItem } from '@pacprotocol/yanui'
import { file } from '@babel/types'
import { type } from 'os'

class Props {
    file: FileEntityUpload = prop({
        required: true,
    })
    type = prop({
        type: String,
        required: true,
    })
    square = prop({
        type: Boolean,
        default: true,
    })
}

@Options({
    components: {
        YDropdown,
        YDropdownItem,
        YButton,
    },
})
export default class FileDropdown extends Vue.with(Props) {
    mounted() {
        //this.$watch('file.name', () => {
        //    this.file_names_updated()
        //})
    }

    private file_remove() {
        console.log('Trigger?')
        if (this.file._upload && !!this.file._upload.uploading) {
            return
        }
        this.$emit('file-remove', this.file, this.$el)
    }
}
