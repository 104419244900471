import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "simple-page-layout" }
const _hoisted_2 = { class: "simple-page-banner" }
const _hoisted_3 = { class: "simple-page-banner-button" }
const _hoisted_4 = { class: "simple-page-slot" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_y_button = _resolveComponent("y-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_y_button, {
          onClick: _ctx.back,
          icon: "chevron-left",
          type: "is-ghost-white",
          spacing: true
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$yanui.utils.get_back_router_name(_ctx.$router)), 1)
          ]),
          _: 1
        }, 8, ["onClick"])
      ])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _renderSlot(_ctx.$slots, "default")
    ])
  ]))
}