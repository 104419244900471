import { library } from '@fortawesome/fontawesome-svg-core'
import {
    faHomeAlt,
    faFolder,
    faImages,
    faUsers,
    faStar,
    faClock,
    faTrash,
    faThumbtack,
    faBallPile,
    faSearch,
    faTimes,
    faTimesCircle,
    faSlidersH,
    faEye,
    faEyeSlash,
    faBell,
    faMoon,
    faSun,
    faQuestionCircle,
    faEllipsisH,
    faCheckSquare,
    faTimesSquare,
    faLock,
    faSpinnerThird,
    faCode,
    faExclamationCircle,
    faExclamationTriangle,
    faCheckCircle,
    faLongArrowUp,
    faRedoAlt,
    faAngleLeft,
    faAngleRight,
    faAngleDown,
    faCloudUpload,
    faFolderUpload,
    faFileUpload,
    faPlus,
    faBracketsCurly,
    faList,
    faTh,
    faThLarge,
    faShareAlt,
    faCloudDownload,
    faICursor,
    faTrashAlt,
    faSquare,
    faFile,
    faFileWord,
    faFileVideo,
    faFilePowerpoint,
    faFilePdf,
    faFileMusic,
    faFileImage,
    faFileExcel,
    faFileCode,
    faFileAlt,
    faFileArchive,
    faCog,
    faCalendarAlt,
    faChevronLeft,
    faChevronRight,
    faFrownOpen,
    faChevronDown,
    faEnvelope,
    faSignIn,
    faUserPlus,
    faDoorOpen,
    faSave,
    faUser,
    faUpload,
    faCopy,
    faLink,
    faCloud,
    faLightbulbOn,
    faCalendar,
    faBug,
    faLightbulb,
    faInfoCircle,
    faCreditCard,
    faAsterisk,
    faCamera,
    faVideo,
    faImage,
    faHistory,
    faLineColumns,
    faQuestionSquare,
    faMusic,
    faFileEdit,
} from '@fortawesome/pro-light-svg-icons'

import { faPlayCircle } from '@fortawesome/pro-duotone-svg-icons'

import { faSquare as faSquareSolid } from '@fortawesome/pro-solid-svg-icons'

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

library.add(
    //@ts-ignore
    faFileEdit,
    faMusic,
    faQuestionSquare,
    faCamera,
    faLineColumns,
    faHistory,
    faImage,
    faVideo,
    faCreditCard,
    faAsterisk,
    faHomeAlt,
    faCalendar,
    faFolder,
    faImages,
    faUsers,
    faUser,
    faStar,
    faClock,
    faTrash,
    faThumbtack,
    faBallPile,
    faSearch,
    faTimes,
    faTimesCircle,
    faSlidersH,
    faEye,
    faEyeSlash,
    faBell,
    faMoon,
    faSun,
    faQuestionCircle,
    faEllipsisH,
    faCheckSquare,
    faTimesSquare,
    faLock,
    faSpinnerThird,
    faCode,
    faPlayCircle,
    faChevronLeft,
    faChevronRight,
    faExclamationCircle,
    faExclamationTriangle,
    faCheckCircle,
    faLongArrowUp,
    faRedoAlt,
    faAngleLeft,
    faAngleRight,
    faAngleDown,
    faCloudUpload,
    faFolderUpload,
    faFileUpload,
    faPlus,
    faBracketsCurly,
    faList,
    faTh,
    faThLarge,
    faShareAlt,
    faCloudDownload,
    faICursor,
    faTrashAlt,
    faSquare,
    faUpload,
    faSquareSolid,
    faFile,
    faFileWord,
    faFileVideo,
    faFilePowerpoint,
    faFilePdf,
    faFileMusic,
    faFileImage,
    faFileExcel,
    faFileCode,
    faFileAlt,
    faFileArchive,
    faCog,
    faCalendarAlt,
    faFrownOpen,
    faChevronDown,
    faEnvelope,
    faSignIn,
    faUserPlus,
    faDoorOpen,
    faSave,
    faCopy,
    faLink,
    faCloud,
    faBug,
    faLightbulb,
    faInfoCircle,
)

export default FontAwesomeIcon
