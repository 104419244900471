
import { StripeElements, StripeElement } from 'vue-stripe-js'
import { Options, Vue, prop } from 'vue-class-component'
import { loadStripe } from '@stripe/stripe-js'
import { YInputText, YButton } from '@pacprotocol/yanui'
import { ref } from 'vue'
import PlanRaw from './PlanRaw.vue'

class Props {
    plan: any = prop({
        required: true,
    })
    recurring = prop({
        required: false,
        default: 'month',
    })
}

@Options({
    components: {
        YInputText,
        YButton,
        StripeElements,
        StripeElement,
        PlanRaw,
    },
})
export default class StripePayment extends Vue.with(Props) {
    private stripeLoaded = false
    private stripeKey = process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY
    private instance_options = {}
    private card_options = ref({
        style: {
            base: {
                fontWeight: '500',
                fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
                fontSize: '16px',
                fontSmoothing: 'antialiased',
            },
            invalid: {},
        },
    })
    private elements_options = {
        appearance: {
            theme: 'flat',
        },
    }

    private cardholder_name: string = ''
    private card_number: string = ''
    private card_exp: string = ''
    private card_cvc: string = ''
    private readonly: boolean = false

    private payment_loading: boolean = false
    private payment_error: string = ''

    mounted() {
        if (this.stripeKey) {
            const stripe_promise = loadStripe(this.stripeKey)
            stripe_promise.then(() => {
                this.stripeLoaded = true
            })
        }
    }

    pay() {
        if (this.payment_loading) return
        this.payment_loading = false
        // @ts-ignore
        const cardElement = this.$refs.card.stripeElement

        //@ts-ignore
        this.$refs.elms.value.instance
            .createToken(cardElement)
            .then((result: object) => {
                // Handle result.error or result.token
                console.log(result)
            })
            .catch((e: any) => {
                this.payment_loading = false
                this.payment_error = e.message
            })
    }

    get price() {
        return this.plan.prices[this.recurring].amount / 100
    }
    get currency() {
        return this.plan.prices[this.recurring].currency.toUpperCase()
    }
}
