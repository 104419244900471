import { State, reactive, ref, Ref } from 'vue'
import { RouteLocationNormalizedLoaded } from 'vue-router'
import { createStore, Store } from 'vuex'
import { BreadcrumbItem, FileEntity } from '@pacprotocol/yanui'

declare module '@vue/runtime-core' {
    // declare your own store states
    interface State {
        modal: any
        redirect: RouteLocationNormalizedLoaded | null
        search: any
        is_file_dragging: boolean
        current_folder: BreadcrumbItem | FileEntity | null
        files: FileEntity[]
        upload_warn_close: boolean
        frontend_url: string
        backend_url: string
        user: any
        user_changes: number
        stripe_loaded: boolean;
        editor_file: FileEntity | null;
        editor_content: string;
        editor_filename: string;
        editor_folder_id: string;
    }

    // provide typings for `this.$store`
    interface ComponentCustomProperties {
        $store: Store<State>
    }
}

const store = createStore({
    state() {
        return {
            frontend_url:
                process.env.VUE_APP_DATOSDRIVE_FRONTEND_URL ||
                'https://localhost:3500',
            backend_url:
                process.env.VUE_APP_DATOSDRIVE_BACKEND_URL ||
                'https://localhost:3000',
            version: process.env.PACKAGE_VERSION || '0',
            redirect: null,
            is_file_dragging: false,
            current_folder: null,
            files: [],
            search: {
                text: '',
            },
            modal: {
                upload: false,
            },
            upload_warn_close: false,
            user: {},
            user_changes: 0,
            stripe_loaded: false,
            editor_file: null,
            editor_content: "",
            editor_filename: "",
            editor_folder_id: "",
        }
    },
    mutations: {
        user(state: State, user: any) {
            state.user = user
            state.user_changes++;
        },
    },
})

export default store
