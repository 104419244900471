
import { FileEntity, FileEntityUpload } from '@pacprotocol/yanui'
import { Options, Vue, prop } from 'vue-class-component'

import { YVideoPlayer } from '@pacprotocol/yanui'

import mimeTypes from 'mime-types'

class Props {
    file: FileEntityUpload = prop({
        required: true,
    })
    fileLink: string = prop({
        type: String,
        required: true,
    })
    fileDownload: string = prop({
        required: true,
    })
}

@Options({
    components: {
        YVideoPlayer,
    },
})
export default class PreviewVideo extends Vue.with(Props) {
    private el: HTMLElement | null = null
    private mimeType: string = ''
    created() {
        this.mimeType = mimeTypes.lookup(this.file.name) || 'video/mp4'
    }
    mounted() {
        this.el = this.$el
    }

    get thumbnail() {
        if (this.file._upload && this.file._upload.thumbnail_object_url) {
            return this.file._upload.thumbnail_object_url
        }
        if (this.file.thumbnail_id) {
            return (this.$i ? this.$i : this).$yandna.thumbnail_url(
                this.file.thumbnail_id,
            )
        }
        return null
    }

    get width() {
        if (this.file.meta && this.file.meta.width) {
            return this.file.meta.width
        }
        return 0
    }
    get height() {
        if (this.file.meta && this.file.meta.height) {
            return this.file.meta.height
        }
        return 0
    }

    get options() {
        const opt: any = {}

        if (this.mimeType.startsWith('audio')) {
            //opt.plugins = {
            //    fluid: false,
            //    wavesurfer: {
            //        backend: 'MediaElement',
            //        displayMilliseconds: false,
            //        waveColor: '#999',
            //        progressColor: '#FFFB00',
            //        cursorColor: '#FF0000',
            //        backgroundColor: '#F8DADA',
            //        hideScrollbar: true,
            //        interact: true,
            //        debug: true,
            //    }
            //}
        }

        return opt
    }
}
