import { FileEntityUpload } from './../../.yalc/@pacprotocol/yanui/dist/types/type/types.d'
import {
    DatosCrypto,
    FileEntity,
    FileType,
    Utils,
    YInputText,
} from '@pacprotocol/yanui'
import { nanoid, customRandom } from 'nanoid'

import { Ref, ref } from 'vue'
import { Vue } from 'vue-class-component'
import mimeTypes from 'mime-types'
import RemoteDBSchema from '@/schemas/database/index'
import {
    RxDBReplicationP2PPlugin,
    RxP2PReplicationPool,
} from 'rxdb/plugins/replication-p2p'
import { getConnectionHandlerDatosDriveP2P } from './helper/p2p-replication'
import { RxReplicationPullStreamItem } from 'rxdb'
import { Subject } from 'rxjs'
import * as clipboardy from 'clipboardy'
import { Capacitor } from '@capacitor/core'
import { Browser } from '@capacitor/browser'
import { StatusBar, Style } from '@capacitor/status-bar'
import { SplashScreen } from '@capacitor/splash-screen'

export default class DatosUtils {
    private session_id = ''
    private vueThis: any
    private access_token: string = ''

    constructor(app: any) {
        this.vueThis = app.config.globalProperties
    }

    get i(): Vue {
        return this.vueThis.$i ? this.vueThis.$i : this.vueThis
    }

    public async user_init() {
        //const searchParams = new URLSearchParams(window.location.search)
        //if (searchParams.has('session_id')) {
        //    const session_id = searchParams.get('session_id')
        //    if (document && session_id) {
        //        this.session_id = session_id
        //        document
        //            ?.getElementById('session-id')
        //            ?.setAttribute('value', session_id)
        //    }
        //}

        console.log(this.i.$route)
        const message = this.i.$route.query.message
        const type = 'is-' + (this.i.$route.query.type || 'success')
        console.log('ROUTER MESSAGE', message, type)
        if (message) {
            this.i.$yanui.toast.show(message, { type })
            delete this.i.$route.query.message
            delete this.i.$route.query.type
        }

        this.i.$store.state.user = await this.get_profile()
        if (!this.i.$auth0?.user?.value.sub) {
            throw new Error('AUTH0 - User not logged in')
        }

        let masterkey
        await this.i.$masterkey.initialize(
            this.i.$auth0.user.value.sub.split('|')[1],
        )

        if (this.i.$store.state.user.masterkey_checksum_new_required) {
            masterkey = await this.i.$masterkey.generate_masterkey()
            await this.i.$masterkey.store_masterkey(masterkey)
        } else {
            masterkey = await this.i.$masterkey.stored_masterkey()
            if (!masterkey) {
                //Ask user to import seed phrase
                await SplashScreen.hide()
                masterkey = await this.i.$masterkey.import_master_key({})
                await this.i.$masterkey.store_masterkey(masterkey)
            }
        }
        await this.i.$masterkey.import(masterkey)

        const masterkey_status = await this.initialize_user({
            masterkey_checksum: this.i.$masterkey.masterkey_checksum,
        })

        if (masterkey_status.success) {
            if (this.i.$store.state.user.masterkey_checksum_new_required) {
                await SplashScreen.hide()
                await this.i.$masterkey.show_master_key()
            }
            delete this.i.$store.state.user.masterkey_checksum_new_required
            console.log('MASTER KEY IS A SUCCESS')
        } else {
            if (masterkey_status.error_type) {
                switch (masterkey_status.error_type) {
                    case 'masterkey_checksum_required':
                        //Master Key Checksum / Initialization is required
                        break
                    case 'masterkey_checksum_invalid':
                        //Invalid Master Key checksum provided
                        break
                    case 'masterkey_checksum_incorrect':
                        //Master Key is incorrect / mismatch
                        break
                    default:
                        throw new Error('Unknown error')
                }
                this.i.$yanui.toast.show(masterkey_status.error, {
                    type: 'is-danger',
                })
                await SplashScreen.hide()
                masterkey = await this.i.$masterkey.import_master_key({})
                await this.i.$masterkey.store_masterkey(masterkey)
            }
        }
        //let url_query = "/db-replication";
        //const access_token = await this.get_access_token()
        //if (access_token) {
        //    url_query += `?authorization=${access_token}`
        //}
        //const ws = new WebSocket(`${process.env.VUE_APP_DATOSDRIVE_WEBSOCKET_URL}${url_query}`)
        //this.i.$yanui.db.files.$.subscribe((change: any) => {
        //    ws.send(JSON.stringify(change));
        //})
        await SplashScreen.hide()
    }

    public async prompt_input(
        title: string,
        description: string = '',
        default_value: string = '',
    ): Promise<string> {
        return new Promise((resolve, reject) => {
            const val = ref(default_value)
            this.i.$yanui.dialog.alert({
                title: `${title}`,
                type: 'is-success',
                icon: 'folder',
                components: [
                    {
                        component: YInputText,
                        props: {
                            title: `${description}`,
                            icon: 'file',
                            modelValue: () => {
                                return val.value
                            },
                        },
                        events: {
                            'update:modelValue': (value: string) => {
                                val.value = value
                            },
                        },
                    },
                ],
                buttons: [
                    {
                        text: 'Cancel',
                        type: 'is-ghost',
                        action: (modal: any) => {
                            modal.close()
                        },
                    },
                    {
                        text: 'OK',
                        type: 'is-success',
                        action: async (modal: any) => {
                            resolve(val.value)
                            modal.close()
                        },
                    },
                ],
            })
        })
    }

    public async file_share_window(file: FileEntity) {
        const share_link = (await this.file_share_link(file)) as string

        const shareData = {
            title: `Sharing "${file.name}" from DatosDrive`,
            text: `Click on the link to view "${file.name}" from DatosDrive.\n\n${share_link}`,
        }

        // @ts-ignore
        if (navigator.canShare) {
            if (navigator.canShare(shareData)) {
                try {
                    await navigator.share(shareData)
                } catch (e) {
                    return
                }
                this.i.$yanui.toast.show('File shared successfully!', {
                    type: 'is-success',
                })
            } else {
                throw new Error('Cannot share file')
            }
        } else {
            //Fallback to copy link
            this.i.$plausible.trackEvent('File Share Link Created')
            this.i.$plausible.trackEvent('File Action', {
                props: {
                    action: 'Share Link created',
                },
            })
            const extension = file.name.split('.').pop()
            const file_name_short =
                file.name.length > 26
                    ? file.name.substring(0, 26) + '...' + extension
                    : file.name
            this.i.$yanui.dialog.alert({
                title: `Shareable Link for "${file_name_short}"`,
                type: 'is-success',
                icon: 'link',
                message: `Here is your shareable link for "${file_name_short}"!\n\nAnyone with this link can decrypt, download and view the file.`,
                components: [
                    {
                        component: YInputText,
                        props: {
                            title: `Shareable Link`,
                            icon: 'link',
                            modelValue: share_link,
                            readonly: true,
                        },
                    },
                ],
                buttons: [
                    {
                        text: 'Close',
                        type: 'is-ghost',
                        action: (modal: any) => {
                            modal.close()
                        },
                    },
                    {
                        text: 'Copy Link',
                        type: 'is-success',
                        icon: 'copy',
                        action: async (modal: any) => {
                            try {
                                await clipboardy.write(share_link)
                                this.i.$yanui.toast.show(
                                    `Shareable link copied for\n${file_name_short}`,
                                    {
                                        type: 'is-success',
                                    },
                                )
                            } catch (e) {
                                console.error(e)
                                this.i.$yanui.toast.show(
                                    `Failed to copy shareable link!`,
                                    {
                                        type: 'is-danger',
                                    },
                                )
                            }
                        },
                    },
                ],
            })
        }
    }

    public async file_rename_window(file: FileEntity): Promise<string> {
        return new Promise((resolve, reject) => {
            let file_extension: Ref<string>
            let file_name: Ref<string>

            if (file.name.includes('.')) {
                const name_split = file.name.split('.')
                file_extension = ref(name_split.pop() as string)
                file_name = ref(name_split.join('.'))
            } else {
                file_extension = ref('')
                file_name = ref(file.name)
            }

            this.i.$yanui.dialog.alert({
                title: `Rename "${file.name}"`,
                type: 'is-success',
                className: 'dialog-rename-file',
                components: [
                    {
                        component: YInputText,
                        props: {
                            icon: 'file',
                            title: `Rename the file name`,
                            modelValue: () => {
                                return file_name.value
                            },
                        },
                        events: {
                            'update:modelValue': (value: string) => {
                                file_name.value = value
                            },
                        },
                    },
                    {
                        component: YInputText,
                        props: {
                            icon: 'asterisk',
                            title: `Rename the file extension`,
                            modelValue: () => {
                                return file_extension.value
                            },
                        },
                        events: {
                            'update:modelValue': (value: string) => {
                                file_extension.value = value
                            },
                        },
                    },
                ],
                buttons: [
                    {
                        text: 'Cancel',
                        type: 'is-ghost',
                        action: (modal: any) => {
                            modal.close()
                        },
                    },
                    {
                        text: 'Rename File',
                        type: 'is-success',
                        action: async (modal: any) => {
                            const _extension =
                                file_extension.value[0] === '.'
                                    ? file_extension.value.substring(1)
                                    : file_extension.value
                            const _file_name =
                                file_name.value +
                                (file_extension.value ? '.' + _extension : '')
                            const check_extension = (): Promise<void> => {
                                return new Promise((resolve, reject) => {
                                    if (file.type === FileType.FOLDER) {
                                        return resolve()
                                    }
                                    const previously_extension = file.name
                                        .split('.')
                                        .pop()
                                    const current_extension = _file_name
                                        .split('.')
                                        .pop()

                                    if (
                                        previously_extension !==
                                        current_extension
                                    ) {
                                        this.i.$yanui.dialog.alert({
                                            title: 'Rename File',
                                            message: `Are you sure you want to rename extension ".${previously_extension}" to ".${current_extension}" as well?`,
                                            type: 'is-warning',
                                            icon: 'exclamation-triangle',
                                            buttons: [
                                                {
                                                    text: 'Cancel',
                                                    type: 'is-ghost',
                                                    action: (modal: any) => {
                                                        modal.close()
                                                        return reject()
                                                    },
                                                },
                                                {
                                                    text: 'Rename it',
                                                    type: 'is-warning',
                                                    action: (modal: any) => {
                                                        modal.close()
                                                        return resolve()
                                                    },
                                                },
                                            ],
                                        })
                                    } else {
                                        return resolve()
                                    }
                                })
                            }

                            check_extension()
                                .then(async () => {
                                    try {
                                        await this.file_rename(file, _file_name)
                                        this.i.$plausible.trackEvent(
                                            'File Action',
                                            {
                                                props: {
                                                    type: file.type.toString(),
                                                    action: 'renamed',
                                                },
                                            },
                                        )
                                        modal.close()
                                        resolve(_file_name)
                                    } catch (e: any) {
                                        console.log(e)
                                        this.i.$yanui.toast.show(e.message, {
                                            type: 'is-danger',
                                        })
                                        reject()
                                    }
                                })
                                .catch(() => {
                                    reject()
                                    //
                                })
                        },
                    },
                ],
            })
        })
    }

    public async file_delete_window(file: FileEntity): Promise<void> {
        return new Promise((resolve, reject) => {
            let message = `Do you want to delete "${file.name}"?`
            if (file.type === FileType.FOLDER) {
                message = `Do you want to delete "${file.name}" and all file(s)/folder(s) inside?`
            }
            const loading = ref(false)
            this.i.$yanui.dialog.alert({
                title: `Delete "${file.name}"?`,
                message,
                icon: 'trash-alt',
                type: 'is-danger',
                buttons: [
                    {
                        text: 'No',
                        type: 'is-ghost',
                        action: (modal: any) => {
                            modal.close()
                        },
                    },
                    {
                        text: 'Yes',
                        type: 'is-danger',
                        loading: () => loading,
                        action: async (modal: any) => {
                            loading.value = true
                            try {
                                const deleted = await this.file_delete(file)
                                if (deleted) {
                                    this.i.$plausible.trackEvent(
                                        'File Action',
                                        {
                                            props: {
                                                type: file.type.toString(),
                                                action: 'removed',
                                            },
                                        },
                                    )
                                    modal.close()
                                    resolve()
                                } else {
                                    throw new Error("File couldn't be deleted")
                                    reject()
                                }
                            } catch (e: any) {
                                this.i.$yanui.dialog.alert({
                                    title: `Delete ${file.type} error!`,
                                    message:
                                        Utils.capitalize_first_letter_sentence(
                                            e.message.split('\n')[0],
                                        ),
                                    type: 'is-danger',
                                    icon: 'exclamation-circle',
                                })
                                reject()
                            }
                        },
                    },
                ],
            })
        })
    }

    public async folder_create_window(
        current_folder: FileEntity | null = null,
    ) {
        const folder_name = ref('New Folder')

        if (current_folder) {
            if (current_folder.type !== FileType.FOLDER) {
                return this.i.$yanui.toast.show(
                    "You can't create folder here",
                    { type: 'is-danger' },
                )
            }
        }

        this.i.$yanui.dialog.alert({
            title: 'Create Folder',
            type: 'is-success',
            message: `It will create new folder at "${
                current_folder ? current_folder.name : 'Home'
            }"`,
            components: [
                {
                    component: YInputText,
                    props: {
                        title: 'Folder Name',
                        icon: 'folder',
                        placeholder: 'Folder Name',
                        modelValue: () => {
                            return folder_name.value
                        },
                    },
                    events: {
                        'update:modelValue': (value: string) => {
                            folder_name.value = value
                        },
                    },
                },
            ],
            buttons: [
                {
                    text: 'Cancel',
                    type: 'is-ghost',
                    action: (modal: any) => {
                        console.log('ACTION', modal)
                        modal.close()
                    },
                },
                {
                    text: 'Create Folder',
                    type: 'is-success',
                    action: async (modal: any) => {
                        if (folder_name.value.trim() === '') {
                            this.i.$yanui.toast.show(
                                'Folder name cannot be empty!',
                                {
                                    type: 'is-danger',
                                },
                            )
                            return
                        }
                        try {
                            let parent_id = ''
                            if (current_folder) {
                                parent_id = current_folder.id
                            }
                            const res = await this.folder_create(
                                folder_name.value,
                                parent_id,
                            )
                            this.i.$plausible.trackEvent('Folder Created')
                            this.i.$store.state.files = [
                                res,
                                ...this.i.$store.state.files,
                            ]
                            modal.close()
                        } catch (e: any) {
                            console.error('[ERROR] Create Folder', e)
                            this.i.$yanui.toast.show(
                                Utils.capitalize_first_letter_sentence(
                                    typeof e.message == 'string'
                                        ? e.message
                                        : e.message[0],
                                ),
                                { type: 'is-danger' },
                            )
                        }
                    },
                },
            ],
        })
    }

    public async editor_docs_open(file: FileEntity) {
        const extension = file.name.split('.').pop()

        switch (extension) {
            case 'datdoc':
                this.i.$router.push({
                    name: 'DatosDocs',
                    hash: '#' + file.id_sha256,
                })
                break;
            case 'pdf':
                this.i.$router.push({
                    name: 'PDFEditor',
                    hash: '#' + file.id_sha256,
                })
                break;
        }
    }

    public file_generate_id() {
        return nanoid(21)
    }
    public file_generate_secret() {
        return nanoid(32)
    }
    public async sha256(message: string) {
        return this.sha256_raw(
            message + this.i.$auth0.user?.value?.sub?.split('|')[1],
        )
    }

    public async sha256_raw(message: string) {
        const encoder = new TextEncoder()
        const data = encoder.encode(message)
        const hash = await crypto.subtle.digest('SHA-256', data)
        const hashArray = Array.from(new Uint8Array(hash))
        const hashHex = hashArray
            .map((b) => b.toString(16).padStart(2, '0'))
            .join('')
        return hashHex
    }

    public async sha256_multi(message: string, rounds: number) {
        let hash = await this.sha256(message)
        for (let i = 0; i < rounds; i++) {
            hash = await this.sha256(hash.toString())
        }
        return hash
    }

    public async folders_by_path(path: string | string[]) {
        if (typeof path === 'string') {
            path = path.split('/')
        }
        const folders: any = []
        let in_folder = ''
        if (path === undefined) return folders
        await this.i.$yanui.db_ready()
        for (let i = 0; i < path.length; i++) {
            const name = path[i]
            const folder = await this.i.$yanui.db.files
                .findOne({
                    selector: {
                        name,
                        in_folder,
                        type: FileType.FOLDER,
                    },
                })
                .exec()
            if (!folder) {
                break
            }
            folders.push(folder)
            in_folder = folder.id
        }
        return folders
    }

    public async folder_create(name: string, parent_id: string = '') {
        let folder_parent = null
        if (parent_id) {
            folder_parent = (await this.i.$yanui.db.files
                .findOne({ selector: { id: parent_id } })
                .exec()) as FileEntity | null

            if (!folder_parent) {
                throw new Error('Parent folder not found!')
            }
            if (folder_parent.type !== FileType.FOLDER) {
                throw new Error('Parent is not a folder!')
            }
        }
        name = await this.file_name_duplicate_rename(name, parent_id)

        const res = await this.i.$yanui.db.files.insert({
            id: this.file_generate_id(),
            name,
            size: 0,
            in_folder: parent_id,
            created_at: new Date().getTime(),
            updated_at: new Date().getTime(),
            type: FileType.FOLDER,
        })
        this.database_upload_trigger()
        return res
    }

    public async folder_update_stats(folder_id: string | undefined) {
        if (!folder_id) {
            return
        }

        let current_folder_id = folder_id
        let current_folder = null

        const folder_chain = []

        do {
            current_folder = await this.i.$yanui.db.files
                .findOne({
                    selector: { id: current_folder_id },
                })
                .exec()
            if (current_folder) {
                folder_chain.push(current_folder)
                current_folder_id = current_folder.in_folder
            } else {
                break
            }
        } while (current_folder.in_folder)

        for (let i = 0; i < folder_chain.length; i++) {
            const folder = folder_chain[i]
            const files = await this.i.$yanui.db.files
                .find({
                    selector: {
                        in_folder: folder.id,
                    },
                })
                .exec()
            let size = 0
            for (let j = 0; j < files.length; j++) {
                const file = files[j]
                size += file.size
            }
            console.log('FOLDER BEFORE', folder)
            await folder.atomicPatch({
                size,
                updated_at: new Date().getTime(),
                type: FileType.FOLDER,
            })
            console.log('FOLDER AFTER', folder)
        }
    }

    public async file_name_duplicate_rename(
        name: string,
        parent_id: string = '',
    ) {
        let same_name_file
        let file_name_count = 0
        let file_name = name
        const file_extension = this.i.$yanui.file_utils.fileExtension(name)
        if (file_extension) {
            //Replace extension but only the last chars
            file_name = name.replace(new RegExp(`.${file_extension}$`), '')
        }
        do {
            same_name_file = await this.i.$yanui.db.files
                .findOne({ selector: { name, in_folder: parent_id } })
                .exec()
            if (same_name_file) {
                if (file_extension) {
                    name =
                        file_name + ` (${++file_name_count}).${file_extension}`
                } else {
                    name = file_name + ` (${++file_name_count})`
                }
            } else {
                break
            }
        } while (same_name_file)
        return name
    }

    public async file_share_link(file: FileEntity) {
        if (!file.secret) return
        const user_id_hex = this.i.$auth0.user?.value?.sub?.split('|')[1]
        if (!user_id_hex) return
        const user_id_sha256 = await this.sha256_raw(user_id_hex)
        const user_id_sha256_base64 = Buffer.from(
            user_id_sha256,
            'hex',
        ).toString('base64')
        const user_id_sha256_base64_urlencoded = encodeURIComponent(
            user_id_sha256_base64,
        )

        const file_id = file.id_sha256 as string
        const file_id_base64 = Buffer.from(file_id, 'hex').toString('base64')
        const file_id_base64_urlencoded = encodeURIComponent(file_id_base64)
        const password = await this.i.$masterkey.derived_password_file_hex(
            file.secret,
        )
        const password_base64 = Buffer.from(password, 'hex').toString('base64')

        const file_name = file.name
        const file_name_base64 = Buffer.from(file_name, 'utf8').toString(
            'base64',
        )

        const hash = [password_base64, file_name_base64].join(',')

        const hash_urlencoded = encodeURIComponent(hash)

        const share_url = `${process.env.VUE_APP_DATOSDRIVE_FRONTEND_URL}/share/${user_id_sha256_base64_urlencoded}/${file_id_base64_urlencoded}#${hash_urlencoded}`
        return share_url
    }

    public async file_thumbnail_upload(
        file: FileEntityUpload,
        password: string,
    ) {
        try {
            console.log('Added File', file?._upload?.thumbnail_blob)
            if (file?._upload?.thumbnail_blob) {
                const thumbnail_id = this.i.$utils.file_generate_id()
                const thumbnail_id_sha256 = await this.i.$utils.sha256(
                    thumbnail_id,
                )
                await this.i.$utils.file_upload(
                    file._upload.thumbnail_blob,
                    password + '-thumbnail',
                    {
                        id: thumbnail_id_sha256,
                    },
                    {
                        on_upload_progress: (progress: number) => {
                            if (file._upload) {
                                file._upload.progress = progress //TODO: Add thumbnail progress
                            }
                        },
                    },
                )
                file.thumbnail_id = thumbnail_id
                file.thumbnail_id_sha256 = thumbnail_id_sha256
            }
        } catch (e) {
            console.error('THUMBNAIL UPLOAD ERROR', e)
        }
    }

    public async file_preview_upload(file: FileEntityUpload, password: string) {
        try {
            if (file?._upload?.preview_blob) {
                const preview_id = this.i.$utils.file_generate_id()
                const preview_id_sha256 = await this.i.$utils.sha256(preview_id)
                await this.i.$utils.file_upload(
                    file._upload.preview_blob,
                    password + '-preview',
                    {
                        id: preview_id_sha256,
                    },
                    {
                        on_upload_progress: (progress: number) => {
                            if (file._upload) {
                                file._upload.progress = progress //TODO: Add thumbnail progress
                            }
                        },
                    },
                )
                file.preview_id = preview_id
                file.preview_id_sha256 = preview_id_sha256
            }
        } catch (e) {
            console.error('PREVIEW UPLOAD ERROR', e)
        }
    }

    public async file_upload(
        file: File | Blob,
        password: string = '',
        meta: any = {},
        events: {
            on_id_received?: (id: string) => void
            on_upload_progress?: (
                progress: number,
                bytes_uploaded: number,
                bytes_loaded: number,
            ) => void
            on_upload_error?: (error: any) => void
        } = {},
        options: {
            signal?: AbortSignal
        } = {},
    ) {
        try {
            const access_token = await this.get_access_token()
            await this.i.$yanui.file_server.file_upload(
                file,
                password,
                access_token,
                meta,
                {
                    on_id_received: events.on_id_received
                        ? events.on_id_received
                        : undefined,
                    on_upload_progress: events.on_upload_progress
                        ? events.on_upload_progress
                        : undefined,
                    on_upload_error: events.on_upload_error
                        ? events.on_upload_error
                        : undefined,
                },
                options,
            )
            if (file.size) {
                console.log('UPDATING UPLOAD STAS.....')
                //Check if document exists, if not create new one. If it does, do $inc
                const query = this.i.$yanui.db.storage_quota_history.findOne({
                    selector: { date: this.date_timestamp_today().toString() },
                })
                const doc = await query.exec()
                if (!doc) {
                    const previously_doc =
                        await this.i.$yanui.db.storage_quota_history
                            .findOne({
                                selector: {},
                                sort: [{ date: 'desc' }],
                            })
                            .exec()
                    let size = 0
                    if (previously_doc) {
                        size = Math.max(previously_doc.quota - file.size, 0)
                    }
                    await this.i.$yanui.db.storage_quota_history.insert({
                        date: this.date_timestamp_today().toString(),
                        quota: size + file.size,
                    })
                } else {
                    await query.update({ $inc: { quota: file.size } })
                }
                this.database_upload_trigger()
            }
        } catch (e) {
            console.error(e)
            throw new Error("Can't upload file")
        }
    }

    public async file_public_download(file: any, user_id: string) {
        await this.i.$yanui.file_server.file_download({
            id: file.id_sha256,
            name: file.name,
            size: file.size,
            key: file.key,
            type: '',
            userid: user_id,
            public: true,
        })

        const extension: string = this.i.$yanui.file_utils.fileExtension(
            file.name,
        )
        this.i.$plausible.trackEvent('Public File Download', {
            props: {
                type: file.type.toString(),
                extension,
                file_size: this.i.$yanui.file_utils.bytes_analytics(file.size),
            },
        })
    }

    public async file_public_size(file_id: any, user_id: string) {
        const response = await fetch(
            `${this.i.$yanui.file_server.backend_url}/api/file-size/${user_id}/${file_id}`,
            {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            },
        )
        return response.status === 200 ? await response.json() : null
    }

    public async file_download(file: FileEntity) {
        if (!file.secret) return
        await this.i.$yanui.file_server.file_download({
            id: file.id_sha256,
            name: file.name,
            size: file.size,
            key: await this.i.$masterkey.derived_password_file(file.secret),
            type: '',
        })

        const extension: string = this.i.$yanui.file_utils.fileExtension(
            file.name,
        )
        this.i.$plausible.trackEvent('Private File Download', {
            props: {
                type: file.type.toString(),
                extension,
                file_size: this.i.$yanui.file_utils.bytes_analytics(file.size),
            },
        })
    }

    public async file_rename(file: FileEntity, new_name: string) {
        this.database_upload_trigger()
        const file_doc = await this.i.$yanui.db.files
            .findOne({ selector: { id: file.id } })
            .exec()
        if (!file_doc) {
            throw new Error('Rename: File not found!' + file.id)
        }
        await file_doc.atomicPatch({ name: new_name })
        return file_doc
    }

    public async file_move(
        file: FileEntity | FileEntity[],
        new_parent_id: string,
    ): Promise<boolean> {
        if (!Array.isArray(file)) {
            file = [file]
        }

        const old_parent_ids = file.map((f) => f.in_folder)

        const file_ids = file.map((f) => f.id)
        const res = await this.i.$yanui.db.files
            .find({ selector: { id: { $in: file_ids } } })
            .update({
                $set: {
                    in_folder: new_parent_id,
                },
            })

        await this.folder_update_stats(new_parent_id)
        for (const old_parent_id of old_parent_ids) {
            await this.folder_update_stats(new_parent_id)
        }
        this.database_upload_trigger()
        return !!res
    }

    public async file_delete(
        file: FileEntity | string,
        is_file: boolean = true,
    ) {
        console.log('FOLDER', file)
        if (typeof file === 'string') {
            file = {
                id: file,
                id_sha256: file,
            } as FileEntity
        }
        if (!file.id_sha256 && file.type === FileType.FILE) {
            return false
        }
        const access_token = await this.get_access_token()
        const file_doc = await this.i.$yanui.db.files
            .findOne({ selector: { id: file.id } })
            .exec()

        //If it's folder - delete all files / folder inside
        if (file_doc) {
            if (file_doc.type === FileType.FOLDER) {
                const files = await this.i.$yanui.db.files
                    .find({
                        selector: { in_folder: file_doc.id },
                    })
                    .exec()
                console.log('FOLDER FILES', files)
                const promises = []
                for (const f of files) {
                    promises.push(this.file_delete(f, true))
                }
                await Promise.all(promises)
                await file_doc.remove()
                this.database_upload_trigger(1)
                return true
            } else {
                const deleted = await this.i.$yanui.file_server.file_delete(
                    file.id_sha256 as string,
                    access_token,
                )

                if (deleted && is_file) {
                    try {
                        if (file.thumbnail_id_sha256) {
                            await this.i.$yanui.file_server.file_delete(
                                file.thumbnail_id_sha256,
                                access_token,
                            )
                        }
                        if (file.preview_id_sha256) {
                            await this.i.$yanui.file_server.file_delete(
                                file.preview_id_sha256,
                                access_token,
                            )
                        }
                    } catch (e) {
                        // ignore
                        console.error('Deleting Thumbnail / Preview Error', e)
                    }
                    await file_doc.remove()
                    await this.folder_update_stats(file_doc.in_folder)
                }
                try {
                    if (file.size && is_file) {
                        const query =
                            await this.i.$yanui.db.storage_quota_history
                                .findOne({
                                    selector: {
                                        date: this.date_timestamp_today().toString(),
                                    },
                                })
                                .exec()
                        if (!query) {
                            const previously_doc =
                                await this.i.$yanui.db.storage_quota_history
                                    .findOne({
                                        selector: {},
                                        sort: [{ date: 'desc' }],
                                    })
                                    .exec()
                            let size = 0
                            if (previously_doc) {
                                size = Math.max(
                                    previously_doc.quota - file.size,
                                    0,
                                )
                            }
                            await this.i.$yanui.db.storage_quota_history.insert(
                                {
                                    date: this.date_timestamp_today().toString(),
                                    quota: size,
                                },
                            )
                        } else {
                            await query.update({ $inc: { quota: -file.size } })
                        }
                    }
                } catch (e) {
                    console.error('Storage Quota Error', e)
                }
                this.database_upload_trigger()
                return deleted
            }
        } else {
            return false
        }
    }

    public file_link(file: FileEntity | string) {
        if (typeof file === 'string') {
            return this.i.$yanui.file_server.file_link(file)
        } else {
            return this.i.$yanui.file_server.file_link(
                file.id_sha256 ?? file.id,
            )
        }
    }

    public async file_pin(file: FileEntity) {
        this.database_upload_trigger()
        return this.i.$yanui.db.files
            .findOne({ selector: { id: file.id } })
            .update({ $set: { pin: true } })
    }
    public async file_unpin(file: FileEntity) {
        this.database_upload_trigger()
        return this.i.$yanui.db.files
            .findOne({ selector: { id: file.id } })
            .update({ $set: { pin: false } })
    }

    private database_upload_timeout: any
    private database_upload_initialized: boolean = false
    private database_upload_finished = true
    public async database_upload_trigger(delay_seconds = 1) {
        this.database_upload_finished = false
        if (this.database_upload_timeout) {
            clearTimeout(this.database_upload_timeout)
            this.database_upload_timeout = null
        }
        this.database_upload_timeout = setTimeout(() => {
            this.database_upload()
            this.database_upload_timeout = null
        }, delay_seconds * 1000)

        if (!this.database_upload_initialized) {
            this.database_upload_initialized = true
            window.addEventListener('beforeunload', async (e: any) => {
                console.log(
                    'Database Upload',
                    this.database_upload_timeout,
                    this.database_upload_finished,
                )
                if (
                    this.database_upload_timeout || // If there is a timeout, the database hasn't uploaded yet
                    !this.database_upload_finished // If database is still uploading, don't close the window
                ) {
                    e.preventDefault()
                    e.returnValue =
                        'Please wait until the database has been uploaded!'
                    if (this.database_upload_finished) {
                        ;(async () => {
                            clearTimeout(this.database_upload_timeout)
                            await this.database_upload()
                            this.database_upload_timeout = null
                        })()
                    }
                }
                return 'Please wait until the database has been uploaded!'
            })
        }
    }

    private database_iterations = 200_000
    public async database_id() {
        if (this.i.$auth0?.user?.value?.sub === undefined)
            throw new Error('Not logged in')
        const id_crypto_key = await this.i.$masterkey.derived_password_file(
            'database-id-' + this.i.$auth0.user.value.sub.split('|')[1],
            256,
            this.database_iterations,
        )
        const id_buffer = await crypto.subtle.exportKey('raw', id_crypto_key)
        const id = new TextDecoder().decode(id_buffer)
        const sha256 = await this.sha256(id)
        return sha256
    }

    public async database_upload(): Promise<boolean> {
        if (this.i.$auth0?.user?.value?.sub === undefined) return false
        this.database_upload_finished = false
        const raw_db = await this.i.$yanui.db.exportJSON()
        const blob = new Blob([JSON.stringify(raw_db)], {
            type: 'application/json',
        })
        const file = new File([blob], 'datos-local.db', {
            type: 'application/json',
            lastModified: Date.now(),
        })
        const key = await this.i.$masterkey.derived_password_file(
            'database-' + this.i.$auth0.user.value.sub.split('|')[1],
            256,
            this.database_iterations,
        )
        const access_token = await this.get_access_token()
        await this.i.$yanui.file_server.file_upload(file, key, access_token, {
            id: await this.database_id(),
            editable: true,
        })
        this.database_upload_finished = true
        return true
    }

    public database_downloaded = false
    public database_is_downloading = true
    public database_is_downloading_error = false
    public async database_download(): Promise<boolean> {
        if (this.database_downloaded) return true
        if (this.i.$auth0?.user?.value?.sub === undefined) return false
        const user_create = async () => {
            console.log('USER CREATE', this.i.$auth0.user)
            const query = this.i.$yanui.db.user.findOne()

            const user = await query.exec()
            if (!user) {
                console.log('LOCAL USER', user)
                await this.i.$yanui.db.user.insert({
                    id: this.i.$auth0.user.value.sub,
                    avatar_id: '',
                    avatar_id_sha256: '',
                })
                console.log('LOCAL USER CREATED')
            }
            console.log('user_create', 'executed')
        }

        try {
            this.database_is_downloading = true
            const id = await this.database_id()
            const link = this.file_link(id)
            const key = await this.i.$masterkey.derived_password_file(
                'database-' + this.i.$auth0.user.value.sub.split('|')[1],
                256,
                this.database_iterations,
            )
            await this.i.$yanui.file_server.file_meta_add({
                id: id,
                mimetype: 'application/json',
                name: 'datos-local.db',
                size: -1,
                key,
                type: '',
            })
            const response = await fetch(link)
            if (response.status === 200) {
                const raw_db = JSON.parse(await response.text()) as any
                await this.i.$yanui.db_initialize(RemoteDBSchema, async () => {
                    await this.i.$yanui.db.importJSON(raw_db)
                    return
                })
                await this.sync_p2p_replication('files')
                this.database_is_downloading = false
                await user_create()
                this.database_downloaded = true
                return true
            } else {
                return false
            }
        } catch (e) {
            console.error('Error downloading database COLLECTIONS', e)
            await this.i.$yanui.db_initialize(RemoteDBSchema)
            console.log('sync start')
            await this.sync_p2p_replication('files')
            console.log('sync end')
            this.database_is_downloading_error = true
            this.database_is_downloading = true
            await user_create()
            this.database_downloaded = true
            return false
        }
    }

    private sync_p2p_pool: { [collection: string]: RxP2PReplicationPool<any> } =
        {}

    public async sync_p2p_replication(collection_name: string) {
        if (this.i.$auth0?.user?.value?.sub === undefined) return false
        if (this.sync_p2p_pool[collection_name]) {
            this.sync_p2p_pool[collection_name].cancel()
            delete this.sync_p2p_pool[collection_name]
        }
        console.log('sync_p2p_replication', '1')
        const access_token = await this.get_access_token()
        console.log('sync_p2p_replication', '2')

        const topic = await this.i.$masterkey.derived_password_file_string(
            'sync-p2p-replication-' +
                this.i.$auth0.user.value.sub.split('|')[1],
        )
        console.log('sync_p2p_replication', '3')
        const secret = await this.i.$masterkey.derived_password_file_string(
            'sync-p2p-replication-secret' +
                this.i.$auth0.user.value.sub.split('|')[1],
        )
        console.log('sync_p2p_replication', '4')
        const document_encryption_key =
            await this.i.$masterkey.derived_password(
                'sync-p2p-replication-document-encryption-key' +
                    this.i.$auth0.user.value.sub.split('|')[1],
            )

        console.log('sync_p2p_replication', '5')
        const datosCrypto = new DatosCrypto(document_encryption_key)
        await datosCrypto.initialize()
        console.log('sync_p2p_replication', '6')
        const document_encryption_iv_string =
            await this.i.$masterkey.derived_password_file_string(
                'sync-p2p-replication-document-encryption-iv' +
                    this.i.$auth0.user.value.sub.split('|')[1],
            )

        console.log('sync_p2p_replication', '7')
        const document_encryption_iv = Utils.string_to_uint8array(
            document_encryption_iv_string,
        )
        console.log('sync_p2p_replication', '8')

        const pool = await this.i.$yanui.db[collection_name].syncP2P({
            topic,
            connectionHandlerCreator: getConnectionHandlerDatosDriveP2P(
                `${process.env.VUE_APP_DATOSDRIVE_WEBSOCKET_URL}/db-replication?authorization=${access_token}`,
            ),
            secret,
            pull: {
                modifier: (d) => d,
            },
            push: {
                modifier: (d) => d,
            },
        })
        console.log('sync_p2p_replication', '9')

        pool.error$.subscribe((err: any) => {
            console.error('P2P ERROR', err)
        })

        this.sync_p2p_pool[collection_name] = pool
        console.log('sync_p2p_replication', '10')
    }

    public async get_plans() {
        const response = await fetch(
            `${this.i.$yanui.file_server.backend_url}/product/plans`,
            {
                method: 'GET',
            },
        )
        return response.json()
    }
    public async masterkey_checksum_match(masterkey_checksum: string) {
        const access_token = await this.get_access_token()
        const response = await fetch(
            `${this.i.$yanui.file_server.backend_url}/user/masterkey-checksum-match`,
            {
                method: 'POST',
                headers: {
                    authorization: `Bearer ${access_token}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    masterkey_checksum,
                }),
            },
        )
        return response.status === 200
    }
    public async get_profile() {
        const access_token = await this.get_access_token()
        const response = await fetch(
            `${this.i.$yanui.file_server.backend_url}/user/profile`,
            {
                method: 'POST',
                headers: {
                    authorization: `Bearer ${access_token}`,
                },
            },
        )
        return response.json()
    }
    public async initialize_user(body: any = {}) {
        const access_token = await this.get_access_token()
        const response = await fetch(
            `${this.i.$yanui.file_server.backend_url}/user/initialize`,
            {
                method: 'POST',
                headers: {
                    authorization: `Bearer ${access_token}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(body),
            },
        )
        return response.json()
    }

    public async subscription_create_checkout_session(price_id: string) {
        const access_token = await this.get_access_token()
        const response = await fetch(
            `${this.i.$yanui.file_server.backend_url}/user/create-checkout-session`,
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    authorization: `Bearer ${access_token}`,
                },
                body: JSON.stringify({
                    price_id: price_id,
                    return_url: window.location.origin,
                }),
            },
        )
        const res = await response.json()
        window.location.replace(res.redirect)
    }

    public async subscription_create_portal_session() {
        const access_token = await this.get_access_token()
        const response = await fetch(
            `${this.i.$yanui.file_server.backend_url}/user/create-portal-session`,
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    authorization: `Bearer ${access_token}`,
                },
                body: JSON.stringify({}),
            },
        )
        const res = await response.json()
        window.location.replace(res.redirect)
    }

    public date_timestamp_today(): number {
        const today = new Date()
        today.setHours(0, 0, 0, 0)
        return today.getTime()
    }

    public async change_password_request() {
        //Popup window asking if user is sure

        const ask_user_prompt = () => {
            return new Promise((resolve, reject) => {
                this.i.$yanui.dialog.alert({
                    title: 'Change your Password?',
                    instance: this.i,
                    type: 'is-success',
                    message: `If you request for new password - you will receive a e-mail to "${this.i.$auth0.user.value.email}" in order to verify the process.`,
                    canCancelBackground: true,
                    width: '650px',
                    should_warn_close: true,
                    buttons: [
                        {
                            text: 'Cancel',
                            type: 'is-ghost',
                            action: (modal: any) => {
                                modal.close()
                                resolve(false)
                            },
                        },
                        {
                            text: 'Request Password Change',
                            type: 'is-success',
                            action: async (modal: any) => {
                                modal.close()
                                resolve(true)
                            },
                        },
                    ],
                    warn_close: (close: CallableFunction) => {
                        close(false)
                        resolve(false)
                    },
                    close: () => {
                        resolve(false)
                    },
                })
            })
        }

        if (!(await ask_user_prompt())) {
            return
        }

        const url = `${process.env.VUE_APP_AUTH0_ISSUER_BASE_URL}/dbconnections/change_password`
        if (!this.i.$auth0.user.value.email) {
            this.i.$yanui.toast.show('E-Mail is missing!', {
                type: 'is-danger',
            })
            return
        }
        //const access_token = await this.get_access_token()
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                //authorization: `Bearer ${access_token}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                client_id: process.env.VUE_APP_AUTH0_CLIENT_ID,
                email: this.i.$auth0.user.value.email,
                connection: process.env.VUE_APP_AUTH0_CONNECTIONS,
            }),
        })
        const res = await response.text()
        console.log('change_password_request RESULT', res)
        this.i.$yanui.toast.show(res, {
            type: response.status === 200 ? 'is-success' : 'is-danger',
        })
    }

    public async change_email_request(
        new_email: string,
        current_password: string,
    ) {
        //Change Auth0 Email
        const url = `${process.env.VUE_APP_DATOSDRIVE_BACKEND_URL}/user/change-email-request`
        if (!new_email) {
            this.i.$yanui.toast.show('New email not entered!', {
                type: 'is-danger',
            })
            return
        }
        const access_token = await this.get_access_token()
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                authorization: `Bearer ${access_token}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                new_email,
                current_password,
            }),
        })
        const res = await response.json()
        console.log('RESULT', res)
        this.i.$yanui.toast.show(res.message, {
            type: response.status === 200 ? 'is-success' : 'is-danger',
        })
    }

    public async logout() {
        localStorage.clear()
        if (this.i.$yanui.db_func) {
            await this.i.$yanui.db_func.clear_db(false)
            indexedDB.deleteDatabase(this.i.$yanui.db_func.db_name + '.db')
        } else {
            indexedDB.deleteDatabase('datosdrivedb.db')
        }
        await this.i.$auth0.logout({
            logoutParams: {
                returnTo: this.i.$utils.is_native()
                ? process.env.VUE_APP_AUTH0_CALLBACK_URL_NATIVE + '/logout'
                : window.location.origin,
            },
            openUrl: async (url: string) => {
                if (this.is_native()) {
                    await Browser.open({
                        url,
                        windowName: '_self',
                        presentationStyle: 'popover',
                    })
                } else {
                    window.location.replace(url)
                }
            },
        })
        this.i.$plausible.trackEvent('User Logout')
    }

    public file_type_simplified(file: FileEntity): string | null {
        if (file.type === 0) {
            return 'folder'
        }
        if (!file.name) {
            return 'file'
        }

        const extension = this.i.$yanui.file_utils.fileExtension(file.name)

        switch (extension) {
            case 'datdoc':
                return 'file-alt'
        }

        const contentType = mimeTypes.lookup(file.name)
        if (!contentType) {
            return null
        }
        const mime = contentType.split('/')
        const type = mime[0]
        const subtype = mime[1]

        switch (type) {
            case 'audio':
                return 'file-music'
            case 'image':
                return 'file-image'
            case 'video':
                return 'file-video'
        }
        switch (extension) {
            case 'txt':
            case 'rtf':
            case 'md':
            case 'markdown':
                return 'file-alt'
            case 'html':
            case 'htm':
            case 'xml':
            case 'xhtml':
            case 'json':
            case 'css':
            case 'js':
            case 'cs':
            case 'scss':
            case 'sass':
            case 'less':
            case 'sql':
            case 'yml':
            case 'yaml':
            case 'jsx':
            case 'tsx':
            case 'ts':
            case 'vue':
            case 'class':
            case 'cpp':
            case 'c':
            case 'h':
            case 'java':
            case 'py':
            case 'rb':
            case 'php':
            case 'sh':
            case 'bash':
            case 'bat':
            case 'pl':
            case 'pm':
                return 'file-code'
            case 'exe':
            case 'msi':
            case 'dmg':
            case 'msp':
            case 'apk':
            case 'deb':
            case 'rpm':
            case 'pkg':
            case 'dll':
            case 'sys':
            case 'scr':
            case 'app':
                return 'cog'
            case 'ical':
            case 'ics':
            case 'ifb':
            case 'icalendar':
                return 'calendar-alt'
        }

        switch (subtype) {
            case 'msword':
            case 'vnd.openxmlformats-officedocument.wordproces':
            case 'vnd.ms-word.document.macroEnabled.12':
            case 'vnd.ms-word.template.macroEnabled.12':
                return 'file-word'
            case 'vnd.ms-excel':
            case 'vnd.openxmlformats-officedocument.spreadsheetml.sheet':
            case 'vnd.openxmlformats-officedocument.spreadsheetml.template':
            case 'vnd.ms-excel.sheet.macroEnabled.12':
            case 'vnd.ms-excel.template.macroEnabled.12':
            case 'vnd.ms-excel.addin.macroEnabled.12':
            case 'vnd.ms-excel.sheet.binary.macroEnabled.12':
                return 'file-excel'
            case 'vnd.ms-powerpoint':
            case 'vnd.openxmlformats-officedocument.presentationml.presentation':
            case 'vnd.openxmlformats-officedocument.presentationml.template':
            case 'vnd.openxmlformats-officedocument.presentationml.slideshow':
            case 'vnd.ms-powerpoint.addin.macroEnabled.12':
            case 'vnd.ms-powerpoint.presentation.macroEnabled.12':
            case 'vnd.ms-powerpoint.template.macroEnabled.12':
            case 'vnd.ms-powerpoint.slideshow.macroEnabled.12':
                return 'file-powerpoint'
            case 'pdf':
                return 'file-pdf'
            case 'zip':
            case 'x-rar-compressed':
            case 'octet-stream':
            case 'x-zip-compressed':
            case 'x-zip':
            case 'x-7z-compressed':
            case 'x-archive':
            case 'x-cpio':
            case 'x-shar':
            case 'x-iso9660-image':
            case 'x-sbx':
            case 'x-tar':
            case 'x-bzip2':
            case 'gzip':
            case 'x-lzip':
            case 'x-lzop':
            case 'x-snappy-framed':
            case 'x-compress':
            case 'x-xz':
            case 'zstd':
                return 'file-archive'
            default:
                return null
        }
    }

    public get_os(): string {
        //@ts-ignore
        const uA = navigator.userAgent || navigator.vendor || window.opera
        if (
            //@ts-ignore
            (/iPad|iPhone|iPod/.test(uA) && !window.MSStream) ||
            (uA.includes('Mac') && 'ontouchend' in document)
        )
            return 'iOS'

        const os = ['Windows', 'Android', 'Unix', 'Mac', 'Linux', 'BlackBerry']
        for (let i = 0; i < os.length; i++) {
            if (new RegExp(os[i], 'i').test(uA)) {
                return os[i]
            }
        }
        return 'Unknown'
    }

    public is_mobile() {
        const mobile_os = ['Android', 'iOS', 'BlackBerry']
        return mobile_os.includes(this.get_os())
    }

    public is_desktop() {
        return !this.is_mobile()
    }

    public is_native() {
        return Capacitor?.isNativePlatform()
    }

    public toggle_theme() {
        if (this.i.$yanui.theme.value == 'light') {
            localStorage.setItem('theme', 'dark')
            document.documentElement.classList.add('theme-dark')
            document.documentElement.classList.remove('theme-light')
            this.i.$yanui.theme.value = 'dark'
        } else {
            localStorage.setItem('theme', 'light')
            document.documentElement.classList.add('theme-light')
            document.documentElement.classList.remove('theme-dark')
            this.i.$yanui.theme.value = 'light'
        }

        this.i.$plausible.trackEvent('Theme Toggle', {
            props: { theme: this.i.$yanui.theme.value },
        })

        if (this.is_native()) {
            if (this.i.$yanui.theme.value == 'light') {
                StatusBar.setStyle({
                    style: Style.Light,
                })
            } else {
                StatusBar.setStyle({
                    style: Style.Dark,
                })
            }
        }
    }

    public async get_access_token() {
        let token

        try {
            console.log('getAccessTokenSilently')
            token = await this.i.$auth0.getAccessTokenSilently({
                authorizationParams: {
                    redirect_uri: process.env.VUE_APP_AUTH0_CALLBACK_URL,
                },
                timeoutInSeconds: 10,
            })
        } catch (e) {
            console.error(e)
            console.log('getAccessTokenWithPopup')
            try {
                token = await this.i.$auth0.getAccessTokenWithPopup(
                    {
                        authorizationParams: {
                            redirect_uri:
                                process.env.VUE_APP_AUTH0_CALLBACK_URL,
                        },
                    },
                    {
                        timeoutInSeconds: 10,
                    },
                )
            } catch (e) {
                //this.i.$auth0.loginWithRedirect({
                //    openUrl: async (url: string) => {
                //        if (this.is_native()) {
                //            await Browser.open({
                //                url,
                //                windowName: '_self',
                //                presentationStyle: 'popover',
                //            })
                //        } else {
                //            window.location.replace(url)
                //        }
                //    },
                //})
                console.error(e)
            }
        }
        return token
    }

    public browse_files(
        accept = '*',
        type: 'folder' | 'file' = 'file',
        multiple = true,
    ): Promise<File[]> {
        return new Promise((resolve) => {
            const input = document.createElement('input') as HTMLInputElement
            input.type = 'file'
            input.accept = accept
            input.multiple = multiple
            if (type === 'folder') {
                input.webkitdirectory = true
                //@ts-ignore
                input.mozdirectory = true
                //@ts-ignore
                input.msdirectory = true
                //@ts-ignore
                input.odirectory = true
                //@ts-ignore
                input.directory = true
            }
            input.style.position = 'fixed'
            input.style.top = '-9999999px'
            input.style.width = '0px'
            input.style.height = '0px'

            document.body.appendChild(input)

            input.addEventListener('change', () => {
                if (!input.files) {
                    return resolve([])
                }
                console.log('DIALOG OPEN -----', input.files)
                const files: File[] = []
                for (let i = 0; i < input.files.length; i++) {
                    const file = input.files?.item(i)
                    if (file !== null) {
                        console.log('ADD FILE', file)
                        files.push(file)
                    }
                }
                document.body.removeChild(input)
                return resolve(files)
            })
            input.click()
        })
    }
}
