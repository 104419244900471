
import { Options, Vue, prop } from 'vue-class-component'

import { YInputText } from '@pacprotocol/yanui';

class Props {
}

@Options({
    components: {
        YInputText
    },
})
export default class ChangeEmail extends Vue.with(Props) {
    public new_email: string = "";
    private current_password: string = "";

    private update() {
        this.$emit('update', {
            new_email: this.new_email,
            current_password: this.current_password
        });
    }

    created() {
        this.$watch('new_email', (newVal: string) => {
            this.update();
        })
        this.$watch('current_password', (newVal: string) => {
            this.update();
        })
    }


}
