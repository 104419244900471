import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "file-icon-wrapper"
}
const _hoisted_2 = ["src"]
const _hoisted_3 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_fa = _resolveComponent("fa")!

  return (_ctx.image_loading)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (!_ctx.usePreview && _ctx.thumbnail)
          ? (_openBlock(), _createElementBlock("img", {
              class: "icon-image",
              src: _ctx.thumbnail,
              loading: "lazy",
              key: _ctx.thumbnail
            }, null, 8, _hoisted_2))
          : (
                !_ctx.usePreview && _ctx.file._upload && _ctx.file._upload.thumbnail_processing
            )
            ? (_openBlock(), _createBlock(_component_fa, {
                key: 1,
                icon: ['fal', 'spinner-third'],
                spin: "",
                "fixed-width": ""
              }))
            : (_ctx.usePreview && _ctx.preview)
              ? (_openBlock(), _createElementBlock("img", {
                  class: "icon-image",
                  src: _ctx.preview,
                  loading: "lazy",
                  key: _ctx.preview
                }, null, 8, _hoisted_3))
              : (
                _ctx.usePreview && _ctx.file._upload && _ctx.file._upload.preview_processing
            )
                ? (_openBlock(), _createBlock(_component_fa, {
                    key: 3,
                    icon: ['fal', 'spinner-third'],
                    spin: "",
                    "fixed-width": ""
                  }))
                : (_openBlock(), _createElementBlock(_Fragment, { key: 4 }, [
                    (_ctx.file.type === 0)
                      ? (_openBlock(), _createBlock(_component_fa, {
                          key: 0,
                          icon: ['fal', 'folder'],
                          "fixed-width": ""
                        }))
                      : (_ctx.icons && _ctx.icons[0])
                        ? (_openBlock(), _createBlock(_component_fa, {
                            key: 1,
                            icon: ['fal', _ctx.icons[0]],
                            "fixed-width": ""
                          }, null, 8, ["icon"]))
                        : (_openBlock(), _createBlock(_component_fa, {
                            key: 2,
                            icon: ['fal', 'file'],
                            "fixed-width": ""
                          }))
                  ], 64))
      ]))
    : _createCommentVNode("", true)
}