
import { Options, Vue, prop } from 'vue-class-component'
import { FileEntityUpload, FileValidator } from '@pacprotocol/yanui'
import mimeTypes from 'mime-types'

class Props {
    file: FileEntityUpload = prop({
        required: true,
    })
    usePreview = prop({
        type: Boolean,
        required: false,
        default: false,
    })
}

@Options({
    components: {},
})
export default class FileIcon extends Vue.with(Props) {
    private icons: string[] = []
    private image_loading = false

    async created() {
        this.icons = this.file_type_icon()
        this.initialize()
        this.$watch(
            'file',
            () => {
                this.icons = this.file_type_icon()
                this.initialize()
                console.log('$WATCH', 'thumbnail_object_url changed')
            },
            { deep: true },
        )
        this.$watch('file.thumbnail_id', () => {
            this.initialize()
            console.log('$WATCH', 'thumbnail_object_url changed')
        })
        this.$watch('usePreview', () => {
            this.initialize()
        })
        //setInterval(() => {
        //    this.$forceUpdate();
        //}, 1000)
    }

    async initialize() {
        this.image_loading = false;
        if (this.usePreview) {
            if (this.file.preview_id_sha256) {
                await (this.$i
                    ? this.$i
                    : this
                ).$yanui.file_server.file_meta_add({
                    id: this.file.preview_id_sha256,
                    mimetype:
                        mimeTypes.lookup('preview.jpg') ||
                        'application/octet-stream',
                    name: 'preview.jpg',
                    size: -1,
                    key:
                        (await (this.$i
                            ? this.$i
                            : this
                        ).$masterkey.derived_password_file(this.file.secret)) +
                        '-preview',
                    type: '',
                })
            }
        } else {
            if (this.file.thumbnail_id_sha256) {
                await (this.$i
                    ? this.$i
                    : this
                ).$yanui.file_server.file_meta_add({
                    id: this.file.thumbnail_id_sha256,
                    mimetype:
                        mimeTypes.lookup('thumbnail.jpg') ||
                        'application/octet-stream',
                    name: 'thumbnail.jpg',
                    size: -1,
                    key:
                        (await (this.$i
                            ? this.$i
                            : this
                        ).$masterkey.derived_password_file(this.file.secret)) +
                        '-thumbnail',
                    type: '',
                })
            }
        }
        this.image_loading = true;
    }

    get thumbnail() {
        if (this.file?._upload?.thumbnail_object_url) {
            return this.file._upload.thumbnail_object_url
        }
        if (this.file?.thumbnail_id_sha256) {
            return (this.$i ? this.$i : this).$utils.file_link(
                this.file.thumbnail_id_sha256,
            )
        }
        return null
    }
    get preview() {
        if (this.file?._upload?.preview_object_url) {
            return this.file._upload.preview_object_url
        }
        if (this.file?.preview_id_sha256) {
            return (this.$i ? this.$i : this).$utils.file_link(
                this.file.preview_id_sha256,
            )
        }
        return null
    }

    private file_type_icon(): any {
        return [
            (this.$i ? this.$i : this).$utils.file_type_simplified(this.file),
        ]
    }
}
