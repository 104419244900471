/* eslint-disable */

import mitt, { Emitter } from "mitt";
import { FileUtils } from "@pacprotocol/yanui";
import BaseRepresentationGenerator, { BaseRepresentationOptions, BaseRepresentationTypeOptions, RepresentationCaptureInstanceResult } from "./BaseRepresentationGenerator";

export default class VideoRepresentationGenerator extends BaseRepresentationGenerator {
  private startTime: number | null = null;
  private lastTime: number | null = null;
  private currentShot = 0;
  private videoElement: HTMLVideoElement | null = null;
  private videoHeight: number | null = null;
  private videoWidth: number | null = null;
  private videoStart: number | null = null;
  private videoDuration: number | null = null;
  private videoObjectUrl: string | null = null;

  constructor(opts: BaseRepresentationOptions) {
      super(opts);
  }
  
  protected async capture_process(options: BaseRepresentationTypeOptions): Promise<RepresentationCaptureInstanceResult> {
    return new Promise((resolve, reject) => {
        console.log("VIDEO PLAY");
      if (!this.file._upload) return Promise.reject();
      this.event.emit("beforeCapture");
      this.lastTime = this.startTime = new Date().getTime();
      const data = new FormData();
      data.append("file", this.file_source, this.file.name);
      const url = window.URL || window.webkitURL;
      this.videoObjectUrl = url.createObjectURL(this.file_source);
      const videoElement = document.createElement("video");
      videoElement.src = this.videoObjectUrl;
      videoElement.preload = "metadata";
      videoElement.id = "videoHtmlCapture";
      videoElement.controls = true;
      videoElement.width = 600;
      videoElement.crossOrigin = "*";

      const sourceElement = document.createElement("source");
      sourceElement.src = this.videoObjectUrl;
      sourceElement.type = this.file_source.type;

      videoElement.appendChild(sourceElement);
      this.videoElement = videoElement;

      //As soon as the meta is ready, trigger that capture is ready
      this.videoElement.onloadedmetadata = () => {
        if (this.videoElement) {
          this.videoElement.play();
        }
      };
      //Trigger the capture here because the video is ready
      this.videoElement.onplay = () => {
        this.init_screenshot(options);
      };
      //Can't play this video
      this.videoElement.onerror = () => {
        this.event.emit("unsupported");
      };
      this.videoElement.addEventListener("seeked", async () => {
        if (this.videoElement) {
          this.videoElement.pause();
          const blob = await this.capture_screenshot(options);
          if (blob !== undefined) {
            return resolve({
              blob,
              url: URL.createObjectURL(blob),
            })
          } else {
            return reject();
          }
        }
      });
    })
  }
  /**
   * Setup the screenshot
   */
  public init_screenshot(options: BaseRepresentationTypeOptions) {
    if (!this.videoElement) {
      return;
    }
    this.videoWidth = this.videoElement.videoWidth;
    this.videoHeight = this.videoElement.videoHeight;
      console.log("DIMENSIONSSSSSS VIDEO BEFORE INITS", "VW", this.videoWidth, "VH", this.videoHeight);
      console.log("DIMENSIONSSSSSS VIDEO BEFORE INITS", "MW", options.maxWidth, "MH", options.maxHeight);
    //Wide video
    if (this.videoWidth > this.videoHeight) {
      const ratio = options.maxWidth / this.videoWidth;
      this.videoWidth = options.maxWidth;
      this.videoHeight = this.videoHeight * ratio;
      //square video
    } else if (this.videoWidth === this.videoHeight) {
      this.videoWidth = options.maxWidth;
      this.videoHeight = options.maxHeight;
      //tall video
    } else {
      const ratio = options.maxHeight / this.videoHeight;
      this.videoHeight = options.maxHeight;
      this.videoWidth = this.videoWidth * ratio;
      console.log("DIMENSIONSSSSSS VIDEO AFTER INITS", "R", ratio, "W", this.videoWidth, "H", this.videoHeight);
    }
    this.videoElement.style.width = this.videoWidth + "px";
    this.videoElement.style.height = this.videoHeight + "px";
    this.videoDuration = this.videoElement.duration;
    this.videoStart = this.videoDuration / 2;
    this.videoElement.currentTime = this.videoStart;
  }
  public async capture_screenshot(options: BaseRepresentationTypeOptions) {
    if (!this.videoElement || !options.maxWidth || !options.maxHeight) {
      return;
    }
    const dimensions = this.get_proportional_dimensions(
        this.videoWidth as number,
        this.videoHeight as number,
        options.maxWidth,
        options.maxHeight,
        0,
        options.dimension
    )
    console.log(
      "DIMENSIONS VIDEO",
      dimensions,
      this.videoWidth,
      this.videoHeight,
      options.maxWidth,
      options.maxHeight,
      options.dimension
    );
    const canvas = document.createElement("canvas");
    canvas.width = dimensions.width;
    canvas.height = dimensions.height;

    const ctx = canvas.getContext("2d");
    if (ctx) {
      ctx.drawImage(this.videoElement, 0, 0, dimensions.width, dimensions.height);
    }
    const blob = await this.canvas_to_blob(
      canvas,
      options.type,
      options.quality
    );
    return blob;
  }

  public cleanUp() {
    this.videoElement = null;
    if (this.videoObjectUrl) {
      URL.revokeObjectURL(this.videoObjectUrl);
    }
  }
}
