export default {
    name: 'datosdrivedb',
    password: 'UhCM!$qPJ2^ZKaO04^', //Placeholder PW - just to make it at least not directly visible in IndexedDB. The whole document is encrypted anyway before uploading with unique password derived from masterkey.
    collections: {
        storage_quota_history: {
            schema: {
                title: 'DatosDrive Storage Quota History',
                version: 0,
                description: 'stores the storage quota history',
                primaryKey: 'date',
                type: 'object',
                properties: {
                    date: {
                        type: 'string',
                        maxLength: 15,
                    },
                    quota: {
                        type: 'number',
                    },
                },
            },
            encrypted: ['date', 'quota'],
        },
        user: {
            schema: {
                title: 'DatosDrive User Settings',
                version: 0,
                description: 'stores the user settings',
                primaryKey: 'id',
                type: 'object',
                properties: {
                    id: {
                        type: 'string',
                        maxLength: 100,
                    },
                    avatar_id: {
                        type: 'string',
                        maxLength: 100,
                    },
                    avatar_id_sha256: {
                        type: 'string',
                        maxLength: 64,
                    },
                },
            },
            encrypted: ['id', 'avatar_id'],
        },
        files: {
            schema: {
                title: 'DatosDrive Files Listing Entries',
                version: 0,
                description: 'lists the files of which has been uploaded',
                primaryKey: 'id',
                type: 'object',
                properties: {
                    id: {
                        type: 'string',
                        maxLength: 100,
                    },
                    id_sha256: {
                        type: 'string',
                        maxLength: 64,
                        minLength: 64,
                    },
                    secret: {
                        type: 'string',
                        maxLength: 64,
                    },
                    name: {
                        type: 'string',
                        maxLength: 255,
                    },
                    size: {
                        type: 'number',
                    },
                    type: {
                        type: 'number',
                    },
                    created_at: {
                        type: 'number',
                    },
                    updated_at: {
                        type: 'number',
                    },
                    in_folder: {
                        type: 'string',
                    },
                    pin: {
                        type: 'boolean',
                    },
                    thumbnail_id: {
                        type: 'string',
                    },
                    thumbnail_id_sha256: {
                        type: 'string',
                        maxLength: 64,
                        minLength: 64,
                    },
                    preview_id: {
                        type: 'string',
                    },
                    preview_id_sha256: {
                        type: 'string',
                        maxLength: 64,
                        minLength: 64,
                    },
                    meta: {
                        type: 'object',
                    },
                },
            },
            encrypted: [
                'id',
                'name',
                'secret',
                'size',
                'type',
                'created_at',
                'updated_at',
                'in_folder',
                'pin',
                'thumbnail_id',
                'preview_id',
                'meta',
            ],
        },
    },
}
