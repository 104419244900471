import { FileEntity, FileType, Utils, YInputText } from '@pacprotocol/yanui'
import { nanoid } from 'nanoid'
import { Vue } from 'vue-class-component'
import * as bip39 from 'bip39'
import SeedPhrase from '@/components/SeedPhrase.vue'
import { ClientSideDatabase } from '@pacprotocol/yanui'
import MasterKeyRxSchema from '@/schemas/database/masterkeydb'
import { reactive } from 'vue'
import memoize from "memoizee";

export default class DatosMasterKey {
    private vueThis: any
    public master_key?: string
    private master_key_hex?: string
    public masterkey_checksum?: string //<-- It's more rather like "public key" derived from master key.
    private derived_key?: CryptoKey
    private userid?: string
    private csd?: ClientSideDatabase
    private db?: any

    constructor(app: any) {
        this.vueThis = app.config.globalProperties
    }

    get i(): Vue {
        return this.vueThis.$i ? this.vueThis.$i : this.vueThis
    }

    public async initialize(userid: string) {
        this.userid = userid
    }

    public async stored_masterkey() {
        const masterkey = localStorage.getItem('masterkey')
        if (masterkey) {
            return masterkey
        } else {
            return ''
        }
    }

    public async store_masterkey(masterkey: string) {
        localStorage.setItem('masterkey', masterkey)
    }

    public async import(masterkey: string) {
        const buffer = await bip39.mnemonicToSeed(masterkey)
        const uint8array = new Uint8Array(buffer)
        this.master_key_hex = buffer.toString('hex')
        this.master_key = masterkey
        this.derived_key = await crypto.subtle.importKey(
            'raw',
            uint8array,
            { name: 'PBKDF2' },
            false,
            ['deriveKey'],
        )
        await this.checksum()
    }

    private async checksum() {
        const derived = await this.derived_password_file_string(
            'masterkey-checksum-' + this.userid,
            256,
            200_000,
        )
        this.masterkey_checksum = await this.i.$utils.sha256(derived)
        return this.masterkey_checksum
    }

    public async generate_masterkey(length = 128) {
        return bip39.generateMnemonic(length)
    }

    public async seedphrase() {
        if (this.master_key) {
            return this.master_key
        } else {
            return ''
        }
    }

    public async _derived_password(
        seed: string,
        length: number = 256,
        iterations: number = 100000,
    ): Promise<CryptoKey> {
        if (this.derived_key) {
            console.log("Not Cached", seed, Utils.string_to_uint8array(seed));
            const password = await crypto.subtle.deriveKey(
                {
                    name: 'PBKDF2',
                    salt: Utils.string_to_uint8array(seed),
                    iterations,
                    hash: 'SHA-256',
                },
                this.derived_key,
                { name: 'AES-GCM', length },
                true,
                ['encrypt', 'decrypt'],
            )
            console.log("Not Cached???");
            return password
        } else {
            throw new Error('Derived key not initialized')
        }
    }

    public derived_password = memoize(this._derived_password, { length: false, promise: true });

    public async derived_password_file(
        filesecret: string,
        length = 256,
        iterations = 100000,
    ): Promise<CryptoKey> {
        return this._derived_password(filesecret, length, iterations)
    }
    public async derived_password_file_string(
        filesecret: string,
        length = 256,
        iterations = 100000,
    ): Promise<string> {
        console.log("derived_password_file_string 1", filesecret, length, iterations)
        const password = await this.derived_password_file(
            filesecret,
            length,
            iterations,
        )
        console.log("derived_password_file_string 2", password.algorithm)
        const exportKey = await crypto.subtle.exportKey('raw', password)
        console.log("derived_password_file_string 3", exportKey)
        return new TextDecoder().decode(exportKey)
    }
    public async derived_password_file_hex(
        filesecret: string,
        length = 256,
        iterations = 100000,
    ): Promise<string> {
        const password = await this.derived_password_file(
            filesecret,
            length,
            iterations,
        )
        const exportKey = await crypto.subtle.exportKey('raw', password)
        return Buffer.from(exportKey).toString('hex')
    }

    public async show_master_key(props: any = {}) {
        this.i.$yanui.dialog.alert({
            instance: this,
            title: 'Your Master Key!',
            components: [
                {
                    component: SeedPhrase,
                    props: {
                        readonly: true,
                        phrases: this.i.$masterkey.master_key
                            ?.trim()
                            .split(' '),
                        ...props,
                    },
                },
            ],
            canCancelBackground: false,
            canCancel: false,
            maxWidth: '700px',
            overflow: 'auto',
            buttons: [
                {
                    text: "It's written down!",
                    type: 'is-success',
                    action: (modal: any) => {
                        modal.close()
                    },
                },
            ],
            close: () => {
                //console.log('CLOOOOSE')
            },
        })
    }
    public async import_master_key(props: any = {}): Promise<string> {
        let phrases: string[] = []
        return new Promise((resolve) => {
            this.i.$yanui.dialog.alert({
                instance: this,
                title: 'Import your Master Key!',
                components: [
                    {
                        component: SeedPhrase,
                        props: {
                            readonly: false,
                            ...props,
                        },
                        events: {
                            change: (value: string[]) => {
                                console.log('CHANGED', value)
                                if (value.length === 12) {
                                    phrases = value
                                }
                            },
                        },
                    },
                ],
                canCancelBackground: false,
                canCancel: false,
                maxWidth: '700px',
                overflow: 'auto',
                buttons: [
                    {
                        text: 'Import',
                        type: 'is-success',
                        action: async (modal: any) => {
                            console.log('Validate Seed Phrase', phrases)
                            const masterkey = phrases.join(' ')
                            //Validate the seed phrase
                            if (!bip39.validateMnemonic(masterkey)) {
                                this.i.$yanui.toast.show(
                                    'Please enter a valid Master Key',
                                    {
                                        type: 'is-danger',
                                    },
                                )
                                return
                            }
                            //Validate if masterkey_checksum is a match
                            await this.i.$masterkey.import(masterkey)
                            if (
                                !(await this.i.$utils.masterkey_checksum_match(
                                    this.i.$masterkey
                                        .masterkey_checksum as string,
                                ))
                            ) {
                                this.i.$yanui.toast.show(
                                    'Not compatible Master Key!',
                                    {
                                        type: 'is-danger',
                                    },
                                )
                                return
                            }
                            modal.close()
                            return resolve(masterkey)
                        },
                    },
                    {
                        text: 'Logout',
                        type: 'is-danger',
                        action: async (modal: any) => {
                            await this.i.$utils.logout()
                            return resolve('')
                        },
                    },
                ],
                close: () => {
                    //console.log('CLOOOOSE')
                },
            })
        })
    }

    private convertArrayBufferToHexaDecimal(buffer: ArrayBufferLike) {
        const data_view = new DataView(buffer)
        let iii,
            len,
            hex = '',
            c

        for (iii = 0, len = data_view.byteLength; iii < len; iii += 1) {
            c = data_view.getUint8(iii).toString(16)
            if (c.length < 2) {
                c = '0' + c
            }

            hex += c
        }

        return hex
    }
}
