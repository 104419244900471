import { resolveComponent as _resolveComponent, withModifiers as _withModifiers, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, withCtx as _withCtx, createVNode as _createVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_y_button = _resolveComponent("y-button")!
  const _component_y_dropdown_item = _resolveComponent("y-dropdown-item")!
  const _component_y_dropdown = _resolveComponent("y-dropdown")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.file._upload && _ctx.file._upload.upload_status !== 'uploading')
      ? (_openBlock(), _createBlock(_component_y_button, {
          key: 0,
          type: _ctx.type === 'list' ? 'is-success' : 'is-ghost-white',
          icon: "times",
          square: true,
          spacing: true,
          size: "is-small",
          class: "no-animation",
          onClick: _withModifiers(_ctx.file_remove, ["stop"])
        }, null, 8, ["type", "onClick"]))
      : _createCommentVNode("", true),
    (!_ctx.file._upload)
      ? (_openBlock(), _createBlock(_component_y_dropdown, {
          key: 1,
          origin: "75% 0px",
          horizontal: "auto-right",
          vertical: "auto",
          teleport: "#teleport-popup"
        }, {
          trigger: _withCtx((prop) => [
            _createVNode(_component_y_button, {
              type: 
                    !_ctx.square
                        ? 'is-grey'
                        : _ctx.type === 'list'
                        ? 'is-ghost'
                        : 'is-ghost-white'
                ,
              icon: _ctx.square ? 'ellipsis-h' : '',
              square: _ctx.square,
              spacing: true,
              size: _ctx.square ? 'is-small' : undefined,
              state: prop.show ? 'active' : '',
              dropdown: !_ctx.square
            }, {
              default: _withCtx(() => [
                (!_ctx.square)
                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                      _createTextVNode(" More ")
                    ], 64))
                  : _createCommentVNode("", true)
              ]),
              _: 2
            }, 1032, ["type", "icon", "square", "size", "state", "dropdown"])
          ]),
          default: _withCtx(() => [
            (
                _ctx.file.type == 1 &&
                (_ctx.file.name.split('.').pop() == 'datdoc' ||
                    _ctx.file.name.split('.').pop() == 'pdf')
            )
              ? (_openBlock(), _createBlock(_component_y_dropdown_item, {
                  key: 0,
                  icon: "file-edit",
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$utils.editor_docs_open(_ctx.file)))
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(" Edit ")
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true),
            (_ctx.file.type == 1)
              ? (_openBlock(), _createBlock(_component_y_dropdown_item, {
                  key: 1,
                  icon: "share-alt",
                  onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$utils.file_share_window(_ctx.file)))
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(" Share ")
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true),
            (_ctx.file.type == 1)
              ? (_openBlock(), _createBlock(_component_y_dropdown_item, {
                  key: 2,
                  icon: "cloud-download",
                  onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$utils.file_download(_ctx.file)))
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(" Download ")
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true),
            (!_ctx.file.pin)
              ? (_openBlock(), _createBlock(_component_y_dropdown_item, {
                  key: 3,
                  icon: "thumbtack",
                  onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$utils.file_pin(_ctx.file)))
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(" Pin File ")
                  ]),
                  _: 1
                }))
              : (_openBlock(), _createBlock(_component_y_dropdown_item, {
                  key: 4,
                  icon: "thumbtack",
                  onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.$utils.file_unpin(_ctx.file)))
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(" Unpin File ")
                  ]),
                  _: 1
                })),
            _createVNode(_component_y_dropdown_item, {
              icon: "i-cursor",
              onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.$utils.file_rename_window(_ctx.file)))
            }, {
              default: _withCtx(() => [
                _createTextVNode(" Rename ")
              ]),
              _: 1
            }),
            _createVNode(_component_y_dropdown_item, {
              icon: "trash-alt",
              type: "is-danger",
              onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.$utils.file_delete_window(_ctx.file)))
            }, {
              default: _withCtx(() => [
                _createTextVNode(" Delete ")
              ]),
              _: 1
            })
          ]),
          _: 1
        }))
      : _createCommentVNode("", true)
  ], 64))
}