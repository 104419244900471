import { normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode } from "vue"

const _hoisted_1 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_fa = _resolveComponent("fa")!

  return (_openBlock(), _createElementBlock("div", {
    class: "avatar-wrapper-component",
    key: _ctx.avatar_id
  }, [
    (_ctx.avatar_loaded)
      ? (_openBlock(), _createElementBlock("img", {
          key: 0,
          style: _normalizeStyle({
                width: _ctx.size + 'px',
                height: _ctx.size + 'px',
                maxWidth: _ctx.size + 'px',
                maxHeight: _ctx.size + 'px',
                fontSize: _ctx.size + 'px',
            }),
          src: _ctx.$yanui.file_server.file_link(_ctx.avatar_id)
        }, null, 12, _hoisted_1))
      : (_openBlock(), _createElementBlock("div", {
          key: 1,
          class: "empty-avatar",
          style: _normalizeStyle({
                width: _ctx.size + 'px',
                height: _ctx.size + 'px',
                maxWidth: _ctx.size + 'px',
                maxHeight: _ctx.size + 'px',
                fontSize: _ctx.size + 'px',
            })
        }, [
          _createVNode(_component_fa, { icon: ['fal', 'user'] })
        ], 4))
  ]))
}