import { Options, Vue, prop } from 'vue-class-component'
import { FileEntity, FileEntityUpload, FileType } from '@pacprotocol/yanui'

export class Props {
    fileLink = prop({
        type: String,
        required: false,
        default: '',
    })
    folderId = prop({
        type: String,
        required: false,
        default: '',
    })
    fileId = prop({
        type: String,
        required: false,
        default: null,
    })
    value = prop({
        type: String,
        required: false,
        default: '',
    })
    readonly = prop({
        type: Boolean,
        required: false,
        default: false,
    })
}

@Options({
    components: {},
})
export default class GeneralFileEditor extends Vue.with(Props) {
    protected last_saved: Date = new Date()
    protected last_changed?: Date
    protected timeout?: any
    protected saved: boolean = true
    protected loaded: boolean = false
    protected show_editor: boolean = false
    protected file_link: string = ''

    protected editable: boolean = false;

    protected fileName = 'Untitled Document'
    protected content: string = ''
    protected _file_id: string = ''
    protected _folder_id: string = ''

    protected file: FileEntity | null = null

    protected extension: string = 'datdoc'

    async mounted() {
        await this.initialize()
    }

    private async initialize() {
        this.editable = false
        if (!this.fileId) {
            this.editable = true
        }

        this._file_id = this.fileId
        this._folder_id = this.folderId


        this.file_link = this.fileLink;

        if (!this.readonly) {
            this.$watch('content', (prevContent, newContent) => {
                if (this.loaded) {
                    this.saved = false
                    if (this.timeout) {
                        clearTimeout(this.timeout)
                    }
                    this.timeout = setTimeout(() => {
                        this.auto_save()
                    }, 2000)
                }
            })
            this.$watch('$store.state.editor_filename', async () => {
                console.log(
                    'DatosDoc - FILE NAME CHANGED',
                    this.$store.state.editor_filename,
                )
                this.fileName = this.$store.state.editor_filename
                await this.save_filename()
            })
        } else {
            await this.download_file();
        }

        await this.$yanui.db_ready()
        await this.load_file()
        if (!this.fileLink) {
            console.log("FILE PSD", this.file);
            //@ts-ignore
            this.file_link = this.$utils.file_link(this.file)
        }
        console.log('DatosDoc - FILE', this.file, this.fileName)
        this.$store.state.editor_filename = (this.file?.name ?? this.fileName)
            .split('.')
            .shift() as string
        if (!this.readonly) {
            this.loaded = true
        }
    }

    protected async auto_save() {
        this.save();
    }

    private file_subscription: any = null

    protected async save_filename(extension: string = this.extension) {
        if (!this.file) return
        const ext = this.$store.state.editor_filename.split('.').pop()
        const file_name =
            this.fileName + (ext === extension ? '' : `.${extension}`)
        //@ts-ignore
        await this.file.atomicPatch({
            name: file_name,
        })
        this.$utils.database_upload_trigger(3)
    }

    protected async load_file() {
        console.log('DatosDoc - LOAD FILE CALLED')
        if (this._file_id && !this.file) {
            const query = await this.$yanui.db.files.findOne({
                selector: {
                    id_sha256: this._file_id,
                },
            })
            const file = await query.exec()
            if (file) {
                this.file = file
            }
            if (!this.readonly) {
                this.file_subscription = query.$.subscribe(async (file) => {
                    this.editable = false
                    console.log(
                        'DatosDoc - FILE UPLOAD????????? Change Detected',
                    )
                    this.file = file
                    try {
                        await this.download_content()
                        this.editable = true
                    } catch (e) {
                        console.log('DatosDoc - Error', e)
                        this.editable = true
                    }
                })
            }
        }
    }

    beforeDestroy() {
        if (this.file_subscription) {
            this.file_subscription.unsubscribe()
        }
    }

    protected async download_content() {
        if (!this.file) return
        console.log(`${this.extension} - Downloading....`)
        this.loaded = false

        const password = await this.$masterkey.derived_password_file(
            this.file?.secret as string,
        )
        await this.$yanui.file_server.file_meta_add({
            id: this._file_id,
            mimetype: 'text/plain',
            name: this.file?.name ?? '',
            size: this.file?.size ?? 0,
            key: password,
            type: '',
        })

        await this.download_file();
    }

    protected async upload_file(): Promise<File | void> {
        //@ Implement it
    }

    protected async download_file(): Promise<void> {
        //@ Implement it

    }

    protected async save() {
        console.log('FILE UPLOAD?????????')
        const file_id = this._file_id ?? this.$utils.file_generate_id()
        const file_id_sha256 = await this.$utils.sha256(file_id)
        const extension = this.$store.state.editor_filename.split('.').pop()

        const file_name =
            this.fileName + (extension === this.extension ? '' : '.' + this.extension)

        const file_object = await this.upload_file()

        if (!file_object) {
            throw new Error('File object is null - upload_file were not overriden.')
        }

        if (this.file) {
            // file already exists - so we just update it.
            await this.upload(file_object)
            return
        }

        const file_secret = this.$utils.file_generate_secret()
        const folder_id = this._folder_id

        const file: FileEntityUpload = {
            id: file_id,
            id_sha256: file_id_sha256,
            secret: file_secret,
            name: file_name,
            size: file_object.size,
            type: FileType.FILE,
            created_at: Date.now(),
            updated_at: Date.now(),
            in_folder: folder_id,
            _upload: {
                id: this.$utils.file_generate_id(),
                source_file: file_object,
                source_file_src: URL.createObjectURL(file_object),
                uploading: false,
                upload_status: 'idle',
                progress: 0,
                bytes_uploaded: 0,
            },
        }

        file.name = await this.$utils.file_name_duplicate_rename(
            file.name,
            folder_id,
        )

        delete file._upload
        this.file = await this.$yanui.db.files.insert(file)
        this._file_id = file_id_sha256
        await this.upload(file_object)
        //await this.load_file()
        this.$router.replace({
            hash: `#${this._file_id}`,
        })
        this.last_saved = new Date()
        this.saved = true
    }

    private async upload(file: File) {
        if (!this.file) return
        const password = await this.$masterkey.derived_password_file(
            this.file.secret as string,
        )
        await this.$utils.file_upload(
            file,
            //@ts-ignore
            password,
            {
                id: this.file.id_sha256,
                editable: true,
            },
            {
                on_upload_progress: () => {
                    //
                },
                on_upload_error: (error: any) => {
                    //
                },
            },
        )
        const file_doc = await this.$yanui.db.files
            .findOne({
                selector: {
                    id_sha256: this.file.id_sha256,
                },
            })
            .exec()
        if (!file_doc) {
            throw new Error(
                'DatosDoc - Doc File not found! - ' + this.file.id_sha256,
            )
        }
        await file_doc.atomicPatch({
            updated_at: Date.now(),
            size: file.size,
        })
        if (file_doc) {
            await this.$utils.folder_update_stats(file_doc.in_folder)
        }
    }
}
