
import { Options, Vue } from 'vue-class-component'

import { YButton } from '@pacprotocol/yanui'

@Options({
    name: 'Dashboard',
    components: {
        YButton,
    },
})
export default class SimplePageLayout extends Vue {
    mounted() {
        //
    }

    back() {
        if (this.$yanui.utils.get_back_router_path(this.$router)) {
            this.$router.back()
        } else {
            this.$router.push('/')
        }
    }
}
