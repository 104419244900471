import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "preview-video" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_y_music_player = _resolveComponent("y-music-player")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_y_music_player, {
      parent: _ctx.el,
      options: {
                songs: [
                    {
                        title: _ctx.file.name,
                        artist: 'Unknown',
                        album: 'Unknown',
                        url: _ctx.fileLink,
                        cover_art_url: _ctx.thumbnail,
                    }
                ]
            }
    }, null, 8, ["parent", "options"])
  ]))
}