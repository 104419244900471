
import { Options, Vue, prop } from 'vue-class-component'
import FileItem from '@/components/FileItem.vue'
import Preview from '@/components/PreviewComponent/Preview.vue'
import { FileEntity, FileType, RectSize } from '@pacprotocol/yanui'
import mimeTypes from 'mime-types'
import FileDropdown from "@/components/FileDropdown.vue";


import {
    YButton,
    YDropdown,
    YDropdownItem,
    YModal,
    YListChunk,
    YSlider,
    TrackPointer,
} from '@pacprotocol/yanui'

class Props {
    files: FileEntity[] = prop({
        required: true,
    })

    view_mode = prop({
        type: Object,
        required: true,
    })

    isFor = prop({
        type: String,
        default: '',
    })
    tool = prop({
        type: Boolean,
        required: false,
        default: true,
    })
}

@Options({
    components: {
        YButton,
        YDropdown,
        YModal,
        YDropdownItem,
        FileItem,
        YListChunk,
        YSlider,
        Preview,
        FileDropdown,
    },
})
export default class FileItems extends Vue.with(Props) {
    private sort_type = 'updated'

    private file_display = []
    private item_per_row: number = 5
    private size_cache: RectSize = {}

    private file_detals_modal_enabled: boolean = false
    private file_details: FileEntity | null = null
    private file_details_index: number = -1
    private slider_left: boolean = false
    private slider_right: boolean = false
    private fileItemFocus: HTMLElement | null = null
    private track_pointer: TrackPointer | null = null
    private extension: string = ''

    declare $refs: {
        file_drag_element: HTMLInputElement
    }

    created() {
        //
    }

    mounted() {
        this.file_display = this.apply_files()
        console.log('FILES', this.file_display)
        this.$watch('files', () => {
            this.file_display = this.apply_files()
        })
        this.view_mode_update()
        this.$watch('view_mode', () => this.view_mode_update(), { deep: true })
    }

    private async file_details_edit(type: string, file: FileEntity) {
        switch (type) {
            case 'rename':
                await this.$utils.file_rename_window(file)
                break
            case 'delete':
                await this.$utils.file_delete_window(file)
                this.hide_details()
                break
            case 'download':
                await this.$utils.file_download(file)
                break
            case 'share':
                await this.$utils.file_share_window(file)
                break
            case 'edit':
                await this.$utils.editor_docs_open(file)
                break
        }
        this.file_names_updated()
    }

    private async slide(direction: string) {
        if (direction === 'left') {
            this.file_details_index = (
                this.$i ? this.$i : this
            ).$yanui.utils.findPreviousIndex(
                this.files,
                this.file_details_index,
                (file: FileEntity) => file.type !== FileType.FOLDER,
            )

            if (this.file_details_index <= 0) {
                this.file_details_index = 0
                this.slider_left = false
            }

            this.slider_right = true
            this.file_details = this.files[this.file_details_index]
        } else {
            this.file_details_index = (
                this.$i ? this.$i : this
            ).$yanui.utils.findNextIndex(
                this.files,
                this.file_details_index,
                (file: FileEntity) => file.type !== FileType.FOLDER,
            )

            if (this.file_details_index >= this.files.length - 1) {
                this.file_details_index = this.files.length - 1
                this.slider_right = false
            }

            this.slider_left = true
            this.file_details = this.files[this.file_details_index]
        }
        await (this.$i ? this.$i : this).$yanui.file_server.file_meta_add({
            id: this.file_details.id_sha256,
            mimetype:
                mimeTypes.lookup(this.file_details.name) ||
                'application/octet-stream',
            name: this.file_details.name,
            size: this.file_details.size,
            key: await (this.$i
                ? this.$i
                : this
            ).$masterkey.derived_password_file(this.file_details.secret),
            type: '',
        })
        this.file_names_updated()
    }

    private open_file_details(
        file: FileEntity,
        el: HTMLElement,
        index: number,
    ) {
        if (file.type === FileType.FOLDER) {
            this.$emit('folder-open', file, el, index)
            return
        }

        this.file_detals_modal_enabled = true
        this.file_details = file
        this.file_names_updated()
        this.file_details_index = index

        const next_file = (this.$i ? this.$i : this).$yanui.utils.findNextIndex(
            this.files,
            this.file_details_index,
            (file: FileEntity) => file.type !== FileType.FOLDER,
        )

        const previous_file = (
            this.$i ? this.$i : this
        ).$yanui.utils.findPreviousIndex(
            this.files,
            this.file_details_index,
            (file: FileEntity) => file.type !== FileType.FOLDER,
        )

        this.slider_right = next_file !== index
        this.slider_left = previous_file !== index

        this.extension = this.files[index].name.split('.').pop() || ''
    }

    private view_mode_update() {
        this.$el.style.setProperty(
            '--icon-height',
            this.view_mode.icon_height + 'px',
        )
        this.$el.style.setProperty(
            '--icon-padding',
            this.view_mode.icon_padding + 'px',
        )
        this.$el.style.setProperty(
            '--item-height',
            this.view_mode.item_height + 'px',
        )
        this.$el.style.setProperty(
            '--item-width',
            this.view_mode.item_width + 'px',
        )
        this.$el.style.setProperty(
            '--file-name-size',
            this.view_mode.file_name_size + 'px',
        )
        this.$el.style.setProperty(
            '--size-name-size',
            this.view_mode.size_name_size + 'px',
        )

        this.listSizeChanged()
    }

    private load_more_files(done: CallableFunction) {
        this.$emit('files-load-more', done)
    }

    private listSizeChanged(size: RectSize | null = null) {
        if (!size) {
            //@ts-ignore
            const { $el } = this.$refs.listChunk as HTMLElement
            const bounding = $el.getBoundingClientRect()
            size = {
                height: bounding.height,
                width: bounding.width,
                top: 0,
                left: 0,
            }
        }

        if (!size.width) return

        const item_width = this.view_mode.item_width
        this.item_per_row = Math.max(1, Math.floor((size.width - item_width) / item_width));
        this.file_display = this.apply_files()
    }


    private apply_files() {
        if (this.view_mode.type === 'list' || this.files.length == 0) {
            return this.files
        } else {
            const files: any = []
            const chunk_size = this.item_per_row
            let chunk_index = -1

            for (let i = 0; i < this.files.length; i++) {
                if (i % chunk_size === 0) {
                    files[++chunk_index] = []
                }
                files[chunk_index].push(this.files[i])
            }

            const remainder = chunk_size - files[chunk_index].length
            for (let i = 0; i < remainder; i++) {
                files[chunk_index].push(null) //Fill up the empty file spaces
            }
            return files
        }
    }

    private fileItemFocusTrigger(event: Event) {
        this.fileItemFocus = event.target as HTMLElement
    }

    private sliderClose() {
        if (this.fileItemFocus) {
            this.fileItemFocus.focus()
        }
    }

    private file_remove(
        file: FileEntity,
        $el: any,
        index: number,
        client_only: boolean = false,
        hide_details: boolean = true,
    ) {
        if (hide_details) {
            this.hide_details()
        }
        this.$emit('file-remove', file, $el, index, client_only)
    }

    public hide_details() {
        this.file_detals_modal_enabled = false
    }

    private async file_details_rename() {
        if (!this.file_details) return
        this.file_details.name = await this.$utils.file_rename_window(
            this.file_details,
        )
    }
    private file_share(file: FileEntity, $el: any, index: number) {
        this.$emit('file-share', file, $el, index)
    }

    beforeUnmount() {
        if (this.track_pointer) {
            this.track_pointer.destroy()
        }
    }

    private file_names: string[] = []
    private file_names_updated() {
        this.file_names = []
        if (!this.file_details) return
        let extension: string = (
            this.$i ? this.$i : this
        ).$yanui.file_utils.fileExtension(this.file_details.name)
        if (!extension) {
            extension = ''
        }
        let name = this.file_details.name
        if (!name) {
            this.file_names.push('unknown')
            this.file_names.push('')
            this.file_names.push('')
            return
        }
        if (extension) {
            name = this.file_details.name.replace(
                new RegExp(`.${extension}$`),
                '',
            )
        }

        this.file_names.push(name.substring(0, 2))
        this.file_names.push(name.substring(2, name.length))
        this.file_names.push(extension ? '.' + extension : '')
        console.log("FILE NAMES", this.file_names);
    }

    private file_drag_abort() {
        if (this.$refs.file_drag_element.firstElementChild) {
            this.$refs.file_drag_element.firstElementChild.remove()
        }
        document.documentElement.classList.remove('no-select')
    }

    private file_drag(
        file_item: FileItem,
        point_position: any,
        file: FileEntity,
        index: number,
    ) {
        this.$store.state.is_file_dragging = true
        document.documentElement.classList.add('no-select')

        this.$nextTick(() => {
            file_item.file_is_inactive = true
            //@ts-ignore
            const original_file_item: HTMLElement = file_item.$el
            const file_item_rectangle =
                original_file_item.getBoundingClientRect()

            //@ts-ignore
            const file_drag_element: HTMLElement = this.$refs.file_drag_element
            file_drag_element.appendChild(original_file_item.cloneNode(true))
            const clone_file_item =
                file_drag_element.firstElementChild as HTMLElement
            clone_file_item.classList.add('is-drag-fake')

            const relative_position = {
                x: point_position.x - file_item_rectangle.x,
                y: point_position.y - file_item_rectangle.y,
            }
            const start_position = {
                x: point_position.x - relative_position.x,
                y: point_position.y - relative_position.y,
            }
            clone_file_item.style.transformOrigin = `${relative_position.x}px ${relative_position.y}px`

            //Resize
            file_drag_element.style.transition = 'none'
            file_drag_element.style.width = file_item_rectangle.width + 'px'
            file_drag_element.style.height = file_item_rectangle.height + 'px'
            file_drag_element.style.transform = `translate(${start_position.x}px, ${start_position.y}px)`
            file_drag_element.style.opacity = '1'

            file_drag_element.style.setProperty(
                '--icon-height',
                this.view_mode.icon_height + 'px',
            )
            file_drag_element.style.setProperty(
                '--icon-padding',
                this.view_mode.icon_padding + 'px',
            )
            file_drag_element.style.setProperty(
                '--file-name-size',
                this.view_mode.file_name_size + 'px',
            )
            file_drag_element.style.setProperty(
                '--size-name-size',
                this.view_mode.size_name_size + 'px',
            )

            //Delete File Action YButton
            const file_action_elem =
                file_drag_element.getElementsByClassName('file-action')[0]
            //@ts-ignore
            file_action_elem.style.opacity = '0'
            let last_touched_file: HTMLElement | null = null

            this.track_pointer = new TrackPointer()

            this.track_pointer.events.on('position', (pos: any) => {
                file_drag_element.style.transform = `translate(${
                    pos.x - relative_position.x
                }px, ${pos.y - relative_position.y}px)`

                const point_element = document.elementFromPoint(
                    pos.x,
                    pos.y,
                ) as HTMLElement
                let temp_last_touched_file: HTMLElement | null = point_element
                if (temp_last_touched_file) {
                    temp_last_touched_file =
                        temp_last_touched_file.closest('.drop-zone')

                    if (!temp_last_touched_file) {
                        const clickable_element = point_element.closest(
                            '.dropdown-folder-trigger',
                        )
                        if (
                            clickable_element &&
                            !clickable_element.classList.contains(
                                'dropdown-trigger-active',
                            )
                        ) {
                            //@ts-ignore
                            clickable_element.click()
                        }
                    }
                } else {
                    temp_last_touched_file = null
                }

                if (temp_last_touched_file !== last_touched_file) {
                    temp_last_touched_file &&
                        temp_last_touched_file.classList.add('hover')
                    last_touched_file &&
                        last_touched_file.classList.remove('hover')
                }
                last_touched_file = temp_last_touched_file

                if (
                    last_touched_file &&
                    last_touched_file.getAttribute('file-id') !== file.id
                ) {
                    clone_file_item?.classList.add('is-dropable')
                } else {
                    clone_file_item?.classList.remove('is-dropable')
                }
            })

            this.track_pointer.events.on('up', async (pos: any) => {
                if (this.track_pointer) {
                    this.track_pointer.destroy()
                }
                this.track_pointer = null

                this.$store.state.is_file_dragging = false
                const target_folder_id = last_touched_file
                    ? last_touched_file.getAttribute('file-id')
                    : undefined
                let result_file_move = false
                const dropable_folder =
                    (target_folder_id || target_folder_id === null) &&
                    target_folder_id !== file.id
                if (dropable_folder) {
                    //Trigger Move File
                    result_file_move = await this.$utils.file_move(
                        [file],
                        //@ts-ignore
                        target_folder_id,
                    )
                    if (result_file_move) {
                     //   this.file_remove(file, file_item.$el, index, true)
                    }
                    if (clone_file_item && result_file_move) {
                        clone_file_item.style.transform = 'scale(0.5)'
                        clone_file_item.style.opacity = '0'
                    }
                    ;(this.$i ? this.$i : this).$plausible.trackEvent(
                        'File Moved',
                        { props: { type: file.type } },
                    )
                }

                if (!result_file_move) {
                    const rect = file_item.$el.getBoundingClientRect()
                    file_drag_element.style.transition = ''
                    this.$nextTick(() => {
                        file_drag_element.style.width = rect.width + 'px'
                        file_drag_element.style.height = rect.height + 'px'
                        file_drag_element.style.transform = `translate(${rect.x}px, ${rect.y}px)`
                        file_drag_element.style.opacity = '0'
                        file_item.file_is_inactive = false
                    })
                }
                if (last_touched_file) {
                    last_touched_file.classList.remove('hover')
                }

                setTimeout(() => {
                    this.file_drag_abort()
                    file_item.file_drag_abort()
                }, 400)
            })
        })
    }
}
