
import { Options, Vue } from 'vue-class-component'
import UploadDashboard from '@/components/Upload/UploadDashboard.vue'

import {
    YButton,
    YMenuItem,
    YInputText,
    YDropdown,
    YDropdownItem,
    YDropdownCard,
    YProgressBar,
    FileType,
} from '@pacprotocol/yanui'

import RemoteDBSchema from '@/schemas/database/index'
import Avatar from '@/components/Avatar.vue'

@Options({
    name: 'Dashboard',
    components: {
        YMenuItem,
        YInputText,
        YButton,
        UploadDashboard,
        YDropdown,
        YDropdownItem,
        YDropdownCard,
        YProgressBar,
        Avatar,
    },
})
//              <!--:style="(hidden_menu_index == $index ? 'display: none' : null)"-->
export default class DashboardLayout extends Vue {
    private uploadDashboardComponent = UploadDashboard
    private pin_menu = false
    private resize_timer: any = null
    private show_others = false
    private search_input = ''
    private scroll_direction = 'scroll-initial'

    private last_scroll_top = 0
    private search_height_scroll = 64
    private search_height_val = 1

    private menus = [
        {
            icon: 'home-alt',
            text: 'Home',
            to: {
                name: 'Home',
            },
        },
        {
            icon: 'folder',
            text: 'Files',
            to: {
                name: 'Files',
            },
        },
        {
            icon: 'file-alt',
            text: 'DatosDocs',
            type: 'open-docs',
        },
        {
            icon: 'image',
            text: 'Media',
            to: {
                name: 'Media',
            },
        },
        {
            icon: 'thumbtack',
            text: 'Pinned',
            to: {
                name: 'Pinned',
            },
        },
        {
            icon: 'history',
            text: 'Last Used',
            to: {
                name: 'LastUsed',
            },
        },
        //{
        //    icon: 'code',
        //    text: 'Elements',
        //    to: {
        //        name: 'Elements',
        //    },
        //}
        /*
    {
      icon: "users",
      text: "Shared",
      to: "/files",
    },
    {
      icon: "star",
      text: "Favorites",
      to: "/files",
    },
    {
      icon: "clock",
      text: "Last Used",
      to: "/files",
    },
    {
      icon: "trash",
      text: "Removed",
      to: "/files",
    },
    */
    ]

    private hidden_menu_index = -1
    private user_loaded = false
    private file_dragging = false
    private file_upload_modal_active = false
    private is_dev = process.env.NODE_ENV === 'development'
    private meta: any = {}

    private user = null

    declare $refs: {
        nav: HTMLElement
    }

    async toggle_pin() {
        this.pin_menu = !this.pin_menu
        ;(this.$i ? this.$i : this).$plausible.trackEvent('Menu Pinned', {
            props: { pinned: this.pin_menu.toString() },
        })
    }

    private link_click(type: string) {
        switch (type) {
            case 'open-docs':
                this.$store.state.editor_folder_id = ''
                this.$router.push({
                    name: 'DatosDocs',
                })
                break
        }
    }

    async beforeCreate() {
        const meta = this.$route.meta
        this.meta = meta
        if (meta.editor) {
            this.pin_menu = false
        }

        console.log('beforeCreated')
        if (!this.$auth0.user?.value?.sub) return
        //await this.$yanui.db_initialize(RemoteDBSchema)
        console.log('DBBBBB Downloading...')
        await this.$utils.user_init()
        await this.$utils.database_download()
        console.log('DBBBBB Downloaded')
        this.user_loaded = true
    }

    async created() {
        window.addEventListener('paste', this.paste_event)
    }

    private currentDate() {
        // Get the current date
        const date = new Date()

        // Extract the day, month, and year from the date object
        const day = date.getDate()
        const month = date.getMonth()
        const year = date.getFullYear()

        // Create an array of month names
        const monthNames = [
            'January',
            'February',
            'March',
            'April',
            'May',
            'June',
            'July',
            'August',
            'September',
            'October',
            'November',
            'December',
        ]

        // Get the name of the month by using the month number (0-based index)
        const monthName = monthNames[month]

        // Add the appropriate ordinal suffix to the day
        let ordinalSuffix = 'th'
        if (day == 1 || day == 21 || day == 31) {
            ordinalSuffix = 'st'
        } else if (day == 2 || day == 22) {
            ordinalSuffix = 'nd'
        } else if (day == 3 || day == 23) {
            ordinalSuffix = 'rd'
        }

        // Return the formatted date string
        return day + ordinalSuffix + ' ' + monthName
    }

    public paste_event(paste_event: any) {
        const files = this.$yanui.utils.filter_file_from_clipboard(paste_event)
        if (!files || files.length == 0) return
        this.$store.state.modal.upload = true
        this.$nextTick(() => {
            this.$global_event.emit('files-added', files)
        })
    }

    public file_drag_drop(e: any) {
        const files = this.$yanui.utils.filter_file_from_clipboard(e)
        if (!files || files.length == 0) return
        this.$global_event.emit('files-added', files)
        this.file_drag_end()
    }

    public file_drag_start(e: any) {
        const files = this.$yanui.utils.filter_file_from_clipboard(e)
        if (!files || files.length == 0) return
        this.$store.state.modal.upload = true
        this.file_dragging = true
    }

    public file_drag_end() {
        this.file_dragging = false
    }

    mounted() {
        window.addEventListener('resize', this.on_resize)
        this.recalibrate_menu()

        // window.matchMedia('(prefers-color-scheme: dark)').addEventListener("change", (e) => {
        //   console.log("lmao?");
        //   this.$yanui.theme.value = e.matches ? "dark" : "light";
        // });
    }

    unmounted() {
        window.removeEventListener('resize', this.on_resize)
        window.removeEventListener('paste', this.paste_event)
        this.$global_event.all.clear()
        clearTimeout(this.resize_timer)
    }

    private others_click() {
        this.show_others = !this.show_others
    }

    private recalibrate_menu() {
        const elements =
            this.$refs.nav.getElementsByClassName('menu-item-wrapper')
        this.hidden_menu_index = -1
        this.show_others = false

        for (let i = 0; i < elements.length; i++) {
            const el: HTMLElement = elements[i] as HTMLElement
            if (!this.$el_utils.is_in_viewport(el)) {
                const index_s = el.getAttribute('data-index')
                if (index_s) {
                    const index = Number(index_s)
                    if (
                        this.hidden_menu_index == -1 ||
                        this.hidden_menu_index > index
                    ) {
                        this.hidden_menu_index = index - 1
                    }
                }
            }
        }
    }

    private upload() {
        this.$modal.open_upload({
            folder: this.$store.state.current_folder,
        })
        //if (!this.$store.state.modal.upload) {
        //    this.$store.state.modal.upload = true
        //}
    }
    private show_menu_upload_mobile(index: number) {
        if (this.hidden_menu_index == -1) {
            return Math.floor((this.menus.length - 1) / 2) == index
        } else {
            return Math.floor((this.hidden_menu_index - 1) / 2) == index
        }
    }

    private on_resize() {
        if (!this.resize_timer) {
            document.body.classList.add('block-transition')
        }
        clearTimeout(this.resize_timer)
        this.resize_timer = setTimeout(() => {
            document.body.classList.remove('block-transition')
            this.resize_timer = null
            this.recalibrate_menu()
        }, 200)
    }

    private scroll(event: any) {
        const scroll_top = event.target.scrollTop
        if (scroll_top > this.last_scroll_top) {
            this.scroll_direction = 'scroll-down'
        } else {
            this.scroll_direction = 'scroll-up'
        }

        this.search_height_scroll += this.last_scroll_top - scroll_top
        if (this.search_height_scroll > 64) {
            this.search_height_scroll = 64
        } else if (this.search_height_scroll < 0) {
            this.search_height_scroll = 0
        }
        this.search_height_val = this.search_height_scroll / 64
        this.last_scroll_top = scroll_top
    }
}
