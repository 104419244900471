
import { Options, Vue, prop } from 'vue-class-component'
import { YTooltip } from '@pacprotocol/yanui'


class Props {
    icon: string = prop({
        required: true,
    })
    title: string = prop({
        required: true,
    })
    subtitle: string = prop({
        required: true,
    })
    value: string = prop({
        required: true,
    })
}

@Options({
    components: {
        YTooltip
    },
})
export default class DistributionItem extends Vue.with(Props) {}
