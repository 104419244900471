
import { Options, Vue, prop, mixins } from 'vue-class-component'
import GeneralFileEditor from "./GeneralFileEditor";
import TinyMCEEditor from '@tinymce/tinymce-vue'


@Options({
    components: {
        TinyMCEEditor,
    },
    extends: [GeneralFileEditor],
})
export default class DocEditor extends GeneralFileEditor {
    protected extension = "datdoc";

    protected async download_file() {
        console.log("DatosDoc - download_file", this.file_link)
        const response = await fetch(this.file_link)

        if (response.status === 200) {
            this.content = await response.text()
        }
        this.$nextTick(() => {
            this.loaded = true
        })
    }

    protected async upload_file() {
        if (!this.content) {
            throw new Error('No file selected')
        }

        const file_object = new File(
            [this.content],
            this.fileName + '.' + this.extension,
            {
                type: 'text/plain',
            },
        )

        return file_object
    }

    private setup(editor: any) {
        editor.on('ExecCommand', (e: any) => {
            if ('mceNewDocument' == e.command) {
                this.loaded = false
                this.content = ''
                this._file_id = ''
                this._folder_id = ''
                this.fileName = 'Untitled Document'
                this.$store.state.editor_filename = this.fileName
                this.$router.replace({ hash: '' })
                if (this.timeout) {
                    clearTimeout(this.timeout)
                }
            }
        })
    }

    private tinymce_init() {
        this.show_editor = true
    }

    private tinymce_remove() {
        this.show_editor = false
    }

    private tinymce_change(event: any, editor: any) {
        console.log('DatosDoc - tinymce_change', event)
    }

}
