import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "preview-pdf" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PDFEditor = _resolveComponent("PDFEditor")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_PDFEditor, {
      "file-link": _ctx.fileLink,
      readonly: true,
      "toolbar-items": [
                {
                    type: 'sidebar-thumbnails',
                },
                {
                    type: 'pager',
                },
                {
                    type: 'pan',
                },
                {
                    type: 'zoom-out',
                },
                {
                    type: 'zoom-in',
                },
                {
                    type: 'zoom-mode',
                },
                {
                    type: 'layout-config'
                },
                {
                    type: 'spacer',
                },
                {
                    type: 'print',
                },
                {
                    type: 'search',
                },
            ]
    }, null, 8, ["file-link"])
  ]))
}