
import { Options, Vue, prop } from 'vue-class-component'
import { YButton } from '@pacprotocol/yanui'
import GeneralFileEditor from "./GeneralFileEditor";
import PSPDFKit, { Instance } from 'pspdfkit'

class Props {
    toolbarItems: any = prop({
        type: Array,
        required: false,
        default: () => [PSPDFKit.defaultToolbarItems],
    })
}


@Options({
    components: {
        YButton,
    },
    extends: [GeneralFileEditor],
})
export default class PDFEditor extends GeneralFileEditor.with(Props) {
    private doc_loaded = false
    private instance: Instance | null = null
    protected extension = "pdf";

    async created() {
        //
        console.log("TOOLBAR KIT", PSPDFKit.defaultToolbarItems)
    }

    async mounted() {
        console.log("PDFEDITOR", this);
        this.load_pspdfkit()
        this.$watch("$yanui.theme.value", () => {
            this.load_pspdfkit()
        })

    }

    protected async download_file() {
        await this.load_pspdfkit();
        this.$nextTick(() => {
            //@ts-ignore
            this.loaded = true
        })
    }

    protected async upload_file() {
        const buffer = await this.instance?.exportPDF();
        if (!buffer) {
            return
        }
        const blob = new Blob([buffer], { type: 'application/pdf' });

        const file_object = new File(
            [blob],
            //@ts-ignore
            this.fileName + '.' + this.extension,
            {
                type: 'application/pdf',
            },
        )

        return file_object
    }
    private async load_pspdfkit() {
        //@ts-ignore
        PSPDFKit.unload(this.$refs.pdfRef)

        let config: any = {
            //@ts-ignore
            document: this.file_link,
            //@ts-ignore
            container: this.$refs.pdfRef,
            toolbarItems: this.toolbarItems,
            theme:
                this.$yanui.theme.value === 'dark'
                    ? PSPDFKit.Theme.DARK
                    : PSPDFKit.Theme.LIGHT,
        }

        if (this.readonly) {
            const initialViewState = new PSPDFKit.ViewState({
                readOnly: true,
            });

            config = {
                ...config,
                initialViewState
            }
        }

        this.instance = await PSPDFKit.load(config)
    }

    async beforeDestroy() {
        //@ts-ignore
        PSPDFKit.unload(this.$refs.pdfRef)
    }
}
