
import { Options, Vue } from 'vue-class-component'

import { YChartLine, YChartTreeMap, YTabs, YTabItem } from '@pacprotocol/yanui'
import FileViewer from '@/components/FileViewer.vue'
import mimeTypes from 'mime-types'
import DistributionItem from '@/components/Dashboard/DistributionItem.vue'
@Options({
    components: {
        YChartLine,
        YTabs,
        YTabItem,
        YChartTreeMap,
        FileViewer,
        DistributionItem,
    },
})
export default class Home extends Vue {
    private timespan: 'day' | 'week' | 'month' | 'year' = 'day'

    private storage_quota_history_count = 6
    private storage_quota_history: any = {
        items: new Array(this.storage_quota_history_count).fill(0),
    }
    private distribution_of_data: any = {
        items: [],
    }

    private date_range_in_array(
        day_start: number,
        day_end: number,
        day_step: number,
    ): Date[] {
        //Return array from day_start to day_end in day_step steps
        let date_range = []
        for (let i = day_start; i <= day_end; i += day_step) {
            date_range.push(new Date(i))
        }
        return date_range
    }

    private last_date_steps(
        type: 'day' | 'week' | 'month' | 'year',
        count: number,
    ): Date[] {
        //Return array of last date steps
        let date_end = new Date()
        let date_start = new Date()
        let date_step = 0

        switch (type) {
            case 'day':
                date_start.setDate(date_start.getDate() - count)
                date_step = 24 * 60 * 60 * 1000
                break
            case 'week':
                date_start.setDate(date_start.getDate() - count * 7)
                date_step = 7 * 24 * 60 * 60 * 1000
                break
            case 'month':
                date_start.setMonth(date_start.getMonth() - count)
                date_step = 30 * 24 * 60 * 60 * 1000
                break
            case 'year':
                date_start.setFullYear(date_start.getFullYear() - count)
                date_step = 365 * 24 * 60 * 60 * 1000
                break
        }

        //Include today
        date_start.setDate(date_start.getDate() + 1)

        return this.date_range_in_array(
            date_start.getTime(),
            date_end.getTime(),
            date_step,
        )
    }

    private date_to_day_month_year_string(date: Date): string {
        //Return date as string in format "day.month.year"
        let day = date.getDate()
        let month = date.getMonth() + 1
        let year = date.getFullYear()
        return month + '/' + day + ' ' + year.toString().substring(2, 4)
    }

    private display_date_x_axis(
        type: 'day' | 'week' | 'month' | 'year' = 'day',
        count: number = 6,
    ): string[] {
        //Return array of last date steps as string in format "day.month.year"
        let date_range = this.last_date_steps(type, count)
        let date_range_string = []
        for (let i = 0; i < date_range.length; i++) {
            date_range_string.push(
                this.date_to_day_month_year_string(date_range[i]),
            )
        }
        return date_range_string
    }

    async created() {
        await Promise.all([
            await this.storage_quota_chart_data_load(),
            await this.distribution_of_data_chart_data_load(),
        ])
    }

    private async storage_quota_history_change_timespan(
        day: 'day' | 'week' | 'month' | 'year',
    ) {
        this.timespan = day
        await this.storage_quota_chart_data_load()
    }

    private async storage_quota_chart_data_load() {
        await this.$yanui.db_ready()
        this.storage_quota_history.items = []
        let last_quote = 0
        const date = this.last_date_steps(
            this.timespan,
            this.storage_quota_history_count,
        )
        for (let i = 0; i < this.storage_quota_history_count; i++) {
            date[i].setHours(0, 0, 0, 0)
            const query = this.$yanui.db.storage_quota_history.findOne({
                selector: {
                    date: date[i].getTime().toString(),
                },
            })
            const quote_day = await query.exec()
            last_quote = quote_day ? quote_day.quota : last_quote
            this.storage_quota_history.items.push(
                (quote_day ? quote_day.quota : last_quote) / 1024 / 1024 / 1024,
            )
        }
        this.storage_quota_history.items[
            this.storage_quota_history_count - 1
        ] += 0.000001 //Fixes the bug in the chart of lines not being displayed. Not an ideal bug fixes.
    }

    private async distribution_of_data_chart_data_load() {
        await this.$yanui.db_ready()
        this.distribution_of_data.items = []
        const query = this.$yanui.db.files.find()

        const files = await query.exec()

        const value = [
            {
                title: 'My Documents',
                subtitle: '',
                value: 0,
                files_count: 0,
                files_total_size: 0,
                icon: 'file-alt',
            },
            {
                title: 'My Videos',
                subtitle: '',
                value: 0,
                files_count: 0,
                files_total_size: 0,
                icon: 'file-video',
            },
            {
                title: 'My Pictures',
                subtitle: '',
                value: 0,
                files_count: 0,
                files_total_size: 0,
                icon: 'file-image',
            },
            {
                title: 'My Musics',
                subtitle: '',
                value: 0,
                files_count: 0,
                files_total_size: 0,
                icon: 'file-music',
            },
            {
                title: 'Archives',
                subtitle: '',
                value: 0,
                files_count: 0,
                files_total_size: 0,
                icon: 'file-archive',
            },
            {
                title: 'Others Files',
                subtitle: '',
                value: 0,
                files_count: 0,
                files_total_size: 0,
                icon: 'question-square',
            },
        ]

        let total_storage_available = this.$store.state.user?.space_max

        for (let i = 0; i < files.length; i++) {
            const file = files[i]
            const extension = file.name.split('.').pop()
            const contentType = mimeTypes.lookup(file.name)

            if (extension === 'datdoc') {
                value[0].files_total_size += file.size
                value[0].files_count++
                continue
            }

            if (!contentType) {
                value[5].value += file.size
                value[5].files_count++
                continue
            }

            if (contentType.startsWith('text') || extension === 'pdf' || extension === 'datdoc') {
                value[0].files_total_size += file.size
                value[0].files_count++
            } else if (contentType.startsWith('video')) {
                value[1].files_total_size += file.size
                value[1].files_count++
            } else if (contentType.startsWith('image')) {
                value[2].files_total_size += file.size
                value[2].files_count++
            } else if (
                contentType.startsWith('audio') ||
                contentType.startsWith('music')
            ) {
                value[3].files_total_size += file.size
                value[3].files_count++
            } else if (
                extension === 'zip' ||
                extension === 'tar' ||
                extension === 'rar' ||
                extension === '7z' ||
                extension === 'gz'
            ) {
                value[4].files_total_size += file.size
                value[4].files_count++
            } else {
                value[5].files_total_size += file.size
                value[5].files_count++
            }
        }
        //Convert value[n].value to percentage
        for (let i = 0; i < value.length; i++) {
            console.log(
                'FILE SIZE',
                value[i].files_total_size,
                'TOTAL STORAGE',
                total_storage_available,
            )
            value[i].value =
                Math.round(
                    (value[i].files_total_size / total_storage_available) *
                        1000,
                ) / 10
            value[i].subtitle = `${
                value[i].files_count
            } files, ${this.$yanui.file_utils.byte_to_human_readable(
                value[i].files_total_size,
            )}`
        }

        this.distribution_of_data.items = value
    }
}
