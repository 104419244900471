
import { PropType, ref } from 'vue'
import { Options, Vue, prop } from 'vue-class-component'
import FileItems from '@/components/FileItems.vue'

import {
    YInputText,
    YButton,
    YDropdown,
    YDropdownItem,
    YModal,
    YListChunk,
    YBreadcrumb,
    YListHeader,
    YListHeaderItem,
    Utils,
    BreadcrumbItem,
    FileEntity,
    FileType,
} from '@pacprotocol/yanui'
import ModalUtils from '@/scripts/modal'

import * as clipboardy from 'clipboardy'
import { Subscription } from 'rxjs'

class Props {
    view_support = prop({
        type: Array,
        required: false,
        default: [
            {
                name: 'view-file-list',
                type: 'list',
                icon: 'list',
                title: 'List View',
                item_height: 48,
                refresh: false,
                load_bottom_offset: 300,
                use_preview: false,
            },
            {
                name: 'view-file-grid',
                type: 'grid',
                icon: 'th',
                title: 'Normal View',
                item_height: 160,
                item_width: 100,
                icon_height: 100,
                icon_padding: 18,
                refresh: false,
                load_bottom_offset: 300,
                use_preview: false,
            },
            {
                name: 'view-file-grid-large',
                type: 'grid',
                icon: 'th-large',
                title: 'Large View',
                item_height: 205,
                item_width: 150,
                icon_height: 150,
                icon_padding: 24,
                refresh: false,
                load_bottom_offset: 300,
                use_preview: true,
            },
            {
                name: 'view-file-grid-giant',
                type: 'grid',
                icon: 'square',
                title: 'Giant View',
                item_height: 305,
                item_width: 250,
                icon_height: 250,
                icon_padding: 24,
                refresh: false,
                load_bottom_offset: 300,
                exclude_mobile: true,
                use_preview: true,
            },
        ],
    })

    filter = prop({
        type: Object,
        required: false,
        default: undefined,
    })
    main = prop({
        type: Boolean,
        required: false,
        default: false,
    })
    sort = prop({
        type: Array as PropType<any[]>,
        required: false,
        default: [{ pin: 'desc' }],
    })
    simple = prop({
        type: Boolean,
        required: false,
        default: false,
    })
    elements = prop({
        type: Array as PropType<any[]>,
        required: false,
        default: [
            'upload',
            'create-folder',
            'create-docs',
            'view-dropdown',
            'directory',
        ],
    })
    tool = prop({
        type: Boolean,
        required: false,
        default: true,
    })
    ignoreParent = prop({
        type: Boolean,
        required: false,
        default: false,
    })
    viewDefault = prop({
        type: String,
        required: false,
        default: 'view-file-list',
    })
}

@Options({
    components: {
        YInputText,
        YButton,
        YDropdown,
        YModal,
        YListChunk,
        YBreadcrumb,
        YDropdownItem,
        FileItems,
        YListHeader,
        YListHeaderItem,
    },
})
export default class FileViewer extends Vue.with(Props) {
    private file_items: FileItems | null = null
    private file_display_count = 40
    private files_loading: boolean = false
    private files_loading_reset: boolean = false
    private path: BreadcrumbItem[] = []
    private current_folder: FileEntity | null = null
    private last_folder_id: string = ''
    private view_mode: any = this.view_support[0]
    private files_load_full: boolean = false
    private file_page_index: number = 0
    private search_timeout: any = null
    private file_subscribe: Subscription | null = null

    private additional_filter: any = {}
    private files: any[] = []

    async created() {
        this.view_mode = this.view_support.find(
            (v: any) => v.name === this.viewDefault,
        )
        await this.convert_path_to_folders()
        await this.load_files(true)

        this.$watch(
            '$store.state.search',
            (search: any) => {
                if (this.search_timeout) {
                    clearTimeout(this.search_timeout)
                }
                this.search_timeout = setTimeout(() => {
                    this.additional_filter = {
                        search: search.text.trim(),
                    }
                    const active_search = !!search.text.trim()
                    this.load_files(true)
                    if (active_search) {
                        ;(this.$i ? this.$i : this).$plausible.trackEvent(
                            'File Searched',
                        )
                    }
                }, 500)
            },
            { deep: true },
        )
    }

    get mobile_view() {
        return (this.$i ? this.$i : this).$screen.width <= 768
    }

    public async change_view(view: any) {
        this.view_mode = view
        ;(this.$i ? this.$i : this).$plausible.trackEvent('View Change', {
            props: { view: view.title },
        })
    }

    async beforeDestroy() {
        if (this.file_subscribe) {
            this.file_subscribe.unsubscribe()
        }
    }

    public async load_files(reset: boolean = false) {
        if (!this.files_loading) {
            this.files_loading = true
            try {
                if (this.current_folder) {
                    if (this.current_folder.type !== FileType.FOLDER) {
                        this.current_folder = null
                    }
                }
                if (reset) {
                    //this.files_loading_reset = true
                    this.file_page_index = 0
                    this.files_load_full = false
                }
                ;(this.$i ? this.$i : this).$plausible.trackEvent(
                    'File List Loading',
                    { props: { index: this.file_page_index.toString() } },
                )
                await this.$yanui.db_ready()
                const queryFind: any = {}
                if (this.filter) {
                    queryFind.selector = this.filter
                }
                if (this.sort) {
                    queryFind.sort = this.sort
                }

                if (!this.ignoreParent) {
                    queryFind.selector = {
                        ...queryFind.selector,
                        in_folder: this.current_folder?.id ?? '',
                    }
                }

                const query = this.$yanui.db.files.find(queryFind)

                if (this.file_subscribe) {
                    this.file_subscribe.unsubscribe()
                }
                this.file_subscribe = query.$.subscribe((files: any) => {
                    this.files = files
                    if (files.length !== this.file_display_count) {
                        this.files_load_full = true
                        ;(this.$i ? this.$i : this).$plausible.trackEvent(
                            'File List Loading',
                            { props: { end: this.file_page_index.toString() } },
                        )
                    }
                    this.file_page_index++
                    this.last_folder_id = this.current_folder?.id ?? ''
                    this.files_loading = false
                    this.files_loading_reset = false
                })

            } catch (e: any) {
                console.error('[ERROR] Load Files', e)
                this.files_loading = false
                this.files_loading_reset = false
                this.$yanui.toast.show(e.message, { type: 'is-danger' })
            }
        }
    }

    private additional_sort: string = 'name'
    private additional_sort_order: string = 'asc'

    private sort_apply(sort_type: any) {
        if (this.additional_sort === sort_type) {
            if (this.additional_sort_order === 'asc') {
                this.additional_sort_order = 'desc'
            } else {
                this.additional_sort_order = 'asc'
            }
        } else {
            this.additional_sort_order = 'asc'
        }
        this.additional_sort = sort_type
        this.load_files(true)
        ;(this.$i ? this.$i : this).$plausible.trackEvent('File Sorted', {
            props: {
                sort_type: sort_type + ' - ' + this.additional_sort_order,
            },
        })
    }

    private async files_load_more(done: CallableFunction) {
        this.files_load_full = true
        return done()
        /*
        if (!this.files_load_full) {
            try {
                await this.load_files()
            } catch (e) {
                console.error(e)
            }
        }
        done()
        */
    }

    public async convert_path_to_folders() {
        if (!this.simple) {
            const path = await (this.$i
                ? this.$i
                : this
            ).$utils.folders_by_path(this.$route.params.path)
            if (!path) return

            if (path.length > 0) {
                let path_endpoint = ''

                for (let i = 0; i < path.length; i++) {
                    path_endpoint += path[i].name + '/'
                    path[i].icon = 'folder'
                    path[i].text = path[i].name
                    path[i].link = '/' + path_endpoint
                }

                this.$router.replace(
                    this.$route.meta.path + path[path.length - 1].link,
                )
                this.path = path
                this.current_folder = this.path[this.path.length - 1] as any
                if (this.current_folder)
                    this.last_folder_id = this.current_folder.id
            } else {
                if (this.$route.meta.path) {
                    this.$router.replace(this.$route.meta.path as string)
                }
                this.current_folder = null
                this.last_folder_id = ''
            }
            if (this.main) {
                this.$store.state.current_folder = this.current_folder
            }
        }
    }

    private upload() {
        this.$modal.open_upload({
            folder: this.current_folder,
        })
        //if (!this.$store.state.modal.upload) {
        //    this.$store.state.modal.upload = true
        //}
    }

    private create_docs() {
        this.$store.state.editor_folder_id = this.current_folder?.id ?? ''
        this.$router.push({
            name: 'DatosDocs',
        })
    }

    get breadcrumb_data() {
        const path: any[] = [
            {
                text: 'Home',
                path: '/',
                icon: 'home-alt',
                id: null,
                _id: null,
                type: 'folder',
            },
        ]

        for (let i = 0; i < this.path.length; i++) {
            path.push(this.path[i])
        }
        return path
    }

    private breadcrumb_click(item: BreadcrumbItem) {
        delete item.$index
        console.log('BREADCRUMB CLICK', item)
        this.folder_open(item as any)
    }

    private async folder_open(folder: FileEntity | any) {
        if (this.simple) return
        if (this.current_folder?.id === folder?.id) return //If its same folder no reload is needed
        if (!this.current_folder && !folder?.id) return //If its same root folder no reload is needed
        if (folder.id == null || folder.id === '') {
            this.path = []
            //@ts-ignore
            this.$router.replace(this.$route.meta.path)
            this.current_folder = null
            await this.load_files(true)
            if (this.main) {
                this.$store.state.current_folder = this.current_folder
            }
            return
        }
        const exists_index = this.path.findIndex((f: any) => f.id === folder.id)
        if (exists_index >= 0) {
            this.path.splice(exists_index + 1, this.path.length)
            ;(this.$i ? this.$i : this).$plausible.trackEvent(
                'Folder YBreadcrumb Open',
            )
        } else {
            let path_endpoint =
                this.path.map((f: any) => f.name).join('/') + '/' + folder.name
            if (!path_endpoint.startsWith('/')) {
                path_endpoint = '/' + path_endpoint
            }
            this.path.push(null as any)
            const path_index = this.path.length - 1
            this.path[path_index] = folder
            this.path[path_index].icon = 'folder'
            this.path[path_index].text = folder.name
            this.path[path_index].link = path_endpoint
        }
        ;(this.$i ? this.$i : this).$plausible.trackEvent('Folder Open')

        this.$router.replace(
            //@ts-ignore
            this.$route.meta.path + this.path[this.path.length - 1].link,
        )
        this.current_folder = this.path[this.path.length - 1] as any
        if (this.current_folder) this.last_folder_id = this.current_folder.id
        if (this.main) {
            this.$store.state.current_folder = this.current_folder
        }
        await this.load_files(true)
    }
}
