
import { Options, Vue } from 'vue-class-component'
import { Utils } from '@pacprotocol/yanui'
import { App as CapApp } from '@capacitor/app'
import { Browser } from '@capacitor/browser'

@Options({
    name: 'App',
})
//              <!--:style="(hidden_menu_index == $index ? 'display: none' : null)"-->
export default class App extends Vue {
    async created() {
        CapApp.addListener('appUrlOpen', async ({ url }) => {
            console.log('appUrlOpen', url)
            if (
                url.includes('state') &&
                (url.includes('code') || url.includes('error'))
            ) {
                console.log('appUrlOpen success', url)
                try {
                    await this.$auth0.handleRedirectCallback(url)
                } catch (e: any) {
                    this.$yanui.toast.show(e.message, {
                        type: 'is-danger',
                    })
                    await Browser.close()
                }
                this.$router.push({
                    name: 'Home',
                })
            }
            if (url.includes('/logout')) {
                //Logout trigger
                console.log('appUrlOpen logout', url)
                
                this.$router.push({
                    name: 'Login',
                })
            }
            // No-op on Android
            await Browser.close()
            const access_token = await this.$utils.get_access_token()
            Utils.send_message_to_sw({
                type: 'access-token',
                token: access_token,
            })
        })

        //Ideal would be putting this in ServiceWorker as well
        const access_token = await this.$utils.get_access_token()
        Utils.send_message_to_sw({
            type: 'access-token',
            token: access_token,
        })
    }
}
