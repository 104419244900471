import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "change-email-component" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_y_input_text = _resolveComponent("y-input-text")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_y_input_text, {
      title: "New E-Mail",
      type: "email",
      modelValue: _ctx.new_email,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.new_email) = $event)),
      placeholder: "john@example.com",
      icon: "envelope"
    }, null, 8, ["modelValue"]),
    _createVNode(_component_y_input_text, {
      title: "Confirm Current Password",
      type: "password",
      modelValue: _ctx.current_password,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.current_password) = $event)),
      icon: "asterisk"
    }, null, 8, ["modelValue"])
  ]))
}